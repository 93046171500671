import { DateSelected } from 'types';

export function dayOfWeekAsString(dayIndex: number): string {
  return (
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][dayIndex] || ''
  );
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDate = (dateSelectedString: DateSelected): string => {
  const date: Date = new Date(dateSelectedString?.data?.start ?? '');
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let minutesString = minutes.toString();
  const dayOfWeekIndex = date.getDay();
  const dayOfMonth = date.getDate();
  const dayOfWeek = dayOfWeekAsString(dayOfWeekIndex);
  const month = monthNames[date.getMonth()];
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const regexParen = /\((.*)\)/;
  const timeZone = date?.toTimeString()?.match(regexParen)?.[1];

  if (hours > 12) {
    hours -= 12;
  }

  if (hours === 0) {
    hours = 12;
  }

  if (minutes < 10) {
    minutesString = `0${minutesString}`;
  }
  const time = `${hours}:${minutesString} ${suffix}`;
  return `${dayOfWeek}, ${month} ${dayOfMonth} at ${time} ${timeZone}`;
};
