import * as React from 'react';
import { View, ScrollView, Platform, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { ReportLocalization } from 'sample-collection-instructions';
import * as codes from 'constants/reportCodes';
import * as types from 'constants/reportTypes';
import { Localization, Biomarker, Result, Sex } from 'types';

import { LDL_HDL_RATIO } from 'constants/biomarkers';
import { formatPascalCase } from 'utils/formatPascalCase';
import { Layout } from './StyledView';
import Text from './StyledText';

export const Table = (props: {
  biomarkers: Biomarker[];
  results: Result[];
  sex: Sex;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <ReportLocalization.Consumer>
      {(localization) => (
        <TableContent
          isMobile={isMobile}
          localization={localization}
          {...props}
        />
      )}
    </ReportLocalization.Consumer>
  );
};

const TableContent = ({
  isMobile,
  localization,
  biomarkers,
  results,
  sex,
}: {
  isMobile: boolean;
  localization: Localization | null;
  biomarkers: Biomarker[];
  results: Result[];
  sex: Sex;
}) => {
  const headers = [
    {
      key: 'biomarker',
      label: localization?.biomarker ?? 'Biomarker',
      flex: 160,
    },
    {
      key: 'quantitative',
      label: localization?.biomarkerQuantitative ?? 'Quantitative',
      flex: 130,
    },
    {
      key: 'reportableRange',
      label: localization?.biomarkerReportableRange ?? 'Reportable Range',
      flex: 160,
    },
    {
      key: 'cutoff',
      label: localization?.biomarkerCutOff ?? 'Cutoff',
      flex: 120,
    },
    {
      key: 'targetRange',
      label: localization?.biomarkerTargetRange ?? 'Target Range',
      flex: 145,
    },
  ];

  const rows = biomarkers
    .map((biomarker) => {
      const { name, note, units, ranges, developerConfig } = biomarker;
      const sexSpecificRanges = ranges[sex] ?? ranges;
      const { segments, cutoff, target_range, reportable_range, boolean } =
        sexSpecificRanges;
      const unitsFormatted = units === '%' ? `${units}` : ` ${units}`;
      const result = results.find(
        ({ key }) => key === biomarker.developerConfig.key
      );
      if (!result) {
        return null;
      }
      const value = boolean
        ? result.qualitativeResult ?? ''
        : result.quantitativeResult ?? 0;
      let val = value;
      if (value > reportable_range[1]) val = reportable_range[1];
      if (
        value < reportable_range[0] &&
        !LDL_HDL_RATIO.includes(developerConfig.key)
      ) {
        val = reportable_range[0];
      }
      let outcome = '';
      let valuePresentation = String(value);
      if (boolean) {
        outcome = value
          ? localization?.biomarkerPositiveOutcome ?? ''
          : localization?.biomarkerNegativeOutcome ?? '';
      } else {
        const targetRange = segments.find(
          (segment) => segment.code === codes.GREEN
        );
        const optimalRange = segments.find(
          (segment) => segment.code === codes.OPTGREEN
        );
        if (!targetRange) return null;
        if (!targetRange.upperBoundInclusive && val >= targetRange.range[1]) {
          if (
            optimalRange &&
            !optimalRange.upperBoundInclusive &&
            val < optimalRange.range[1]
          ) {
            outcome = localization?.biomarkerOptimalOutcome ?? '';
          } else {
            outcome = localization?.biomarkerElevatedOutcome ?? '';
          }
        } else if (
          targetRange.upperBoundInclusive &&
          val > targetRange.range[1]
        ) {
          if (
            optimalRange &&
            optimalRange.upperBoundInclusive &&
            val <= optimalRange.range[1]
          ) {
            outcome = localization?.biomarkerOptimalOutcome ?? '';
          } else {
            outcome = localization?.biomarkerElevatedOutcome ?? '';
          }
        } else if (
          !targetRange.lowerBoundInclusive &&
          val <= targetRange.range[0]
        ) {
          outcome = localization?.biomarkerLowOutcome ?? '';
        } else if (
          targetRange.lowerBoundInclusive &&
          val < targetRange.range[0]
        ) {
          outcome = localization?.biomarkerLowOutcome ?? '';
        } else outcome = localization?.biomarkerNormalOutcome ?? '';
        if (value > reportable_range[1]) {
          valuePresentation = `>${reportable_range[1]}`;
        }
        if (value < reportable_range[0]) {
          valuePresentation = `<${reportable_range[0]}`;
        }
        if (result.aboveReportable) valuePresentation = `>${value}`;
        if (result.belowReportable) valuePresentation = `<${value}`;
      }

      return headers.map(({ key }) => {
        const headerKey = `${key}-${name}`;
        switch (key) {
          case 'biomarker':
            return (
              <View style={styles.biomarkerColumn} key={headerKey}>
                <Text
                  style={styles.biomarkerRowTitle}
                  label
                  fontWeight={'700'}
                  testID={`${formatPascalCase(name)}Row`}
                >
                  {name.split(' - ')[0]}
                </Text>
                {note === types.CELIAC_SCREENING ||
                  note === types.CELIAC_MONITORING ||
                  (!!note && (
                    <Layout marginTop={'tiny'}>
                      <Text
                        style={styles.biomarkerRowTitle}
                        label
                      >{`(${note})`}</Text>
                    </Layout>
                  ))}
              </View>
            );
          case 'quantitative':
            if (boolean) return null;
            return (
              <Text
                style={styles.quantitativeColumn}
                label
                key={headerKey}
                testID={`${formatPascalCase(name)}QuantitativeValue`}
              >
                {valuePresentation}
                {unitsFormatted}
              </Text>
            );
          case 'qualitative':
            return (
              <Text label style={styles.qualitativeColumn} key={headerKey}>
                {outcome}
              </Text>
            );
          case 'reportableRange':
            if (!reportable_range || !reportable_range.length) return null;
            return (
              <Text
                style={styles.reportableRangeColumn}
                label
                key={headerKey}
                testID={`${formatPascalCase(name)}RRangeValue`}
              >
                {reportable_range.join(' - ')}
                {unitsFormatted}
              </Text>
            );
          case 'cutoff':
            if (!cutoff) return null;
            return (
              <Text
                style={styles.cutoffColumn}
                fontWeight={'500'}
                label
                key={headerKey}
                testID={`${formatPascalCase(name)}CutoffValue`}
              >{`${cutoff}${unitsFormatted}`}</Text>
            );
          case 'targetRange':
            if (!target_range) return null;
            if (target_range[0] < 0) {
              return (
                <Text
                  style={styles.targetRangeColumn}
                  label
                  key={headerKey}
                  testID={`${formatPascalCase(name)}TRangeValue`}
                >{`<${target_range[1]}${unitsFormatted}`}</Text>
              );
            }
            if (target_range[1] === 9999) {
              return (
                <Text
                  style={styles.targetRangeText}
                  label
                  key={headerKey}
                  testID={`${formatPascalCase(name)}TRangeValue`}
                >{`>${target_range[0]}${unitsFormatted}`}</Text>
              );
            }
            return (
              <Text
                style={styles.targetRangeFormat}
                fontWeight={'500'}
                label
                key={headerKey}
                testID={`${formatPascalCase(name)}TRangeValue`}
              >{`${target_range.join(' - ')}${unitsFormatted}`}</Text>
            );
          default:
            return null;
        }
      });
    })
    .filter((row) => !!row);
  const isWeb = Platform.OS === 'web'; // TODO, table breaks on flex: 1
  return (
    <ScrollView
      contentContainerStyle={[
        isWeb ? { width: '100%', marginVertical: 20 } : { flex: 1 },
      ]}
      horizontal={true}
    >
      <View
        style={[
          {
            width: '100%',
          },
          isMobile && {
            minWidth: 960,
          },
        ]}
      >
        <Layout style={styles.tableRowHeader}>
          {headers.map((header) => (
            <Layout
              style={{
                flex: header.flex,
              }}
              key={header.key}
            >
              <Layout>
                <Text small fontWeight={'500'}>
                  {header.label}
                </Text>
              </Layout>
            </Layout>
          ))}
        </Layout>
        {rows.map((row, index: number) => {
          const lastRow = index === rows.length - 1;
          return (
            <View
              key={row?.[0]?.key}
              style={[
                styles.tableRowBody,
                index % 2
                  ? { backgroundColor: '#EAEEF1' }
                  : { backgroundColor: '#F7F9FB' },
                lastRow && {
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                },
              ]}
            >
              {row?.map((entry, i: number) => {
                const lastIndex = i === row.length - 1;
                const style = {
                  flex: headers[i].flex,
                };
                const key = `${entry?.key}-i`;
                if (typeof entry !== 'object') {
                  return (
                    <Layout style={style} key={key}>
                      <Layout padding={'small'}>
                        <Text style={styles.rowText} label>
                          {entry}
                        </Text>
                      </Layout>
                    </Layout>
                  );
                }
                return (
                  <Layout style={style} key={key}>
                    <Layout
                      padding={'small'}
                      paddingRight={lastIndex ? 'regular' : ''}
                    >
                      {entry}
                    </Layout>
                  </Layout>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  biomarkerColumn: { paddingLeft: 25, paddingVertical: 10 },
  quantitativeColumn: {
    color: '#4A4A4A',
    paddingLeft: 20,
    paddingVertical: 10,
  },
  qualitativeColumn: {
    textTransform: 'uppercase',
    color: '#4A4A4A',
    paddingLeft: 20,
    paddingVertical: 15,
  },
  reportableRangeColumn: {
    color: '#4A4A4A',
    paddingLeft: 10,
    paddingVertical: 10,
  },
  cutoffColumn: {
    fontStyle: 'italic',
    color: '#949494',
  },
  targetRangeColumn: {
    fontStyle: 'italic',
    color: '#949494',
  },
  targetRangeText: {
    fontStyle: 'italic',
    color: '#949494',
  },
  targetRangeFormat: {
    fontStyle: 'italic',
    color: '#949494',
  },
  tableRowHeader: {
    backgroundColor: '#EAEEF1',
    borderBottomWidth: 1,
    borderColor: '#B4BCC1',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexDirection: 'row',
    paddingLeft: 35,
    paddingVertical: 20,
  },
  tableRowBody: { flexDirection: 'row', alignItems: 'center' },
  rowText: { color: '#4A4A4A' },
  biomarkerRowTitle: {
    color: '#4A4A4A',
  },
});
