/* 
  TODO: EAG, VLDL, BLOOD_CREATININE and several other of these biomarkers are not used anywhere.
  Need to determine whether they'll be used for Recommendations colors or should be deleted */
export const BLOOD_GLUCOSE = ['000168', '000193', '000104', '000016'];
export const BLOOD_CREATININE = ['000167', '000204', '000103', '000185'];
export const CHOLESTEROL_TOTAL = ['000006', '000094', '000159', '000188', '000179'];
export const HDL_C = ['000008', '000161', '000190', '000096', '000184'];
export const LDL_C = ['000181', '000163', '000162', '000191', '000098', '000097'];
export const TRIGLYCERIDES = ['000007', '000160', '000192', '000095', '000178'];
export const CHOLESTEROL_HDL_RATIO = ['000012', '000165', '000100'];
export const LDL_HDL_RATIO = ['000005', '000080', '000149'];
export const VLDL = ['000011', '000099', '000164'];
export const HSCRP = ['000018', '000207', '000169', '000105', '000183'];
export const FERRITIN = ['000101', '000166', '000225', '000013'];
export const HB_A1C = ['000017', '000202', '000048', '000134', '000175'];
export const EAG = ['000002', '000113', '000026'];
export const TESTOSTERONE = ['000250', '000020', '000219', '000251', '000107'];
export const PSA = ['000170', '000211', '000106'];
export const VITAMIN_D = ['000023', '000187', '000173', '000218', '000110'];
export const TSH = ['000180', '000171', '000194', '000108', '000021'];
export const FSH = ['000245', '000196'];
export const E2 = ['000244', '000197', '000074'];
export const AMH = ['000246', '000195'];
export const BLOOD_COTININE = ['000172', '000109'];
export const APOLIPOPROTEIN_B = ['000082', '000206'];
export const TTG_IGA = ['000248'];
export const TTG_IGG = ['000249'];
export const DGP_IGA = 'dgp_iga';
export const DGP_IGG = ['000247'];
export const LIPOPROTEIN_A = ['000057', '000252'];
export const LP_PLA2_ACTIVITY = 'lp_pla2_activity';
export const RF_IGM = 'rf_igm';
export const RF_IGA = 'rf_iga';
export const CCP_IGG = 'ccp_igg';
export const TOTAL_PROTEIN = 'protein';
export const PLACA_DBS = 'placa_dbs';
export const APOB_DBS = 'apob_dbs';
export const CORONARY_RISK_RATIO = 'coronary_risk_ratio';
export const DBS_CHOLESTEROL_TP = 'dbs_cholesterol_tp';
export const TP_GLUCOSE_DBS = 'tp_glucose_dbs';
export const TOTAL_PROTEIN_LPA_DBS = 'total_protein_lpa_dbs';
export const LPA_DBS = 'lpa_dbs';
