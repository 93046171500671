import React, { useState } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { format } from 'date-fns';
import '@expo/match-media';

import {
  MessageTitle,
  MessageContainer,
  MessageBubble,
  Avatars,
} from 'components/Overview';
import { ReportContainer, Paragraph } from 'components/ReportCommon';
import ScreenWrapper from 'components/ScreenWrapper';

import cmsClient, { ALL_REPORTS } from 'sample-collection-instructions';
import { getReport, getPage } from 'utils/content';
import parseMarkdown from 'utils/markdown';
import { Doctor, Page } from 'types';
import reportClient, { GET_KIT_ID } from 'kit-activation';

import GET_TEST_RESULTS from './queries';

export default ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [title, setTitle] = useState('');
  const [reportText, setReportText] = useState<JSX.Element[] | null>(null);
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] =
    useState<ApolloError | null>(null);

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = data?.kitId ?? '';

  const [getTestResults, { loading: resultsLoading, error: resultsError }] =
    useMutation(GET_TEST_RESULTS, {
      variables: {
        kitId,
      },
      onCompleted: ({ getTestResults: testResultsData }) => {
        const {
          patient: { name },
          testType,
          testedDate,
        } = testResultsData;
        const {
          pages: unfilteredPages,
          title: reportTitle,
          doctors: reportDoctors,
        } = getReport(content, testType);
        const page = getPage(unfilteredPages, 'ReportOverviewPageRecord');
        const { welcomeMessage } = page;
        const filteredPages = unfilteredPages.filter(
          (p) => p.goToButton !== 'View Background'
        );
        setPages(filteredPages);
        setTitle(reportTitle);
        setDoctors(reportDoctors);
        const firstName = name[0].given[0];
        const richText = parseMarkdown(
          {
            Header: MessageTitle,
            Paragraph,
            first_name: firstName,
            tested_date: testedDate
              ? `on ${format(new Date(testedDate), 'MMMM dd, yyyy')} `
              : '',
          },
          welcomeMessage
        ) as JSX.Element[];
        setReportText(richText);
      },
      onError: (err) =>
        console.error(
          `An error occurred during GetTestResults Mutation with kit id: ${kitId}`,
          err
        ),
    });

  const {
    loading: contentLoading,
    error: contentError,
    data: content,
  } = useQuery(ALL_REPORTS(), {
    client: cmsClient,
    onCompleted: () => getTestResults(),
    onError: (err) =>
      console.error('An error occurred during AllReports Query', err),
  });

  return (
    <ScreenWrapper
      loading={contentLoading || resultsLoading || reportDownloadLoading}
      error={contentError || resultsError || reportDownloadError}
      excludeWrapper
      excludeFooter
    >
      <ReportContainer
        pages={pages}
        navigation={navigation}
        title={title}
        screen={'OverviewScreen'}
        setLoading={setReportDownloadLoading}
        setError={setReportDownloadError}
      >
        <MessageContainer>
          <MessageBubble>
            {reportText?.map((text) => (
              <React.Fragment key={text.key}>{text}</React.Fragment>
            ))}
            <Avatars doctors={doctors} />
          </MessageBubble>
        </MessageContainer>
      </ReportContainer>
    </ScreenWrapper>
  );
};
