import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { View, StyleSheet, Image, Platform } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';
import { useMediaQuery } from 'react-responsive';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import ScreenWrapper from 'components/ScreenWrapper';
import CheckboxKitCode from 'components/CheckboxKitCode';
import HeaderBar from 'components/HeaderBar';
import {
  AllRequirements,
  Patient,
  PatientTestType,
  PlanDefinition,
  TestHistory,
} from 'types';

import InstructionsClient from 'sample-collection-instructions';
import {
  GET_PATIENT,
  GET_PATIENT_TEST_HISTORY,
} from 'screens/dashboard/PatientDashboardScreen/queries';
import GlobalStyles from 'constants/Styles';
import { klaviyoRequest } from 'klaviyo/klaviyo';

import TestingRequirements from './components/Requirements';
import { ALL_TEST_REQUIREMENTS, GET_PLAN_DEFINITIONS } from './queries';
import { Box } from './styles';
import { collectionDeviceMap } from './utils';
import { ACTIVATE_KIT } from '../EnterKitIdScreen/queries';

const ConfirmTestingRequirementsScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers;
  route: RouteProp<{ params: { kitId: string } }, 'params'>;
}): JSX.Element => {
  const [testName, setTestName] = useState<PatientTestType | ''>('');

  const [requirements, setRequirements] = useState(null);
  const [testAgree, setTestAgree] = useState(false);
  const [ownerAgree, setOwnerAgree] = useState(false);
  const [testMatchError, setTestMatchError] = useState(false);
  const [testingRequirementError, setTestingRequirementError] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [testActivated, setTestActivated] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  useQuery(GET_PATIENT, {
    onCompleted: ({ getPatient }) => {
      setPatient(getPatient);
    },
    onError: () =>
      console.error(
        `An error occurred while retrieving Patient with id: ${route.params.kitId}`
      ),
  });

  const { loading: testNameLoading } = useQuery(GET_PATIENT_TEST_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: ({
      getPatientTestHistory,
    }: {
      getPatientTestHistory: TestHistory[];
    }) => {
      const matchingPatientTest = getPatientTestHistory.find(
        (test) => test.kitId === route.params.kitId
      )?.testType as PatientTestType;
      if (matchingPatientTest) {
        setTestActivated(true);
      }
    },
    onError: (err) =>
      console.error(
        `An error occurred while retrieving patient test history for kit id: ${route.params.kitId}`,
        err
      ),
  });

  const { loading: getPlanDefinitionsLoading, error: getPlanDefinitionsError } =
    useQuery(GET_PLAN_DEFINITIONS, {
      fetchPolicy: 'network-only',
      onCompleted: ({
        getPlanDefinitions: planDefinitions,
      }: {
        getPlanDefinitions: PlanDefinition[];
      }) => {
        const matchingTest = planDefinitions.find((pd) =>
          route.params.kitId.includes(pd.identifier)
        );
        if (!matchingTest) {
          setTestMatchError(true);
        } else {
          setTestName(matchingTest.name);
        }
      },
      onError: (err) =>
        console.error(
          `An error occurred while retrieving plan definitions for kit id: ${route.params.kitId}`,
          err
        ),
    });

  const [
    activateKit,
    { loading: activateKitLoading, error: activateKitError },
  ] = useMutation(ACTIVATE_KIT, {
    onCompleted: () => onShowCollectionInstructions(),
    onError: () =>
      console.error(
        `An error occurred while activating kit with id: ${route.params.kitId}`
      ),
  });

  const { data, loading, error } = useQuery(ALL_TEST_REQUIREMENTS, {
    client: InstructionsClient,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && testName) {
      const reqs = data.allTestRequirements.find(
        (req: AllRequirements) => req.title === testName
      );
      setRequirements(reqs);
    }
  }, [data, testName]);

  const onSubmit = () => {
    if (testActivated) {
      onShowCollectionInstructions();
    } else {
      if (!testAgree || !ownerAgree) {
        setTestingRequirementError(true);
        return;
      }
      setTestingRequirementError(false);
      activateKit({ variables: { kitId: route.params.kitId } });
    }
  };

  const onShowCollectionInstructions = () => {
    if (patient) {
      klaviyoRequest({
        event: 'KitActivated',
        email: patient.telecom[1].value,
        firstName: patient.name[0].given[0],
        lastName: patient.name[0].family,
      });
    }
    const collectionDevice = collectionDeviceMap[testName];
    if (Platform.OS === 'web') {
      Linking.openURL(
        `https://www.imaware.health/sample-collection/${collectionDevice}`
      );
    } else {
      WebBrowser.openBrowserAsync(
        `https://www.imaware.health/sample-collection/${collectionDevice}`
      ).then(() => {
        localStorage.removeItem('kitid');
        navigation.navigate('PatientDashboardScreen');
      });
    }
  };

  return (
    <>
      <ScreenWrapper
        loading={
          loading ||
          activateKitLoading ||
          getPlanDefinitionsLoading ||
          testNameLoading
        }
      >
        {!isMobile ? <HeaderBar navigation={navigation} /> : null}
        <View style={styles.contentBackground}>
          <View
            style={
              isMobile
                ? [styles.contentContainer, styles.mobileContentContainer]
                : styles.contentContainer
            }
          >
            <Box isMobile={isMobile}>
              <Text lora style={styles.headerText}>
                Before you begin your {testName || 'test'}
              </Text>
              {!testMatchError ? (
                <>
                  <Text lato style={styles.requirementsText}>
                    Please agree to and follow the requirements outlined below
                    to ensure your test results will be as accurate as possible.
                  </Text>
                  <TestingRequirements
                    requirements={requirements}
                    setTestAgree={setTestAgree}
                  />
                  {!testActivated ? (
                    <>
                      <View style={styles.checkboxWrapper}>
                        <CheckboxKitCode
                          onChange={() => setOwnerAgree(!ownerAgree)}
                          checked={ownerAgree}
                        />
                        <Text style={styles.confirmPhrase}>
                          I am ready to collect my sample right now
                        </Text>
                      </View>
                    </>
                  ) : null}
                  <View
                    style={
                      isMobile
                        ? styles.alertContainer
                        : [styles.alertContainer, { maxHeight: 74 }]
                    }
                  >
                    <View style={styles.alertCopyContainer}>
                      <Image
                        style={styles.alertIconContainer}
                        source={require('assets/images/alert-icon.png')}
                      />
                      <Text lato fontWeight="700">
                        Please do not proceed beyond this screen unless you are
                        ready to collect your sample right now.
                      </Text>
                    </View>
                  </View>
                </>
              ) : null}
              {testingRequirementError ? (
                <View style={styles.errorContainer}>
                  <Image
                    source={require('assets/images/error-icon.png')}
                    style={styles.errorIcon}
                  />
                  <Text lato style={styles.errorText}>
                    You must meet all of the above requirements before beginning
                    to collect your sample to ensure accurate results.{' '}
                  </Text>
                </View>
              ) : null}
              {error || activateKitError || getPlanDefinitionsError ? (
                <View style={styles.errorContainer}>
                  <Text lato style={[GlobalStyles.error, styles.errorText]}>
                    Something went wrong while trying to activate your kit code.
                    Please contact{' '}
                    <a
                      href="mailto:support@imaware.health"
                      style={{ color: '#DC412C' }}
                    >
                      support@imaware.health
                    </a>{' '}
                    for further assistance
                  </Text>
                </View>
              ) : null}
              {testMatchError ? (
                <Text lato style={[GlobalStyles.error, styles.errorText]}>
                  Something went wrong when retrieving your testing
                  requirements. Please contact{' '}
                  <a
                    href="mailto:support@imaware.health"
                    style={{ color: '#DC412C' }}
                  >
                    support@imaware.health
                  </a>{' '}
                  for further assistance
                </Text>
              ) : null}
              {!isMobile && !testMatchError ? (
                <Button
                  onPress={onSubmit}
                  arrowRight
                  primary
                  style={[
                    styles.continueButton,
                    !testingRequirementError ? styles.noErrorSpacing : null,
                  ]}
                  textStyle={styles.continueButtonText}
                >
                  Continue
                </Button>
              ) : null}
            </Box>
          </View>
        </View>
      </ScreenWrapper>
      {isMobile && !testMatchError ? (
        <Button
          onPress={onSubmit}
          arrowRight
          primary
          style={[styles.continueMobileButton]}
          textStyle={styles.continueMobileButtonText}
        >
          CONTINUE
        </Button>
      ) : null}
    </>
  );
};

export default ConfirmTestingRequirementsScreen;

const styles = StyleSheet.create({
  middleContainer: {
    marginVertical: 15,
  },
  confirmPhrase: { paddingLeft: 15 },
  testBtn: {
    color: 'white',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 26,
    lineHeight: 34,
    marginBottom: 13,
    fontWeight: '600',
  },
  requirementsText: {
    lineHeight: 22,
    marginBottom: 32,
    fontSize: 18,
  },
  acknowledgementText: {
    lineHeight: 22,
    marginBottom: 7,
    fontSize: 16,
  },
  contentBackground: {
    backgroundColor: '#FBF8F1',
    alignItems: 'center',
    height: '100%',
    overflow: 'scroll',
  },
  contentContainer: {
    width: 576,
    minHeight: 615,
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 40,
    borderRadius: 24,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
    alignItems: 'center',
    paddingTop: 42,
    paddingBottom: 47,
  },
  mobileContentContainer: {
    marginHorizontal: 18,
    width: 'auto',
  },
  alertContainer: {
    backgroundColor: '#E8EEF5',
    padding: 15,
    paddingLeft: 5,
    marginBottom: 15,
    borderRadius: 5,
    marginTop: 22,
    maxWidth: 465,
  },
  alertCopyContainer: { display: 'flex', flexDirection: 'row' },
  alertIconContainer: { width: 49, height: 49, marginRight: 15 },
  continueButton: {
    display: 'flex',
    height: 44,
    width: 232,
    justifyContent: 'center',
  },
  continueMobileButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    height: 70,
  },
  continueButtonText: {
    fontWeight: '600',
  },
  continueMobileButtonText: {
    letterSpacing: 2,
    lineHeight: 22,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorText: {
    lineHeight: 20,
    letterSpacing: 0.41,
    color: '#DC412C',
    marginTop: 10,
    marginBottom: 20,
  },
  errorIcon: {
    height: 20.8,
    width: 20.8,
    marginRight: 14,
    marginTop: 8,
  },
  noErrorSpacing: {
    marginTop: 26,
  },
  requirementConfirmCheckbox: {
    marginTop: 15,
    marginBottom: 12,
  },
  checkboxWrapper: {
    marginVertical: 8,
    marginHorizontal: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileButtonText: { textTransform: 'uppercase', fontFamily: 'lato_700' },
});
