import React, { useState } from 'react';
import { StyleSheet, View, Linking } from 'react-native';
import Text from 'components/StyledText';
import format from 'date-fns/format';
import { useMediaQuery } from 'react-responsive';
import { AntDesign } from '@expo/vector-icons';

import { TestHistory, TestStatus } from 'types';
import { buttonTextMap, testStatusMap } from './utils';
import TestRowContainer, {
  TestBottomRow,
  TestRowButton,
  TestStatusContainer,
  TestStatusIndicator,
  TestText,
  TestTitle,
  TestTopRow,
} from './styles';

const TestRow = ({
  test,
  onViewCollectionInstructions,
  onViewResults,
  onTrackSample,
  // eslint-disable-next-line
  onOpenModal,
}: {
  test: TestHistory;
  // eslint-disable-next-line no-unused-vars
  onViewCollectionInstructions: (kitId: string) => void;
  // eslint-disable-next-line no-unused-vars
  onViewResults: (kitId: string) => void;
  // eslint-disable-next-line no-unused-vars
  onTrackSample: (t: TestHistory) => void;
  // eslint-disable-next-line no-unused-vars
  onOpenModal: (kitId: string, edit: boolean, consultId: string) => void;
}): JSX.Element => {
  const { testType, activationDate, kitStatus } = test;
  const { status } = kitStatus?.[0] ?? { status: 'Activated' };
  const testStatus = testStatusMap[status] as TestStatus;
  const rejectionReasons = kitStatus?.[0].rejectionReasons;
  const buttonText = buttonTextMap[testStatus];
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const onContactSupport = () =>
    Linking.openURL('mailto:support@imaware.health');

  // eslint-disable-next-line consistent-return
  const determineOnClick = () => {
    switch (buttonText) {
      case 'View collection instructions':
        return onViewCollectionInstructions(test.kitId);
      case 'Track your sample':
        return onTrackSample(test);
      case 'View your results':
        return onViewResults(test.kitId);
      case 'Contact support':
        return onContactSupport();
      default: 
        break;
    }
  };

  /* const openModal = () => onOpenModal(test.kitId, false, '0');

  const userAbleToSchedule = () => {
    const appointment = test?.appointment;
    const checkUserAppointment =
      appointment === null ? true : !appointment?.ableToReschedule;
    return status === 'Fully Resulted By Lab' ? checkUserAppointment : false;
  };

  const renderScheduleButton = () => {
    const appointmentStatus = test?.appointment?.status;
    if (appointmentStatus === 'pending') {
      return (
        <Text
          style={
            isMobile ? styles.appointmentMobileText : styles.appointmentText
          }
        >
          Consultation request sent
        </Text>
      );
    }

    if (appointmentStatus === 'fulfilled') {
      return (
        <Text
          style={
            isMobile ? styles.appointmentMobileText : styles.appointmentText
          }
        >
          Consultation complete
        </Text>
      );
    }

    return (
      <TestRowButton
        small
        consultation={true}
        textStyle={styles.text}
        onPress={openModal}
        testID="scheduleBtn"
        isMobile={isMobile}
      >
        Schedule a free consultation
      </TestRowButton>
    );
  }; */

  return (
    <TestRowContainer isMobile={isMobile} testID={`testRow${test.kitId}`}>
      {isMobile ? (
        <>
          <TestTopRow>
            <View style={styles.view}>
              <TestTitle fontWeight="700" lora>
                {testType}
              </TestTitle>
            </View>
          </TestTopRow>
          <TestBottomRow isMobile>
            <TestText small>
                Activated {format(new Date(activationDate), 'MMM d, y')}
            </TestText>
            <TestStatusContainer isMobile>
              <TestStatusIndicator
                testStatus={testStatus}
                testID="status-indicator"
              />
              <TestText
                small
                style={styles.italic}
              >{` ${testStatus}`}</TestText>
            </TestStatusContainer>
          </TestBottomRow>
          <View style={styles.buttonsContainer}>
            <TestRowButton isMobile small onPress={determineOnClick}>
              {buttonText}
            </TestRowButton>
            {/* {userAbleToSchedule() ? renderScheduleButton() : null} */}
          </View>
        </>
      ) : (
        <View style={styles.testContainer}>
          <View style={styles.view}>
            <TestTitle fontWeight="500" lora testID="testType">
              {testType}
            </TestTitle>
          </View>
          <View style={{ flex: 1 }}>
            <TestText style={styles.activationDate} testID="activationDate">
              {format(new Date(activationDate), 'MMM d, y')}
            </TestText>
          </View>
          <TestStatusContainer style={{ flex: 1 }}>
            <TestStatusIndicator
              testStatus={testStatus}
              testID="status-indicator"
            />
            <TestText
              style={styles.italic}
              testID="testStatus"
            >{` ${testStatus}`}</TestText>
            {testStatus === 'Results unavailable' ? (
              <View onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)} style={styles.iconContainer} testID="iconContainer">
                <AntDesign
                  name="questioncircle"
                  size={16}
                  color="#0049B0"
                />
                {tooltipVisible ? (
                  <View style={styles.tooltipContainer}>
                    {rejectionReasons ? rejectionReasons.map((reason: RejectionReason) => (
                      <Text units key={reason.code} style={styles.rejectionReason}>{reason.message}</Text>
                    )): null}
                  </View>
                ) : null}
              </View>
            ) : null }
          </TestStatusContainer>
          <View style={styles.nextSteps}>
            <TestRowButton small onPress={determineOnClick}>
              {buttonText}
            </TestRowButton>
            {/* {userAbleToSchedule() ? renderScheduleButton() : null} */}
          </View>
        </View>
      )}
    </TestRowContainer>
  );
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    flex: 1,
  },
  text: {
    color: 'white',
  },
  italic: {
    fontStyle: 'italic',
    marginRight: 5,
  },
  activationDate: {
    textAlign: 'center',
  },
  testContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  nextSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  appointmentText: {
    textAlign: 'center',
    width: 250,
    fontStyle: 'italic',
    marginVertical: 5,
    fontSize: 14,
  },
  appointmentMobileText: {
    textAlign: 'center',
    width: '100%',
    fontStyle: 'italic',
    marginVertical: 5,
    fontSize: 14,
  },
  buttonsContainer: {
    paddingHorizontal: 10,
  },
  iconContainer: {
    position: 'relative',
  },
  tooltipContainer: {
    width: 225,
    borderWidth: 1,
    borderColor: '#868686',
    position: 'absolute',
    bottom: 20,
    left: -150,
    right: 250,
    zIndex: 10,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
  },
  rejectionReason: {
    marginBottom: 5, 
  },
});

export default TestRow;
