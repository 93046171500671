import React, { createElement } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Control, Controller, FieldError, Noop } from 'react-hook-form';

import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import GlobalStyles from 'constants/Styles';
import { PersonalInfoRegistrationFields } from 'types';
import { MaskedTextInput } from 'react-native-mask-text';

const PatientInformation = ({
  control,
}: {
  control: Control<PersonalInfoRegistrationFields>;
}): JSX.Element => {
  const isDate18orMoreYearsOld = (dob: string) => {
    const dobValues = dob.split('-');
    const year = parseInt(dobValues[0]);
    const month = parseInt(dobValues[1]);
    const day = parseInt(dobValues[2]);

    return new Date(year + 18, month - 1, day) <= new Date();
  };

  const validatingOldDate = (mydate: string) => {
    const isdate = Date.parse(mydate);
    if (Number.isNaN(isdate)) {
      return false;
    }

    const enteredDate = new Date(isdate);
    const todayIs = new Date();

    if (isDate18orMoreYearsOld(mydate) === false) {
      return 'You must be 18 or older \n to take a test.';
    }
    if (enteredDate.getFullYear() < todayIs.getFullYear() - 150) {
      return 'Please enter a valid birthdate.';
    }

    return undefined;
  };

  const DateTimePicker = ({
    onChange,
    value,
    onBlur,
    error,
    testID,
    accessible,
  }: {
    // eslint-disable-next-line no-unused-vars
    onChange: (e: string) => void;
    error: FieldError | undefined;
    value: string;
    onBlur: Noop;
    testID: string;
    accessible: boolean;
  }) =>
    createElement('input', {
      type: 'date',
      max: '9999-12-31',
      value,
      onInput: onChange,
      onBlur,
      testID,
      accessible,
      style: {
        flexDirection: 'row-reverse',
        height: 48,
        margin: '10px 0px',
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
        borderWidth: 1,
        borderColor: typeof error === 'object' ? '#DC412C' : '#F7F7F7',
        borderRadius: 4,
        borderStyle: 'solid',
      },
    });

  return (
    <>
      <View style={styles.nameRow}>
        <Controller
          control={control}
          rules={{
            required: 'This field is required',
          }}
          name={'firstName'}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <View style={styles.marginField}>
              <Text style={styles.fieldLabel}>First Name</Text>
              <TextInput
                onChangeText={onChange}
                onBlur={onBlur}
                value={value as string}
                style={[
                  GlobalStyles.input,
                  styles.nameField,
                  error && GlobalStyles.inputError,
                ]}
                testID={'firstName'}
                accessible={true}
              />
            </View>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: 'This field is required',
          }}
          name={'lastName'}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <View style={styles.column}>
              <Text style={styles.fieldLabel}>Last Name</Text>
              <TextInput
                onChangeText={onChange}
                onBlur={onBlur}
                value={value as string}
                style={[
                  GlobalStyles.input,
                  styles.nameField,
                  error && GlobalStyles.inputError,
                ]}
                testID={'lastName'}
                accessible={true}
              />
            </View>
          )}
        />
      </View>
      <View style={styles.row}>
        <Controller
          control={control}
          rules={{
            required: 'This field is required',
            minLength: {
              value: 14,
              message: 'Phone number is not valid',
            },
          }}
          name={'phone'}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <View style={styles.marginField}>
              <Text style={styles.fieldLabel}>Phone Number</Text>
              <MaskedTextInput
                mask="(999) 999-9999"
                value={value}
                style={[GlobalStyles.input, error && GlobalStyles.inputError]}
                keyboardType="numeric"
                testID="inputPhone"
                onBlur={onBlur}
                onChangeText={onChange}
              />
            </View>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: 'This field is required',
            validate: validatingOldDate,
          }}
          name={'birthDate'}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <View style={[styles.marginField]}>
              <Text style={styles.fieldLabel}>Date of Birth (MM/DD/YYYY)</Text>
              <DateTimePicker
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                testID={'birthDate'}
                accessible={true}
                error={error}
              />
            </View>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: 'This field is required',
          }}
          name={'gender'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <View style={styles.column}>
              <Text style={styles.fieldLabel}>Sex Assigned at Birth</Text>
              <View style={styles.row}>
                <TouchableOpacity
                  style={[styles.radioContainer]}
                  onPress={() => onChange('male')}
                >
                  <View
                    style={[
                      styles.radioButton,
                      error && GlobalStyles.inputError,
                    ]}
                    testID="maleBtn"
                  >
                    {value === 'male' ? (
                      <View style={styles.radioSelected} />
                    ) : null}
                  </View>
                  <Text
                    style={[styles.maleLabel, error && styles.errorMessage]}
                  >
                    Male
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.radioContainer}
                  onPress={() => onChange('female')}
                >
                  <View
                    style={[
                      styles.radioButton,
                      error && GlobalStyles.inputError,
                    ]}
                    testID="femaleBtn"
                  >
                    {value === 'female' ? (
                      <View style={styles.radioSelected} />
                    ) : null}
                  </View>
                  <Text
                    style={[styles.femaleLabel, error && styles.errorMessage]}
                  >
                    Female
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  fieldLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 0.8,
    color: '#2F2F2F',
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nameField: {
    width: 275,
  },
  phoneField: {
    width: 175,
  },
  birthDateField: {
    width: 180,
  },
  marginField: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 26,
  },
  radioButton: {
    height: 16,
    width: 16,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioSelected: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: '#000',
  },
  maleLabel: {
    marginLeft: 7,
    marginRight: 30,
    fontSize: 14,
  },
  femaleLabel: { marginLeft: 7, fontSize: 14 },
  errorMessage: {
    color: '#DC412C',
  },
});

export default PatientInformation;
