import * as React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Localization } from 'types';
import fetch from 'cross-fetch';

export const httpLink = createHttpLink({
  uri: 'https://graphql.datocms.com/environments/prod-patient-console',
  fetch,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        JSON.stringify(message, null, 2),
        JSON.stringify(locations, null, 2),
        JSON.stringify(path, null, 2)
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const authLink = setContext((_, { headers }) => ({
  headers: Object.assign(headers || {}, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: '04caeb93d6ac5f4b6c771e36f89187',
  }),
}));
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
export const ReportLocalization = React.createContext<Localization | null>(
  null
);

export const ALL_PARTNER_CONFIGS = gql`
  query AllPartnerConfigs {
    allPartnerConfigs {
      name
      navigationItems
      theme
      key
    }
  }
`

export const ALL_REPORTS = (locale = 'en') =>
  gql`
    query allReports {
      allReports(locale: ${locale}) {
        title
        pages {
          ... on ReportOverviewPageRecord {
            name
            welcomeMessage
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResultsPageRecord {
            name
            title
            scenarios {
              name
              code {
                name
                developerConfig
              }
              summary
              explanation
              nextSteps
            }
            biomarkerPanels {
              title
              biomarkers {
                name
                shortName
                note
                units
                ranges
                developerConfig
                scenarioCode {
                  developerConfig
                }
                elevatedRecs
                lowRecs
              }
              description
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportDetailsPageRecord {
            name
            title
            subtitle
            description
            biomarkers {
              name
              note
              units
              ranges
              developerConfig
              scenarioCode {
                developerConfig
              }
              elevatedRecs
              lowRecs
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportBackgroundPageRecord {
            name
            title
            subtitle
            description
            familyHistory
            pastMedicalHistory
            questions {
              familyHistory
              pastMedicalHistory
              yes
              no
              name
              developerConfig
            }
            breadcrumb
            goToButton
            scenarios {
                name
                code {
                  name
                  developerConfig
                }
                summary
            }
            __typename
          }
          ... on ReportRecommendationsPageRecord {
            name
            title
            subtitle
            description
            defaultDescription
            recommendations {
              title
              icon {
                url
              }
              code {
                name
                developerConfig
              }
              description
              link
              recommendationId
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResourcesPageRecord {
            name
            title
            subtitle
            description
            resources {
              title
              thumbnail {
                url
              }
              preview
              author
              link
            }
            breadcrumb
            goToButton
            __typename
          }
        }
        doctors {
          name
          positionTitle
          avatar {
            url
          }
        }
        theme {
          name
          primaryColor {
            hex
          }
        }
        developerConfig
      }
    }      
`;

export const REPORT_LOCALIZATION = (locale = 'en') => gql`
  query reportLocalization {
    reportLocalization(locale: ${locale}) {
      biomarker
      biomarkerCutOff
      biomarkerElevatedOutcome
      biomarkerOptimalOutcome
      biomarkerLowOutcome
      biomarkerNormalOutcome
      biomarkerPositiveOutcome
      biomarkerNegativeOutcome
      biomarkerQualitative
      biomarkerQuantitative
      biomarkerReportableRange
      biomarkerTargetRange
      headerBackToDashboard
      headerDownloadPdfReport
      navigationBackButton
      learnMore
      showMore
      activateTest
      myTests
      settings
      signOut
      brand
    }
  }
`;

export default client;
