import { gql } from '@apollo/client';

export const ALL_TEST_REQUIREMENTS = gql`
  query allTestRequirements {
    allTestRequirements(locale: en) {
      title
      instructionType {
        name
        instructionSteps {
          name
          image {
            url
          }
          title
          subtitle
          description
        }
        developerConfig
      }
      prepRequirements {
        title
        description
        link
      }
      developerConfig
    }
  }
`;

export const GET_PLAN_DEFINITIONS = gql`
  query GetPlanDefinitions {
    getPlanDefinitions {
      name
      identifier
    }
  }
`;