import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { RescheduleBtnInterface } from 'types';

const RescheduleBtn = ({
  onOpenModal,
  appointment,
}: RescheduleBtnInterface): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const consultId = appointment?.consultId ? appointment?.consultId : '0';

  const renderDescription = () => {
    const appointmentAvailable = appointment?.ableToReschedule;
    const appointmentStatus = appointment?.status;
    if (appointmentAvailable && appointmentStatus === 'cancelled') {
      return (
        <Text style={styles.rescheduleText}>
          Your healthcare provider can no longer make your telehealth
          appointment. Please reschedule your appointment.
        </Text>
      );
    } else {
      return (
        <Text>
          You have an upcoming telehealth appointment{' '}
          <Text style={styles.rescheduleText}>
            {appointment?.startFormatted ? appointment?.startFormatted : 'N/A'}{' '}
            with {appointment?.clinician ? appointment?.clinician : 'N/A'}.
          </Text>
        </Text>
      );
    }
  };

  return (
    <View
      style={
        isMobile ? styles.rescheduleContainerMobile : styles.rescheduleContainer
      }
    >
      {renderDescription()}
      <TouchableOpacity
        style={styles.rescheduleBtn}
        onPress={() => onOpenModal('', true, consultId)}
      >
        <Text>Reschedule</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  rescheduleContainer: {
    backgroundColor: 'rgba(68, 143, 255, 0.3)',
    opacity: 0.8,
    height: 85,
    display: 'flex',
    paddingHorizontal: 15,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'center',
  },
  rescheduleContainerMobile: {
    backgroundColor: 'rgba(68, 143, 255, 0.3)',
    opacity: 0.8,
    display: 'flex',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'center',
  },
  rescheduleBtn: {
    borderColor: 'black',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: 200,
    alignItems: 'center',
    marginVertical: 10,
  },
  rescheduleText: { fontWeight: '600' },
});

export default RescheduleBtn;
