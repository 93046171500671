import React from 'react';

import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { Alert, StyleSheet, View, Image } from 'react-native';
import * as Linking from 'expo-linking';
import { DrawerActions } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { useMediaQuery } from 'react-responsive';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import ScanQRCodeScreen from 'screens/kitActivation/ScanQRCodeScreen';
import EnterKitIdScreen from 'screens/kitActivation/EnterKitIdScreen';
import ConfirmTestingRequirementsScreen from 'screens/kitActivation/ConfirmTestingRequirementsScreen';
import PatientDashboardScreen from 'screens/dashboard/PatientDashboardScreen';
import ResetPasswordScreen from 'screens/login/ResetPasswordScreen';
import CompletePatientProfileScreen from 'screens/registration/CompletePatientProfileScreen';

import client from 'kit-activation';
import Button from 'components/StyledButton';
import GlobalStyles from 'constants/Styles';
import { logoutUser } from 'auth/auth0';
import SuccessResetPasswordScreen from 'screens/login/SuccessResetPasswordScreen';
import AccountDetailsScreen from 'screens/setting/AccountDetailsScreen';
import { ReportNavigatorStack } from './ReportNavigator';

const AuthStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const authenticatedScreens = () => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <AuthStack.Navigator headerMode="none">
      <AuthStack.Screen name="PatientDashboardScreen">
        {(props) => <PatientDashboardScreen isDesktop={!isMobile} {...props} />}
      </AuthStack.Screen>
      <AuthStack.Screen
        name="CompletePatientProfileScreen"
        component={CompletePatientProfileScreen}
      />
      <AuthStack.Screen name="EnterKitIdScreen" component={EnterKitIdScreen} />
      <AuthStack.Screen name="ScanQRCodeScreen" component={ScanQRCodeScreen} />
      <AuthStack.Screen
        name="ConfirmTestingRequirementsScreen"
        component={ConfirmTestingRequirementsScreen}
      />
      <AuthStack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
      />
      <AuthStack.Screen
        name="SuccessResetPasswordScreen"
        component={SuccessResetPasswordScreen}
      />
      <AuthStack.Screen name="Report" component={ReportNavigatorStack} />
      <AuthStack.Screen name="AccountDetailsScreen">
        {(props) => <AccountDetailsScreen isDesktop={!isMobile} {...props} />}
      </AuthStack.Screen>
    </AuthStack.Navigator>
  );
};

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const onLogoutPress = () =>
    client
      .clearStore()
      .then(() => logoutUser())
      .catch((err) => Alert.alert(err.message));

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={styles.drawerContainer}
    >
      <View
        style={
          isMobile
            ? styles.drawerItemsContainer
            : styles.drawerItemsContainerDesktop
        }
      >
        <View style={styles.drawerLogoBox}>
          {isMobile ? (
            <Ionicons
              name="close"
              size={34}
              color="black"
              testID="closeButton"
              onPress={() => {
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          ) : (
            <Image
              source={require('../assets/images/logo.png')}
              style={styles.logo}
            />
          )}
        </View>
        <DrawerItemList {...props} />
        <DrawerItem
          onPress={() => Linking.openURL('https://support.imaware.health/')}
          style={isMobile ? styles.drawerItem : styles.drawerDesktopItem}
          icon={() =>
            !isMobile && (
              <Ionicons name="help-circle-outline" size={24} color="black" />
            )
          }
          inactiveTintColor="#000000"
          label="Help and Support"
        />
      </View>

      <Button
        onPress={onLogoutPress}
        style={(GlobalStyles.button, styles.logoutButton)}
        bold
      >
        Logout
      </Button>
    </DrawerContentScrollView>
  );
};

const DrawerNavigator = ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <Drawer.Navigator
      drawerContent={(props) => (
        <CustomDrawerContent {...props} navigation={navigation} />
      )}
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
        },
        headerShown: isMobile,
        swipeEnabled: false,
      }}
      drawerContentOptions={{
        activeBackgroundColor: '#E1E9F3',
        activeTintColor: 'black',
        inactiveTintColor: 'black',
      }}
    >
      <Drawer.Screen
        name="Dashboard"
        component={authenticatedScreens}
        options={{
          title: 'Dashboard',
          headerTitle: () => (
            <Image
              source={require('assets/images/logo.png')}
              style={styles.imawareLogo}
            />
          ),
          drawerIcon: () =>
            !isMobile && (
              <Ionicons name="home-outline" size={24} color="black" />
            ),
        }}
      />
      <Drawer.Screen
        name="Settings"
        component={AccountDetailsScreen}
        options={{
          drawerIcon: () =>
            !isMobile && (
              <Ionicons name="lock-closed-outline" size={24} color="black" />
            ),
        }}
      />
      <Drawer.Screen
        name="Change Password"
        component={ResetPasswordScreen}
        options={{
          drawerIcon: () =>
            !isMobile && (
              <Ionicons name="lock-closed-outline" size={24} color="black" />
            ),
        }}
      />
    </Drawer.Navigator>
  );
};

export default DrawerNavigator;

const styles = StyleSheet.create({
  drawerContainer: {
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: 'white',
    paddingBottom: 60,
  },
  drawerItemsContainer: {
    paddingTop: 30,
    paddingHorizontal: 10,
  },
  drawerItemsContainerDesktop: {
    paddingHorizontal: 0,
  },
  drawerItem: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#000000',
    paddingLeft: 10,
  },
  drawerDesktopItem: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#000000',
    backgroundColor: 'white',
    paddingHorizontal: 10,
  },
  logoutButton: {
    alignSelf: 'center',
    width: '90%',
    justifyContent: 'center',
  },
  logo: {
    width: 130,
    aspectRatio: 5,
    height: 100,
    resizeMode: 'contain',
    display: 'flex',
    alignItems: 'flex-start',
  },
  drawerLogoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 100,
    paddingHorizontal: 10,
  },
  imawareLogo: {
    width: 120,
    aspectRatio: 5,
    height: 21,
    resizeMode: 'contain',
  },
});
