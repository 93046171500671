import React, { useState } from 'react';
import { Image, TouchableOpacity, View, StyleSheet } from 'react-native';
import Text from 'components/StyledText';
import { SimpleLineIcons } from '@expo/vector-icons';

const MissingBiomarkers = ({
  expanded,
  biomarkers,
  setExpanded,
}: {
  expanded: boolean;
  biomarkers: string[];
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <View style={styles.missingBiomarkerContainer}>
          <View style={styles.missingBiomarkerSubContainer}>
            <View style={styles.missingBiomarkerTextContainer}>
              <Text
                style={[
                  styles.missingBiomarkerText,
                  expanded && styles.expanded,
                ]}
                fontWeight="500"
              >
                We were unable to gather enough data for certain biomarkers and
                apologize for the inconvenience. Please contact{' '}
                <a href="mailto:support@imaware.health">
                  support@imaware.health
                </a>{' '}
                for further assistance.
                <View
                  onMouseEnter={() => setTooltipVisible(true)} 
                  onMouseLeave={() => setTooltipVisible(false)}
                >
                  <SimpleLineIcons
                    name="question"
                    size={20}
                    color="gray"
                    style={{ marginLeft: 5, textAlignVertical: 'center' }}
                  />
                </View>
                {tooltipVisible ? (
                  <>
                    <Image
                      source={require('../../../assets/images/triangle-left.png')}
                      style={styles.triangle}
                    />
                    <Image
                      source={require('../../../assets/images/rectangle-white.png')}
                      style={styles.rectangle}
                    />
  
                    <View style={styles.helpText}>
                      <Text units>
                        We were not able to test all of the biomarkers included
                        in the panel. This is either due to insufficient volume
                        of the blood sample or the blood sample not passing
                        quality inspection at the lab. The biomarkers that were
                        not able to be tested are listed below.
                      </Text>
                    </View>
                  </>
                ) : null}
              </Text>
            </View>
            <View
              style={[
                styles.arrowContainer,
                expanded && { transform: [{ rotate: '180deg' }] },
              ]}
            >
              <Image
                style={styles.arrow}
                source={require('assets/images/arrow.png')}
              />
            </View>
          </View>
          {expanded
            ? biomarkers.map((biomarker) => (
              <Text
                style={[styles.missingBiomarkerText, styles.biomarkerName]}
                key={biomarker}
              >
                {biomarker}
              </Text>
            ))
            : null}
        </View>
      </TouchableOpacity>
    </>
  )
};

const styles = StyleSheet.create({
  missingBiomarkerContainer: {
    backgroundColor: '#FCECEB',
    paddingVertical: 19,
    paddingHorizontal: 35,
    borderRadius: 10,
  },
  missingBiomarkerSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  missingBiomarkerTextContainer: {
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  missingBiomarkerText: {
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: 20,
    width: '100%',
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  arrow: {
    width: 16,
    height: 9,
  },
  biomarkerName: {
    marginTop: 5,
  },
  expanded: {
    marginBottom: 15,
  },
  triangle: { width: 10, height: 10, zIndex: 2, right: -6 },
  rectangle: { width: 5, height: 10, zIndex: 6, right: -4 },
  helpText: {
    borderWidth: 1,
    borderColor: '#868686',
    position: 'absolute',
    bottom: -70,
    maxWidth: 400,
    zIndex: 1,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
  },
});

export default MissingBiomarkers;
