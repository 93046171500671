import React, { useState, useCallback, useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useQuery } from '@apollo/client';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import { AccountInformation, PartnerConfig } from 'types';
import { GET_PATIENT } from 'screens/dashboard/PatientDashboardScreen/queries';
import GlobalStyles from 'constants/Styles';
import DesktopNavigation from 'components/DesktopNavigation';

import PersonalInformation from '../components/PersonalInformation';
import AddressInformation from '../components/AddressInformation';
import PasswordInformation from '../components/PasswordInformation';
import { UPDATE_PATIENT_ADDRESS } from './queries';

const AccountDetailsScreen = ({
  navigation,
  isDesktop,
}: {
  navigation: StackNavigationHelpers;
  isDesktop: boolean;
}) => {
  const [errorFlag, setErrorFlag] = useState<boolean>(true);

  const isMobile = useMediaQuery({
    maxWidth: 850,
  });

  const [patientInformation, setPatientInformation] =
    useState<AccountInformation>({
      name: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      birthDate: '',
      gender: '',
      address: {
        city: '',
        line: [],
        postalCode: '',
        country: '',
        state: '',
      },
    });

  const methods = useForm<AccountInformation>({
    mode: 'onBlur',
    defaultValues: patientInformation,
  });

  const { handleSubmit, reset } = methods;

  const { loading: queryLoading } = useQuery(GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPatient }) => {
      const response = {
        ...patientInformation,
        name: getPatient?.name?.[0]?.given?.[0],
        lastname: getPatient?.name?.[0].family,
        email: getPatient?.telecom?.[1].value,
        phoneNumber: getPatient?.telecom?.[0].value,
        birthDate: getPatient?.birthDate,
        gender: getPatient?.gender,
        address: {
          city: getPatient?.address[0]?.city,
          line: getPatient?.address[0].line[1]
            ? [getPatient?.address[0]?.line[0], getPatient?.address[0]?.line[1]]
            : [getPatient?.address[0]?.line[0], ''],
          postalCode: getPatient?.address[0]?.postalCode,
          state: getPatient?.address[0]?.state,
          country: getPatient?.address[0]?.country,
        },
      };
      reset(response);
      setPatientInformation(response);
    },
  });

  useEffect(() => {
    reset(patientInformation);
  }, [patientInformation]);

  const [
    updateAddress,
    { loading: updatingAddressLoading, error: updatingAddressError },
  ] = useMutation(UPDATE_PATIENT_ADDRESS, {
    onCompleted: () => setErrorFlag(false),
    onError: () => setErrorFlag(true),
  });

  const updateInformation = (data: AccountInformation) => {
    updateAddress({
      variables: {
        addressInput: data.address,
      },
    });
  };

  const showResponseText = useCallback(() => {
    if (errorFlag && updatingAddressError) {
      return (
        <Text
          style={[GlobalStyles.error, styles.textError]}
          testID="errorMessage"
        >
          An error has occurred while updating your information, please try
          again.
        </Text>
      );
    }

    if (!errorFlag) {
      return (
        <Text style={styles.textSuccess} testID="successMessage">
          Updates have been saved.
        </Text>
      );
    }

    return null;
  }, [errorFlag, updatingAddressError]);

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  return (
    <View style={Platform.OS === 'web' ? styles.container : {}}>
      {isDesktop ? (
        <DesktopNavigation
          navigation={navigation}
          componentLabel={'AccountDetailsScreen'}
          partnerConfig={partnerConfig}
        />
      ) : null}
      <ScreenWrapper loading={queryLoading || updatingAddressLoading}>
        <View style={styles.box}>
          <View style={styles.header}>
            <Button
              style={styles.dashboardButton}
              textStyle={styles.headerButtonText}
              ignoreWidth
              onPress={() => navigation.navigate('PatientDashboardScreen')}
            >
              Back to Dashboard
            </Button>
          </View>
          <View style={styles.subContainer}>
            <View
              style={
                isMobile ? styles.mobileContainer : styles.desktopContainer
              }
            >
              <Text
                lato
                large
                fontWeight="500"
                style={styles.title}
                testID="title"
              >
                Account Details
              </Text>
              <FormProvider {...methods}>
                <PersonalInformation {...patientInformation} />
                <AddressInformation />
                <PasswordInformation navigation={navigation} />
                <Button
                  onPress={handleSubmit(updateInformation)}
                  primary
                  arrowRight
                  style={isMobile ? styles.btnMobileText : styles.btnText}
                  testID="confirmBtn"
                >
                  Confirm
                </Button>
                {showResponseText()}
              </FormProvider>
            </View>
          </View>
        </View>
      </ScreenWrapper>
    </View>
  );
};

export default AccountDetailsScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  subContainer: {
    paddingTop: 15,
    backgroundColor: '#fbf8f1',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flex: 2,
    paddingHorizontal: 15,
  },
  inputDesktop: {
    width: '100%',
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    borderWidth: 0,
  },
  desktopContainer: {
    width: 650,
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  mobileContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '600',
    fontFamily: 'lora_500',
  },
  textError: { width: '100%', textAlign: 'center', paddingVertical: 25 },
  textSuccess: {
    fontWeight: '600',
    color: '#008000eb',
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
    paddingVertical: 25,
  },
  btnText: {
    width: 250,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  btnMobileText: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  headerButtonText: { fontSize: 14, fontFamily: 'lato_400' },
  dashboardButton: {
    width: 192,
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 36,
    marginRight: 50,
    position: 'relative',
    top: -10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 15,
  },
});
