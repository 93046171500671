import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import { useMediaQuery } from 'react-responsive';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import GlobalStyles from 'constants/Styles';
import Text from 'components/StyledText';
import { Timeslot, DateSelected } from 'types';

import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import { ScrollView } from 'react-native-gesture-handler';
import TimeButton from './components/TimeButton';
import { GET_TIMESLOTS } from '../queries';

const DateSelection = ({
  setDateSelected,
  dateSelected,
  consultId,
}: {
  // eslint-disable-next-line no-unused-vars
  setDateSelected: (res: DateSelected) => void;
  dateSelected: DateSelected | null;
  consultId: string | null;
}): JSX.Element => {
  const [currentConsults, setCurrentConsults] = useState<Timeslot[] | null>(
    null
  );
  const isMobile = useMediaQuery({
    maxWidth: 900,
  });

  const [
    getTimeslots,
    { loading: getTimeslotsLoading, error: getTimeslotsError },
  ] = useLazyQuery(GET_TIMESLOTS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTimeslots: getTimeslotsData }) => {
      setCurrentConsults(getTimeslotsData);
    },
    onError: () => null,
  });

  useEffect(() => {
    if (consultId !== null) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const datePicked = moment(dateSelected?.currentDayPicked).format(
        'YYYY-MM-DD'
      );
      getTimeslots({
        variables: {
          id: consultId,
          timezone,
          start: datePicked,
          end: datePicked,
        },
      });
    }
  }, [dateSelected]);

  const calculateDuration = (response: Timeslot) => {
    if (response.start && response.end) {
      const startTime = new Date(response.start);
      const endTime = new Date(response.end);
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);
      return resultInMinutes;
    }
    return 0;
  };

  const onChangeTimeSlot = (response: Timeslot) => {
    const res = calculateDuration(response);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setDateSelected({
      ...dateSelected,
      data: response,
      consultDuration: res,
      timezone,
    });
  };

  const renderTimes = () => {
    if (currentConsults?.length) {
      return currentConsults.map((timeslot) => {
        const date = new Date(timeslot.start);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        let minutesString = minutes.toString();
        const suffix = hours >= 12 ? 'PM' : 'AM';
        if (hours > 12) {
          hours -= 12;
        }
        if (hours === 0) {
          hours = 12;
        }
        if (minutes < 10) {
          minutesString = `0${minutesString}`;
        }
        const time = `${hours}:${minutesString} ${suffix}`;
        return (
          <TimeButton
            time={time}
            setDateSelected={onChangeTimeSlot}
            dateSelected={dateSelected}
            date={timeslot.start}
            timeData={timeslot}
          />
        );
      });
    } else if (currentConsults?.length === 0) {
      return (
        <Text style={styles.noTimeslotText} fontWeight={'500'}>
          There are no consults available for the selected day. Please select
          another day.
        </Text>
      );
    } else {
      return null;
    }
  };

  return (
    <View style={styles.mobileContainer}>
      <Text style={styles.subheader}>
        Select a date and time for your appointment.
      </Text>
      <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
        <View
          style={[
            styles.column,
            isMobile ? styles.calendarMobile : styles.calendar,
          ]}
        >
          <CalendarPicker
            width={isMobile ? 300 : 275}
            height={350}
            selectedDayColor="#116FA4"
            todayTextStyle={styles.selectedDateText}
            minDate={new Date()}
            disabledDates={[]}
            initialDate={dateSelected?.currentDayPicked || undefined}
            onDateChange={(res) => {
              const date = new Date(moment(res).format('MM/DD/YYYY'));
              setDateSelected({ ...dateSelected, currentDayPicked: date });
            }}
            onMonthChange={(res) => {
              const date = new Date(moment(res).format('MM/DD/YYYY'));
              setDateSelected({ ...dateSelected, currentDayPicked: date });
            }}
            selectedStartDate={dateSelected?.currentDayPicked || undefined}
          />
        </View>
        <View
          style={[styles.column, isMobile ? styles.timesMobile : styles.times]}
        >
          <View>
            <Text>Available Times: </Text>
            <LoadingErrorWrapper loading={getTimeslotsLoading} error={null}>
              <ScrollView style={styles.timesContainer}>
                <View style={styles.timeBox}>{renderTimes()}</View>
              </ScrollView>
            </LoadingErrorWrapper>
          </View>
          <View>
            <Text style={styles.text}>Price: Free</Text>
            <Text style={styles.text}>Format: Video call</Text>
            <Text style={styles.text}>
              Duration:{' '}
              {dateSelected?.consultDuration
                ? `${dateSelected?.consultDuration} minutes`
                : '-'}
            </Text>
          </View>
          {getTimeslotsError ? (
            <Text lato style={[GlobalStyles.error, styles.errorText]}>
              Something went wrong while trying to get available timeslots.
              Please contact{' '}
              <a
                href="mailto:support@imaware.health"
                style={{ color: '#DC412C' }}
              >
                support@imaware.health
              </a>{' '}
              for further assistance
            </Text>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  desktopContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 60,
    width: '50%',
  },
  calendar: {
    width: '45%',
  },
  calendarMobile: {
    width: '100%',
  },
  times: {
    width: '55%',
  },
  timesMobile: {
    width: '100%',
  },
  subheader: {
    textAlign: 'center',
    width: '100%',
  },
  selectedDateText: {
    color: 'white',
  },
  timesContainer: {
    marginVertical: 15,
    height: 150,
  },
  text: {
    marginVertical: 5,
  },
  noTimeslotText: {
    marginVertical: 15,
  },
  errorText: {
    marginVertical: 15,
    textAlign: 'center',
  },
  timeBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default DateSelection;
