import React, { useState } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import {
  ReportContainer,
  ContentHeader,
  Paragraph,
} from 'components/ReportCommon';
import { ContentSummaryContainer } from 'components/Common';
import { PanelContainer, Panels, ResultSummary } from 'components/Results';
import ScreenWrapper from 'components/ScreenWrapper';
import MissingBiomarkers from 'components/report/MissingBiomarkers';

import cmsClient, { ALL_REPORTS } from 'sample-collection-instructions';
import reportClient, { GET_KIT_ID } from 'kit-activation';
import { getReport, getPage, getScenarioContent } from 'utils/content';
import { formatObservations, calculateReportCode } from 'utils/data';
import {
  ColorCode,
  GetTestResultsData,
  GetTestResultsVariables,
  Page,
  Panel,
  RecommendationBiomarkerKey,
  Result,
  Sex,
} from 'types';

import GET_TEST_RESULTS from './queries';

export default ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [unresultedBiomarkers, setUnresultedBiomarkers] = useState<string[]>(
    []
  );
  const [expanded, setExpanded] = useState(false);
  const [biomarkerPanels, setBiomarkerPanels] = useState<Panel[]>([]);
  const [reportCode, setReportCode] = useState<ColorCode>('opt-green');
  const [summary, setSummary] = useState<string>('');
  const [sex, setSex] = useState<Sex>('' as Sex);
  const [explanation, setExplanation] = useState('');
  const [title, setTitle] = useState('');
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] =
    useState<ApolloError | null>(null);

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = data?.kitId ?? '';

  const [getTestResults, { loading: resultsLoading, error: resultsError }] =
    useMutation<GetTestResultsData, GetTestResultsVariables>(GET_TEST_RESULTS, {
      variables: {
        kitId,
      },
      onCompleted: ({ getTestResults: testResultsData }) => {
        const {
          patient: { gender },
          testType,
          observations,
        } = testResultsData;
        const testResults = formatObservations(observations);
        const { pages: unfilteredPages, title: reportTitle } = getReport(
          content,
          testType
        );
        const page = getPage(unfilteredPages, 'ReportResultsPageRecord');
        const { biomarkerPanels: panels = [], scenarios = [] } = page;
        const { biomarkers = [] } = getPage(
          unfilteredPages,
          'ReportDetailsPageRecord'
        );
        const calculatedReportCode = calculateReportCode(
          testResults,
          biomarkers,
          gender
        );
        const {
          summary: scenarioSummary,
          explanation: scenarioExplanation = '',
        } = getScenarioContent(scenarios, calculatedReportCode);
        setSex(gender);
        setResults(testResults);
        setSummary(scenarioSummary);
        setExplanation(scenarioExplanation);
        const filteredPages = unfilteredPages.filter(
          (p) => p.goToButton !== 'View Background'
        );
        const testedBiomarkerNames = [
          ...new Set(biomarkers.map((b) => b.name.split(' - ')[0])),
        ];
        const resultedBiomarkerCodes = testResults.map((r) => r.key);
        const resultedBiomarkerNames = biomarkers
          .filter((b) =>
            resultedBiomarkerCodes.includes(
              b.developerConfig.key as RecommendationBiomarkerKey
            )
          )
          .map((b) => b.name.split(' - ')[0]);
        const unresultedBiomarkerNames: string[] = [];
        testedBiomarkerNames.forEach((n) => {
          if (!resultedBiomarkerNames.includes(n)) {
            unresultedBiomarkerNames.push(n);
          }
        });
        setUnresultedBiomarkers(unresultedBiomarkerNames);
        setReportCode(calculatedReportCode);
        setBiomarkerPanels(panels);
        setPages(filteredPages);
        setTitle(reportTitle);
      },
      onError: (err) =>
        console.error(
          `An error occurred during GetTestResults Mutation with kit id: ${kitId}`,
          err
        ),
    });
  const {
    loading: contentLoading,
    error: contentError,
    data: content,
  } = useQuery(ALL_REPORTS(), {
    client: cmsClient,
    onCompleted: () => getTestResults(),
    onError: (err) =>
      console.error('An error occurred during AllReports Query', err),
  });

  return (
    <ScreenWrapper
      loading={contentLoading || resultsLoading || reportDownloadLoading}
      error={contentError || resultsError || reportDownloadError}
      excludeWrapper
      excludeFooter
    >
      <ReportContainer
        pages={pages}
        navigation={navigation}
        title={title}
        screen={'ResultsScreen'}
        setLoading={setReportDownloadLoading}
        setError={setReportDownloadError}
      >
        <ContentHeader title={title} />
        <ContentSummaryContainer>
          <ResultSummary code={reportCode}>{summary}</ResultSummary>
          <Paragraph leftAlignOnMobile>{explanation}</Paragraph>
        </ContentSummaryContainer>
        <PanelContainer>
          <Panels panels={biomarkerPanels} results={results} sex={sex} />
          {unresultedBiomarkers.length ? (
            <MissingBiomarkers
              expanded={expanded}
              biomarkers={unresultedBiomarkers}
              setExpanded={setExpanded}
            />
          ) : null}
        </PanelContainer>
      </ReportContainer>
    </ScreenWrapper>
  );
};
