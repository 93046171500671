import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { DateSelected, Timeslot } from 'types';

const TimeButton = ({
  time,
  date,
  dateSelected,
  setDateSelected,
  timeData,
}: {
  time: string;
  date: Date;
  // eslint-disable-next-line no-unused-vars
  setDateSelected: (response: Timeslot) => void;
  dateSelected: DateSelected | null;
  timeData: Timeslot;
}): JSX.Element => {
  const formattedTime = time;

  const onTimePress = () => {
    setDateSelected(timeData);
  };

  return (
    <TouchableOpacity onPress={onTimePress}>
      <View
        style={[
          date.toString() === dateSelected?.data?.start.toString()
            ? styles.activeContainer
            : styles.inactiveContainer,
          styles.timeButton,
        ]}
      >
        <Text
          style={[
            date.toString() === dateSelected?.data?.start.toString()
              ? styles.activeText
              : styles.inactiveText,
            styles.time,
          ]}
        >
          {formattedTime}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default TimeButton;

const styles = StyleSheet.create({
  timeButton: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 10,
  },
  activeContainer: {
    backgroundColor: '#116FA4',
  },
  inactiveContainer: {
    backgroundColor: '#ffffff',
  },
  time: {
    fontSize: 14,
    fontWeight: '700',
    fontFamily: 'Lato',
  },
  activeText: {
    color: '#ffffff',
    fontFamily: 'lato',
  },
  inactiveText: {
    color: '#666666',
    fontFamily: 'lato',
  },
});
