import StartTelehealth from './StartTelehealth';
import Questionnaire from './Questionnaire';
import DateSelection from './DateSelection';
import ConsultConfirmation from './ConsultConfirmation';
import SuccessError from './SuccessError';

const StartOption = {
  component: StartTelehealth,
  name: 'StartTelehealth',
  actions: [],
};

const QuestionnaireOption = {
  component: Questionnaire,
  name: 'Questionnaire',
  actions: [],
};

const DateSelectionOption = {
  component: DateSelection,
  name: 'DateSelection',
  actions: [],
};

const ConfirmationOption = {
  component: ConsultConfirmation,
  name: 'ConsultConfirmation',
  actions: [],
};

const SuccessErrorOption = {
  component: SuccessError,
  name: 'SuccessError',
  actions: [],
};

export const modalComponents = [
  StartOption,
  QuestionnaireOption,
  DateSelectionOption,
  ConfirmationOption,
  SuccessErrorOption,
];
