import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import client, { GET_KIT_ID } from 'kit-activation';
import {
  LIST_QUESTIONNAIRES,
  GET_PATIENT,
  GET_PATIENT_TEST_HISTORY,
} from 'screens/dashboard/PatientDashboardScreen/queries';
import ModalComponent from 'components/Modal';
import {
  DateSelected,
  ModalActions,
  Patient,
  QuestionnaireQuestions,
  IntakeData,
} from 'types';

import { modalComponents } from './utils';
import {
  CREATE_CONSULT,
  FINALIZE_CONSULT,
  RESCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT,
} from './queries';

const TelehealthConsult = ({
  onCloseModal,
  rescheduleConsult = false,
  consultIdPicked,
}: {
  onCloseModal: () => void;
  rescheduleConsult?: boolean;
  consultIdPicked: string;
}): JSX.Element => {
  const [modalPage, setModalPage] = useState(rescheduleConsult ? 2 : 0);
  const [actions, setActions] = useState([] as ModalActions[]);
  const [questions, setQuestions] = useState({} as QuestionnaireQuestions);
  const [consultId, setConsultId] = useState(
    rescheduleConsult ? consultIdPicked : null
  );
  const [intakeData, setIntakeData] = useState<IntakeData | null>(null);
  const [dateSelected, setDateSelected] = useState<DateSelected | null>(null);
  const [consultationSuccess, setConsultationSuccess] = useState<
    boolean | null
  >(null);
  const [consultationMessage, setConsultationMessage] = useState('');

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const kitIdData = client.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = kitIdData?.kitId ?? '';

  const cachedPatient = client.cache.readQuery<{ getPatient: Patient }>({
    query: GET_PATIENT,
  });
  const patientData = cachedPatient?.getPatient ?? ({} as Patient);

  /* We'll need to refactor this once multiple Questionnaires can be returned */
  const { loading: listQuestionnairesLoading, error: listQuestionnairesError } =
    useQuery(LIST_QUESTIONNAIRES, {
      onCompleted: ({ listQuestionnaires }) =>
        setQuestions(listQuestionnaires[0]),
      onError: () => null,
    });

  const [
    scheduleAppointment,
    { loading: scheduleAppointmentLoading, error: scheduleAppointmentError },
  ] = useMutation(SCHEDULE_APPOINTMENT, {
    onCompleted: () => {
      finalizeConsult({ variables: { id: consultId } });
    },
    onError: () => null,
  });

  const [
    finalizeConsult,
    { loading: finalizeConsultLoading, error: finalizeConsultError },
  ] = useMutation(FINALIZE_CONSULT, {
    refetchQueries: [{ query: GET_PATIENT_TEST_HISTORY }],
    onCompleted: ({ finalizeConsult: finalizeConsultData }) => {
      setConsultationSuccess(finalizeConsultData);
      setModalPage(modalPage + 1);
    },
    onError: () => null,
  });

  const [
    createConsult,
    { loading: createConsultLoading, error: createConsultError },
  ] = useMutation(CREATE_CONSULT, {
    onCompleted: ({ createConsult: createConsultData }) => {
      setConsultId(createConsultData.id);
      setModalPage(modalPage + 1);
    },
    onError: () => null,
  });

  const [rescheduleAppointment, { loading: rescheduleAppointmentLoading }] =
    useMutation(RESCHEDULE_APPOINTMENT, {
      refetchQueries: [{ query: GET_PATIENT_TEST_HISTORY }],
      onCompleted: () => {
        setConsultationSuccess(true);
        setModalPage(modalPage + 2);
      },
      onError: () => setConsultationSuccess(false),
    });

  const reschedulePatient = (rescheduleDate: DateSelected) =>
    rescheduleAppointment({
      variables: {
        wheelScheduleAppointment: {
          consultId,
          start: rescheduleDate?.data?.start,
          timezone,
        },
      },
    });

  const nextScheduleBtn = () => {
    const checkBtn =
      rescheduleConsult && dateSelected
        ? reschedulePatient(dateSelected)
        : setModalPage(modalPage + 1);
    return checkBtn;
  };

  const backScheduleBtn = () => {
    const backBtn = rescheduleConsult
      ? onCloseModal()
      : setModalPage(modalPage - 1);
    return backBtn;
  };

  useEffect(() => {
    if (dateSelected?.data) {
      actionComponent[2].actions.push({
        name: 'Next',
        onPress: nextScheduleBtn,
      });
    }
  }, [dateSelected]);

  const actionComponent: {
    name: string;
    actions: ModalActions[];
  }[] = [
    {
      name: 'StartTelehealth',
      actions: [
        {
          name: 'Next',
          onPress: () => setModalPage(modalPage + 1),
        },
      ],
    },
    {
      name: 'Questionnaire',
      actions: [],
    },
    {
      name: 'DateSelection',
      actions: [
        {
          name: 'Back',
          onPress: backScheduleBtn,
        },
      ],
    },
    {
      name: 'ConsultConfirmation',
      actions: [
        {
          name: 'Back',
          onPress: () => setModalPage(modalPage - 1),
        },
        {
          name: 'Book Appointment',
          onPress: () =>
            scheduleAppointment({
              variables: {
                wheelScheduleAppointment: {
                  consultId,
                  start: dateSelected?.data?.start,
                  timezone,
                },
              },
            }),
        },
      ],
    },
    {
      name: 'SuccessError',
      actions: [],
    },
  ];

  const SwitchComponent = () => {
    const Component = modalComponents[modalPage].component;
    const consultProps = {
      patientData,
      modalPage,
      setModalPage,
      consultationSuccess,
      dateSelected,
      setDateSelected,
      questions,
      consultId,
      setConsultId,
      timezone,
      kitId,
      setConsultationMessage,
      consultationMessage,
      createConsult,
      listQuestionnairesError,
      createConsultError,
      scheduleAppointmentError,
      finalizeConsultError,
      intakeData,
      setIntakeData,
      rescheduleConsult,
    };

    return <Component {...consultProps} />;
  };

  useEffect(() => {
    const getAction = actionComponent.find(
      (component) => component.name === modalComponents[modalPage].name
    );
    if (getAction) {
      setActions(getAction.actions);
    }
  }, [modalPage, dateSelected]);

  return (
    <ModalComponent
      title={
        modalPage < 3
          ? 'Book a telehealth appointment to review your results with a healthcare professional'
          : 'Consult Confirmation'
      }
      hideTitle={typeof consultationSuccess === 'boolean'}
      actions={actions}
      onHidden={onCloseModal}
      loading={
        scheduleAppointmentLoading ||
        finalizeConsultLoading ||
        createConsultLoading ||
        listQuestionnairesLoading ||
        rescheduleAppointmentLoading
      }
    >
      <SwitchComponent />
    </ModalComponent>
  );
};

export default TelehealthConsult;
