/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Platform, Linking } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useLazyQuery } from '@apollo/client';

import client, { SET_KIT_ID } from 'kit-activation';
import Footer from 'components/Footer';
import Text from 'components/StyledText';
import ScreenWrapper from 'components/ScreenWrapper';

import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';
import { Styles, TestHistory } from 'types';

import TestRow from './components/TestRow';
import ActivateKitLink from './components/ActivateKit';
import { GET_PATIENT_TEST_HISTORY } from '../../queries';
import TelehealthConsult from './components/TelehealthConsult';
import RescheduleBtn from './components/RescheduleBtn';
import { testTableHeaders } from './components/TestRow/utils';

const TestResultsTable = ({
  navigation,
  name,
}: {
  navigation: StackNavigationHelpers;
  name: string | null;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isWeb = Platform.OS === 'web';
  const [tests, setTests] = useState([] as TestHistory[]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [rescheduleConsult, setRescheduleConsult] = useState(false);
  const [consultId, setConsultId] = useState('0');
  const [testHistoryError, setTestHistoryError] = useState<string | null>(null);

  const [getPatientTestHistory, { loading, error }] = useLazyQuery(
    GET_PATIENT_TEST_HISTORY,
    {
      fetchPolicy:
        process.env.NODE_ENV === 'test' ? 'cache-first' : 'network-only',
      onCompleted: ({ getPatientTestHistory: getPatientTestHistoryData }) =>
        setTests(getPatientTestHistoryData),
      onError: () =>
        setTestHistoryError(
          'Something went wrong while trying to view your tests. Please contact support@imaware.health for further assistance.'
        ),
    }
  );

  const { isAuthenticated } = useCheckOrRefreshAuth();
  useEffect(() => {
    if (isAuthenticated) {
      getPatientTestHistory();
    }
  }, [isAuthenticated]);

  const onOpenModal = (kitId: string, consultEdit: boolean, id: string) => {
    onSetKitId(kitId);
    setConsultId(id);
    setRescheduleConsult(consultEdit);
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    onSetKitId('');
    setVisibleModal(false);
  };

  const onViewCollectionInstructions = (kitId: string) =>
    navigation.navigate('ConfirmTestingRequirementsScreen', {
      kitId,
    });

  const onSetKitId = (kitId: string) =>
    client.writeQuery({
      query: SET_KIT_ID,
      data: {
        kitId,
      },
    });

  const onViewResults = (kitId: string) => {
    onSetKitId(kitId);
    navigation.navigate('Report', {
      kitId,
    });
  };

  const onTrackSample = (test: TestHistory) => {
    const { trackingLab } = test;
    if (!trackingLab) return;
    const { trackingNumber, carrier = '' } = trackingLab;
    let trackingUrl = '';
    switch (carrier.toUpperCase()) {
      case 'USPS':
        trackingUrl = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
        break;
      case 'FEDEX':
        trackingUrl = `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}&cntry_code=us`;
        break;
      case 'UPS':
      default:
        trackingUrl = `https://www.ups.com/track?loc=null&tracknum=${trackingNumber}&requester=MB/trackdetails`;
        break;
    }
    if (isWeb) {
      window.open(`${trackingUrl}`, '_blank');
    } else {
      Linking.openURL(`${trackingUrl}`);
    }
  };

  const renderError = () => (
    <View style={styles.errorContainer}>
      <Text style={styles.errorText}>{testHistoryError}</Text>
    </View>
  );

  const mapTestResults = () =>
    tests.map((test) => (
      <React.Fragment key={test.kitId}>
        <TestRow
          key={test.kitId}
          test={test}
          onViewCollectionInstructions={onViewCollectionInstructions}
          onViewResults={onViewResults}
          onTrackSample={onTrackSample}
          onOpenModal={onOpenModal}
        />
        {test.appointment?.ableToReschedule ? (
          <RescheduleBtn
            onOpenModal={onOpenModal}
            appointment={test.appointment}
          />
        ) : null}
      </React.Fragment>
    ));

  const showEmptyState = () => (
    <Text lato large style={styles.emptyStateText}>
      It looks like you don't have any tests yet. Get started by activating your
      kit above!
    </Text>
  );

  const headerStyles = (header: string) => {
    const headerStyle =
      header === 'Activation Date' ? { textAlign: 'center' } : null;

    return headerStyle;
  };

  return (
    <View
      style={isMobile ? styles.contentContainerMobile : styles.contentContainer}
    >
      <View>
        <View style={isMobile ? styles.viewMobile : styles.view}>
          <Text fontWeight="500" small lora style={[styles.header]}>
            Hello, {name ? `${name}` : ''}. Welcome to your portal.
          </Text>
        </View>
        <View
          style={
            !isMobile ? styles.calculatePadding : styles.calculatePaddingMobile
          }
        >
          <ActivateKitLink navigation={navigation} />
          <View
            style={[
              isMobile ? styles.testContainerMobile : styles.testContainer,
              loading ? styles.loadingPadding : null,
            ]}
          >
            <ScreenWrapper
              excludeFooter
              excludeWrapper
              loading={loading}
              error={error}
            >
              <>
                {tests?.length ? (
                  <View style={{ flexDirection: 'row' }}>
                    {testTableHeaders.map((header) => (
                      <Text
                        fontWeight="700"
                        key={header}
                        small
                        style={
                          isMobile
                            ? styles.testLabelMobile
                            : ([
                                styles.testLabel,
                                headerStyles(header),
                              ] as Styles)
                        }
                      >
                        {header}
                      </Text>
                    ))}
                  </View>
                ) : null}

                {tests?.length ? mapTestResults() : showEmptyState()}
              </>
            </ScreenWrapper>
          </View>
          {testHistoryError ? renderError() : null}
          {visibleModal ? (
            <TelehealthConsult
              onCloseModal={onCloseModal}
              rescheduleConsult={rescheduleConsult}
              consultIdPicked={consultId}
            />
          ) : null}
        </View>
      </View>
      <Footer />
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    padding: 10,
    paddingTop: 80,
    backgroundColor: 'white',
  },
  viewMobile: {
    paddingHorizontal: 26,
    paddingBottom: 22,
    paddingTop: 20,
    backgroundColor: 'white',
  },
  header: {
    fontSize: 22,
    lineHeight: 45,
  },
  testLabelMobile: {
    color: '#2F2F2F',
    textTransform: 'uppercase',
    letterSpacing: 0.8,
    marginBottom: 15,
    fontSize: 16,
  },
  testLabel: {
    marginLeft: 25,
    color: '#59748A',
    textTransform: 'uppercase',
    letterSpacing: 0.8,
    marginBottom: 15,
    marginTop: 25,
    fontSize: 12,
    flex: 1,
  },
  calculatePadding: {
    paddingHorizontal: 25,
    paddingVertical: 25,
  },
  calculatePaddingMobile: { paddingHorizontal: 0 },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 240px)',
    overflow: 'scroll',
    backgroundColor: 'background: rgba(251, 248, 241, 0.82);',
    justifyContent: 'space-between',
  },
  contentContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'background: rgba(251, 248, 241, 0.82);',
    justifyContent: 'space-between',
    overflow: 'scroll',
  },
  testContainerMobile: {
    marginHorizontal: 28,
    backgroundColor: 'transparent',
  },
  testContainer: {
    backgroundColor: '#FFFFFF',
    borderColor: 'white',
    borderWidth: 2,
    borderStyle: 'solid',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 10,
    borderRadius: 16,
    paddingHorizontal: 26,
  },
  loadingPadding: {
    paddingVertical: 15,
  },
  errorContainer: { width: '100%', textAlign: 'center', paddingHorizontal: 20 },
  errorText: { fontWeight: '600' },
  emptyStateText: {
    marginVertical: 180,
    marginHorizontal: 'auto',
    color: '#686868',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default TestResultsTable;
