import {
  Page,
  Recommendation,
  PatientRecommendation,
  BiomarkerCode,
  Report,
  Scenario,
  Biomarker,
  BiomarkerLevels,
  Result,
  Sex,
  PatientTestType,
  BiomarkerColorCode,
  BiomarkerColorCodeRecommendation,
} from 'types';
import * as codes from 'constants/reportCodes';
import {
  PSA,
  TTG_IGG,
  DGP_IGG,
  DGP_IGA,
  HB_A1C,
  BLOOD_GLUCOSE,
  LIPOPROTEIN_A,
} from 'constants/biomarkers';
import { CELIAC_MONITORING, CELIAC_SCREENING } from 'constants/reportTypes';

const reportTypeMap = {
  'Female Fertility and Wellness Test': 'female_fertility_and_wellness',
  'Female Fertility Test': 'female_fertility',
  'Female Wellness Test': 'female_wellness',
  'Testosterone Monitoring Test': 'testosterone_monitoring',
  'Prostate Cancer Screening Test': 'prostate_screening',
  "Women's Health & Wellness Test": 'womens_health',
  "Men's Health & Wellness Test": 'mens_health',
  'Thyroid Screening Test': 'thyroid_screening',
  'Vitamin D Monitoring Test': 'vitamin_d_monitoring',
  'Prediabetes / Diabetes Screening Test': 'prediabetes_screening',
  'Baseline Heart Health Test': 'baseline_heart_health',
  'Immune Defense Test': 'immune_defense',
  'Celiac Disease Screening Test': 'celiac_screening',
  'Celiac Disease Monitoring Test': 'celiac_monitoring',
  'Rheumatoid Arthritis Test': 'rheumatoid_arthritis',
  'Lipoprotein(a) Test': 'lipoprotein_a',
  'IgE Allergy Test': '',
};

export const getReport = (
  content: { allReports: Report[] },
  reportType: PatientTestType
): Report =>
  content?.allReports?.find(
    (report) => report.developerConfig.key === reportTypeMap[reportType]
  ) ?? ({} as Report);

export const getPage = (pages: Page[], pageName: string): Page =>
  pages.find((page) => page.__typename === pageName) ?? ({} as Page);

export const getScenarioContent = (scenarios: Scenario[], reportCode: string) =>
  scenarios.find(({ code }) => code.developerConfig.key === reportCode) ??
  ({} as Scenario);

export const getBiomarkerLevel = (
  biomarkers: Biomarker[],
  results: Result[],
  sex: Sex
): BiomarkerLevels[] => {
  const biomarkerLevels: BiomarkerLevels[] = [];
  // eslint-disable-next-line consistent-return
  biomarkers.forEach((biomarker) => {
    const { name, ranges, elevatedRecs, lowRecs, developerConfig } = biomarker;
    const sexSpecificRanges = ranges[sex] ?? ranges;
    const { segments, boolean } = sexSpecificRanges;
    const result = results.find(
      ({ key }) => key === biomarker.developerConfig.key
    );
    if (!result) return null;
    const value = boolean
      ? result.qualitativeResult ?? ''
      : result.quantitativeResult ?? 0;
    let outcome = '';
    if (boolean) {
      outcome = value ? 'Positive' ?? '' : 'Negative' ?? '';
    } else {
      const targetRange = segments.find(
        (segment) => segment.code === codes.GREEN
      );
      const optimalRange = segments.find(
        (segment) => segment.code === codes.OPTGREEN
      );
      if (!targetRange) return null;
      if (!targetRange.upperBoundInclusive && value >= targetRange.range[1]) {
        if (
          optimalRange &&
          !optimalRange.upperBoundInclusive &&
          value < optimalRange.range[1]
        ) {
          outcome = 'Normal' ?? '';
        } else {
          outcome = 'Elevated' ?? '';
        }
      } else if (
        targetRange.upperBoundInclusive &&
        value > targetRange.range[1]
      ) {
        if (
          optimalRange &&
          optimalRange.upperBoundInclusive &&
          value <= optimalRange.range[1]
        ) {
          outcome = 'Normal' ?? '';
        } else {
          outcome = 'Elevated' ?? '';
        }
      } else if (
        !targetRange.lowerBoundInclusive &&
        value <= targetRange.range[0]
      ) {
        outcome = 'Low' ?? '';
      } else if (
        targetRange.lowerBoundInclusive &&
        value < targetRange.range[0]
      ) {
        outcome = 'Low' ?? '';
      } else outcome = 'Normal' ?? '';
      biomarkerLevels.push({
        name,
        level: outcome,
        elevatedRecs,
        lowRecs,
        developerConfig,
      });
    }
  });
  return biomarkerLevels;
};

export const getRecommendations = (
  recommendations: Recommendation[],
  biomarkerCodes: BiomarkerCode[],
  biomarkerLevels: BiomarkerLevels[],
  biomarkers: Biomarker[]
): PatientRecommendation[] => {
  const specialRecBiomarkers = [
    ...PSA,
    ...HB_A1C,
    ...BLOOD_GLUCOSE,
    ...LIPOPROTEIN_A,
  ];
  let patientRecommendations: PatientRecommendation[] = [];
  const defaultRec =
    recommendations.find(({ recommendationId }) => recommendationId === 999) ??
    ({} as Recommendation);
  biomarkerLevels.forEach((biomarkerLevel: BiomarkerLevels) => {
    const { developerConfig, level, lowRecs, elevatedRecs } = biomarkerLevel;
    const recType = level === 'Low' ? lowRecs : elevatedRecs;
    let recColor: BiomarkerColorCode;
    // run block if biomarker level is abnormal
    if (level !== 'Normal') {
      let recTypeRecommendations = recType.recommendations as number[];
      if (specialRecBiomarkers.includes(developerConfig.key)) {
        const index = specialRecBiomarkers.indexOf(developerConfig.key);
        recColor =
          biomarkerCodes.find((b) => b.key === specialRecBiomarkers[index])
            ?.code ?? ('' as BiomarkerColorCode);

        if (level !== 'Low') {
          const recs =
            recType.recommendations as BiomarkerColorCodeRecommendation;
          recTypeRecommendations = recs[recColor];
        }
      }
      recTypeRecommendations.forEach((recId: number) => {
        const recommendation = recommendations.find(
          ({ recommendationId }) => recommendationId === recId
        );
        const biomarker = biomarkerCodes.find(
          ({ key }) => key === developerConfig.key
        );
        // check if recommendation already exists - prevent duplicates
        const index = patientRecommendations.findIndex(
          ({ rec }) => rec.recommendationId === recommendation?.recommendationId
        );
        // if recommendation exists, just add biomarker to the recommendation
        if (index > -1) {
          patientRecommendations[index].biomarkers.push(
            biomarker || { key: 'default' }
          );
          // otherwise push the new recommendation
        } else {
          patientRecommendations.push({
            rec: recommendation || defaultRec,
            biomarkers: [biomarker || { key: 'default' }],
          });
        }
      });
    }
  });
  if (patientRecommendations.length > 0) {
    // For celiac reports
    const recBiomarkers = patientRecommendations[0].biomarkers;
    const celiacScreening =
      biomarkers.find(
        (biomarker) => biomarker.developerConfig.key === recBiomarkers[0].key
      )?.note === CELIAC_SCREENING;
    const celiacMonitoring =
      biomarkers.find(
        (biomarker) => biomarker.developerConfig.key === recBiomarkers[0].key
      )?.note === CELIAC_MONITORING;
    const checkBiomarkers = (celiacBiomarkers: string[]) =>
      recBiomarkers.every(({ key }) => celiacBiomarkers.includes(key));
    if (recBiomarkers.length === 2 && celiacScreening) {
      if (checkBiomarkers([...TTG_IGG, ...DGP_IGG])) {
        patientRecommendations = [];
      }
      if (checkBiomarkers([...TTG_IGG, DGP_IGA])) {
        patientRecommendations = [];
      }
    }
    if (celiacMonitoring) {
      if (checkBiomarkers([...TTG_IGG, DGP_IGA])) {
        patientRecommendations = [];
      }
    }
  }

  // if all biomarker levels are normal - provide default recommendation
  if (patientRecommendations.length < 1) {
    patientRecommendations.push({
      rec: defaultRec,
      biomarkers: [{ key: 'default' }],
    });
  }
  return patientRecommendations;
};
