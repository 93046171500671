import React, { useRef, useState, useEffect } from 'react';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View, Image, Pressable } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useMediaQuery } from 'react-responsive';

import ScreenWrapper from 'components/ScreenWrapper';
import Button from 'components/StyledButton';
import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import HeaderBar from 'components/HeaderBar';

import GlobalStyles from 'constants/Styles';
import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';

const EnterKitIdScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers;
  route?: RouteProp<{ params: { scanError: boolean } }, 'params'>;
}): JSX.Element => {
  const [kitIdFromUrl, setKitIdFromUrl] = useState('');

  useEffect(() => {
    const kitIdFromStorage = localStorage.getItem('kitid') ?? '';

    setKitIdFromUrl(kitIdFromStorage);
  }, []);

  const { control, handleSubmit, watch, reset } = useForm<{
    kitId: string;
  }>({
    mode: 'onBlur',
    defaultValues: {
      kitId: kitIdFromUrl,
    },
  });
  const { isAuthenticated } = useCheckOrRefreshAuth();

  useEffect(() => {
    if (isAuthenticated === false) {
      navigation.navigate('Registration');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    reset({ kitId: kitIdFromUrl });
  }, [kitIdFromUrl]);

  const kitId = useRef({});
  kitId.current = watch('kitId', kitIdFromUrl);

  const onQRScanPress = () => {
    navigation.navigate('ScanQRCodeScreen');
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const tabletScreen = useMediaQuery({
    maxWidth: 1200,
  });

  const largeScreen = useMediaQuery({
    maxWidth: 1300,
  });

  // eslint-disable-next-line no-unused-vars
  const onCalculateChange = (text: string, onChange: (event: string) => void) =>
    onChange(text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/g, ''));

  const onCalculateBoxStyles = () => {
    if (largeScreen) {
      return [styles.box, { flexDirection: 'column' }];
    }
    if (!isMobile) {
      return styles.box;
    }
    return {};
  };

  const onCalculateInputContainerStyles = () => {
    if (!isMobile) {
      return [
        styles.subBoxContent,
        styles.subBoxDesktop,
        tabletScreen ? { width: '700px' } : null,
      ];
    }

    return [styles.subBoxContent, styles.subBoxMobile];
  };

  const onSubmit = () =>
    navigation.navigate('ConfirmTestingRequirementsScreen', {
      kitId: kitId.current,
    });

  return (
    <>
      <ScreenWrapper>
        <View>
          {!isMobile ? <HeaderBar navigation={navigation} /> : null}
          <View style={[GlobalStyles.container, !isMobile && styles.container]}>
            <View style={!isMobile && styles.desktopHeader}>
              <Text xl lora fontWeight={'700'}>
                Kit Activation
              </Text>
            </View>
            <View style={onCalculateBoxStyles()}>
              <View
                style={
                  !isMobile ? styles.subBoxImage : styles.subBoxImageMobile
                }
              >
                <Image
                  source={require('./img/kit-activation-new.png')}
                  style={isMobile ? styles.image : styles.desktopImage}
                />
              </View>

              <View style={onCalculateInputContainerStyles()}>
                <Text style={styles.header} large lato fontWeight={'700'}>
                  Enter your kit code
                </Text>
                <Text lato small style={styles.text}>
                  Your unique kit code can be found on the instruction card that
                  came with your kit
                </Text>
                <Controller
                  control={control}
                  name="kitId"
                  rules={{
                    required: {
                      value: true,
                      message: `The kit code is invalid. Please re-enter your kit code or email support@imaware.health for assistance.`,
                    },
                    minLength: {
                      value: 9,
                      message: `The kit code is invalid. Please re-enter your kit code or email support@imaware.health for assistance.`,
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextInput
                        onChangeText={(text) =>
                          onCalculateChange(text, onChange)
                        }
                        onBlur={onBlur}
                        value={value}
                        autoCapitalize="characters"
                        maxLength={10}
                        placeholder="Enter kit code"
                        placeholderTextColor="grey"
                        testID="kitId"
                        accessible={true}
                        accessibilityLabel="kitId"
                        style={[
                          GlobalStyles.input,
                          styles.input,
                          error && styles.inputError,
                        ]}
                      />
                      {error && (
                        <Text style={GlobalStyles.error} label>
                          {error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
                {isMobile && (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <View
                      style={{
                        flex: 0.8,
                        height: 1,
                        backgroundColor: 'rgb(217,217,217)',
                        marginTop: 5,
                      }}
                    />
                    <Text
                      lato
                      fontWeight={'700'}
                      style={{
                        alignSelf: 'center',
                        marginVertical: 5,
                        marginHorizontal: 10,
                      }}
                    >
                      or
                    </Text>
                    <View
                      style={{
                        flex: 0.8,
                        height: 1,
                        backgroundColor: 'rgb(217,217,217)',
                        marginTop: 5,
                      }}
                    />
                  </View>
                )}
                {isMobile && (
                  <Pressable onPress={onQRScanPress} style={styles.qrContainer}>
                    <FontAwesome
                      name="qrcode"
                      size={24}
                      color="black"
                      style={styles.icon}
                    />
                    <Text fontWeight={'700'} lato label>
                      Scan QR code
                    </Text>
                  </Pressable>
                )}
                <View style={styles.disclaimerContainer}>
                  <FontAwesome
                    name="exclamation-triangle"
                    size={22}
                    color="#F1C40F"
                    style={styles.icon}
                  />
                  <Text lato fontWeight={'700'} label style={{ width: '90%' }}>
                    If you're taking multiple tests, you must activate each kit
                    code independently.
                  </Text>
                </View>
                {route?.params?.scanError ? (
                  <View style={styles.errorWrapper}>
                    <Image
                      source={require('assets/images/error-icon.png')}
                      style={styles.errorIcon}
                    />
                    <Text style={GlobalStyles.error} lato>
                      Something went wrong while trying to activate your kit
                      code. Please contact{' '}
                      <a
                        href="mailto:support@imaware.health"
                        style={{ color: '#DC412C' }}
                      >
                        support@imaware.health
                      </a>{' '}
                      for further assistance
                    </Text>
                  </View>
                ) : null}
                {!isMobile ? (
                  <Button
                    primary
                    arrowRight
                    onPress={handleSubmit(onSubmit)}
                    style={styles.desktopButton}
                    textStyle={styles.buttonDesktopText}
                  >
                    Continue
                  </Button>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </ScreenWrapper>
      {isMobile ? (
        <View style={styles.buttonView}>
          <Button
            primary
            onPress={handleSubmit(onSubmit)}
            style={styles.button}
            textStyle={styles.buttonText}
          >
            Begin
          </Button>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: 10,
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 200,
  },
  subBoxImage: {
    flex: 2,
  },
  subBoxImageMobile: {
    flex: 0.7,
    width: '100%',
  },
  desktopHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: 40,
  },
  subBoxContent: {
    flex: 1.2,
    width: 'auto',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginBottom: 30,
    borderRadius: 24,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  subBoxMobile: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  subBoxDesktop: {
    paddingTop: 50,
    marginHorizontal: 15,
    paddingLeft: 50,
    paddingRight: 50,
  },
  box: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  desktopImage: {
    width: 550,
    height: 550,
    resizeMode: 'contain',
    aspectRatio: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    aspectRatio: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  buttonView: {
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    borderRadius: 0,
    justifyContent: 'center',
    height: 90,
  },
  desktopButton: {
    borderRadius: 25,
    marginVertical: 25,
    justifyContent: 'center',
    height: 40,
    width: 200,
  },
  buttonText: {
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 2,
    color: 'white',
  },
  buttonDesktopText: {
    fontWeight: '500',
    fontSize: 14,
    fontFamily: 'lato_400',
  },
  text: {
    lineHeight: 22,
    fontSize: 16,
    marginBottom: 10,
    letterSpacing: 0.84,
  },
  input: {
    borderWidth: 0,
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    width: '100%',
    height: 50,
    marginHorizontal: 0,
    paddingLeft: 20,
    letterSpacing: 0.64,
    color: '#707070',
  },
  inputError: {
    borderWidth: 1,
    borderColor: '#DC412C',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  desktopCheckboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#D9D9D9',
    padding: 10,
  },
  disclaimerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F7F9FB',
    borderRadius: 10,
    padding: 15,
    marginTop: 15,
  },
  checkboxText: {
    marginLeft: 15,
  },
  link: {
    textDecorationLine: 'underline',
  },
  qrContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 4,
    borderWidth: 1,
    height: 50,
    marginVertical: 15,
  },
  icon: {
    marginRight: 15,
  },
  descriptionTestTaker: {
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 12,
    marginVertical: 10,
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23,
  },
  errorIcon: { height: 21, width: 21, marginRight: 10 },
});

export default EnterKitIdScreen;
