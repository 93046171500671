import { Picker } from '@react-native-picker/picker';
import TextInput from 'components/StyledTextInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { View, Text, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import {
  stateCodeMap,
  states,
} from 'screens/registration/CompletePatientProfileScreen/utils';

const AddressInformation = (): JSX.Element => {
  const { control } = useFormContext();

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const selectStateByPrefix = (state: string) => {
    const stateSelected = Object.entries(stateCodeMap).find(
      (s) => s[1] === state
    );
    return stateSelected ? stateSelected[0] : '';
  };

  const selectStateByName = (state: string, onChange: any) => {
    const stateSelected = Object.entries(stateCodeMap).find(
      (s) => s[0] === state
    );
    if (stateSelected && stateSelected[1]) {
      onChange(stateSelected[1]);
      return stateSelected[1];
    }

    return '';
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title} testID="sd">
        Address Information
      </Text>
      <View>
        <View style={isMobile ? styles.mobileBox : styles.box}>
          <View style={styles.subBox}>
            <Text style={styles.text}>ADDRESS LINE 1</Text>
            <Controller
              control={control}
              name="address.line[0]"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChangeText={onChange}
                  value={value ?? ''}
                  placeholder="Address line 1"
                  placeholderTextColor="grey"
                  testID="addressLine"
                  accessibilityLabel="addressLine"
                  style={styles.input}
                />
              )}
            />
          </View>
          <View style={styles.subBox}>
            <Text style={styles.text}>ADDRESS LINE 2</Text>
            <Controller
              control={control}
              name="address.line[1]"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChangeText={onChange}
                  value={value ?? ''}
                  placeholder="Address line 2"
                  placeholderTextColor="grey"
                  testID="addressLine2"
                  accessibilityLabel="addressLine2"
                  style={styles.input}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.mobileBox : styles.box}>
          <View style={styles.subBox}>
            <Text style={styles.text}>CITY</Text>
            <Controller
              control={control}
              name="address.city"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChangeText={onChange}
                  value={value ?? ''}
                  placeholder="City"
                  placeholderTextColor="grey"
                  testID="city"
                  accessibilityLabel="city"
                  style={styles.input}
                />
              )}
            />
          </View>
          <View style={styles.subBox}>
            <Text style={styles.text}>STATE</Text>
            <Controller
              control={control}
              name="address.state"
              render={({ field: { onChange, value } }) => (
                <Picker
                  selectedValue={selectStateByPrefix(value)}
                  onValueChange={(val) => selectStateByName(val, onChange)}
                  style={styles.input}
                  testID="statePicker"
                >
                  {states?.map((option) => (
                    <Picker.Item
                      key={option.label}
                      label={option.label}
                      value={option.value}
                      testID={option.label}
                    />
                  ))}
                </Picker>
              )}
            />
          </View>
          <View style={styles.subBox}>
            <Text style={styles.text}>ZIP CODE</Text>
            <Controller
              control={control}
              name={'address.postalCode'}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  onChangeText={onChange}
                  value={value ?? ''}
                  placeholder="Zip code"
                  placeholderTextColor="grey"
                  testID="zipCode"
                  accessibilityLabel="zipCode"
                  style={styles.input}
                />
              )}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default AddressInformation;

const styles = StyleSheet.create({
  title: {
    fontWeight: '600',
    fontFamily: 'lora_500',
    fontSize: 18,
  },
  text: {
    flex: 1,
    marginVertical: 10,
    fontSize: 12,
    fontWeight: '600',
  },
  container: {
    marginVertical: 20,
    width: '100%',
  },
  box: { display: 'flex', flexDirection: 'row' },
  mobileBox: { display: 'flex', flexDirection: 'column' },
  subBox: { marginHorizontal: 5, marginVertical: 10, flex: 1 },
  input: {
    backgroundColor: '#80808029',
    padding: 10,
    borderRadius: 5,
  },
});
