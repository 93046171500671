import { gql } from '@apollo/client';

const GET_TEST_RESULTS = gql`
  mutation GetTestResults($kitId: String!) {
    getTestResults(kitId: $kitId) {
      patient {
        name {
          family
          given
        }
        gender
        birthDate
        id
      }
      observations {
        code {
          coding {
            code
            display
            system
          }
        }
        valueQuantity {
          unit
          value
        }
      }
      testType
      testedDate
    }
  }
`;

export default GET_TEST_RESULTS;
