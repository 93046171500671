import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';

import Button from 'components/StyledButton';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import { LayoutProps, PartnerConfig } from 'types';

const HeaderBar = ({
  navigation,
  buttonStyles,
}: {
  navigation: StackNavigationHelpers;
  buttonStyles?: LayoutProps;
}) => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  return (
    <View style={styles.headerBar}>
      <Image
        style={
          partnerConfig
            ? [styles.imawareLogo, partnerConfig.theme.logoDimensions]
            : styles.imawareLogo
        }
        source={
          partnerConfig
            ? partnerConfig.theme.logo
            : require('assets/images/logo.png')
        }
      />
      <Button
        style={styles.dashboardButton}
        textStyle={styles.headerButtonText}
        ignoreWidth
        {...buttonStyles}
        onPress={() => navigation.navigate('PatientDashboardScreen')}
      >
        Back to Dashboard
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  imawareLogo: {
    width: 120,
    aspectRatio: 5,
    height: 21,
    resizeMode: 'contain',
    marginLeft: 42,
    marginTop: 38,
    marginBottom: 40,
  },
  headerButtonText: { fontSize: 14, fontFamily: 'lato_400' },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dashboardButton: {
    width: 192,
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 36,
    marginTop: 28,
    marginRight: 50,
  },
});

export default HeaderBar;
