import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { Ionicons } from '@expo/vector-icons';

const SuccessError = ({
  consultationSuccess,
  consultationMessage,
  rescheduleConsult,
}: {
  consultationSuccess: boolean | null;
  consultationMessage: string | null;
  rescheduleConsult: boolean | null;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const renderTitle = () => {
    if (rescheduleConsult) {
      return <Text style={styles.subtitle}>Appointment rescheduled!</Text>;
    }
    return (
      <Text style={styles.subtitle}>
        {consultationSuccess ? 'Success!' : null}
      </Text>
    );
  };

  return (
    <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
      <View style={styles.box}>
        <View
          style={{
            marginVertical: 15,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {consultationSuccess ? (
            <Ionicons name="checkmark-circle-outline" size={55} color="green" />
          ) : (
            <Ionicons name="close-circle-outline" size={55} color="red" />
          )}
          {renderTitle()}
        </View>
        <Text style={styles.subtitle}>{consultationMessage}</Text>
        {consultationSuccess ? (
          <Text style={styles.description}>
            You will receive an email shortly confirming this and outlining next
            steps.
          </Text>
        ) : (
          <Text style={styles.subtitle}>
            Something went wrong and your appointment was not scheduled. Please
            try Again
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  desktopContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginVertical: 10,
  },
  box: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    fontWeight: '700',
    fontSize: 26,
    fontFamily: 'lora',
    textAlign: 'center',
  },
  listItem: {
    marginBottom: 5,
    fontSize: 16,
  },
  textBox: {
    marginVertical: 10,
    maxWidth: 'auto',
  },
  rescheduling: {
    lineHeight: 24,
  },
  description: {
    fontSize: 20,
    textAlign: 'center',
  },
});

export default SuccessError;
