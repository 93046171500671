import styled from 'styled-components/native';

const StyledView = styled.View`
  flex: 1;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 15;
  margin-bottom: 15;
`;

export default StyledView;
