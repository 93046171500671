/**
 * Config generator for application. Based on the release channel app was built through EAS
 * if no release channel it is a local build and uses the local config.
 */
import Constants from 'expo-constants';

// release profiles referenced in eas.json
const RC_DEV = 'development';
const RC_STAGE = 'staging';
const RC_PROD = 'production';
const LOCAL = 'local';
let RELEASE_TAG = 'local';

const ENV = {
  local: {
    AMPLITUDE_APIKEY: '3edfcaccd298d8f010fb43d47105820d',
    AUTH0_AUDIENCE: 'https://imaware.partner.api',
    AUTH0_CLIENT_ID: '5gR12gD0rtrYlqShLV9QZQxj7Y6r2Jnk',
    AUTH0_CONNECTION: 'client-portal',
    AUTH0_DOMAIN: 'https://login.alpha.imaware.health',
    ENVIRONMENT: 'local',
    KLAVIYO_PUBLIC_APIKEY: 'SX53fg',
    LOCAL_GRAPHQL_ENDPOINT: 'http://localhost:4000/graphql',
    PROXY_GRAPHQL_ENDPOINT: 'http://192.168.1.26:4000/graphql',
    PROXY: 'true',
    RELEASE_TAG,
    SENTRY_DEBUG: 'true',
  },
  dev: {
    AMPLITUDE_APIKEY: '3edfcaccd298d8f010fb43d47105820d',
    AUTH0_AUDIENCE: 'https://imaware.partner.api',
    AUTH0_CLIENT_ID: window?.config?.AUTH0_CLIENT_ID ?? '5gR12gD0rtrYlqShLV9QZQxj7Y6r2Jnk',
    AUTH0_CONNECTION:  window?.config?.AUTH0_CONNECTION ?? 'client-portal',
    AUTH0_DOMAIN: 'https://login.alpha.imaware.health',
    ENVIRONMENT: 'develop',
    KLAVIYO_PUBLIC_APIKEY: 'SX53fg',
    LOCAL_GRAPHQL_ENDPOINT:  window?.config?.LOCAL_GRAPHQL_ENDPOINT ?? 'https://api.portal.patient.develop.imaware.dev/graphql',
    PROXY_GRAPHQL_ENDPOINT: 'https://portal.develop.imaware.health/graphql',
    PROXY: 'false',
    RELEASE_TAG,
    SENTRY_DEBUG: 'true',
  },
  staging: {
    AMPLITUDE_APIKEY: '3edfcaccd298d8f010fb43d47105820d',
    AUTH0_AUDIENCE: 'https://imaware.partner.api',
    AUTH0_CLIENT_ID: 'wbQ9eAA107eyQhAWjUoSqQNXDuNqYiop',
    AUTH0_CONNECTION: 'imaware-stage',
    AUTH0_DOMAIN: 'https://login.alpha.imaware.health',
    ENVIRONMENT: 'staging',
    KLAVIYO_PUBLIC_APIKEY: 'SX53fg',
    LOCAL_GRAPHQL_ENDPOINT: 'https://api.portal.patient.staging.imaware.dev/graphql',
    PROXY_GRAPHQL_ENDPOINT: 'https://portal.staging.imaware.health/graphql',
    PROXY: 'false',
    RELEASE_TAG,
    SENTRY_DEBUG: 'true',
  },
  prod: {
    AMPLITUDE_APIKEY: '8b0031076bf37ba6d1834185dcbce6a2',
    AUTH0_AUDIENCE: 'https://imaware.partner.api',
    AUTH0_CLIENT_ID: 'Gd1mevHMHGO5fmnfIzq8rDbBiQhsOgSI',
    AUTH0_CONNECTION: 'Imaware-prod',
    AUTH0_DOMAIN: 'https://login.imaware.health',
    ENVIRONMENT: 'prod',
    KLAVIYO_PUBLIC_APIKEY: 'SX53fg',
    LOCAL_GRAPHQL_ENDPOINT: 'https://api.portal.patient.prod.imaware.dev/graphql',
    PROXY_GRAPHQL_ENDPOINT: 'https://portal.prod.imaware.health/graphql',
    PROXY: 'false',
    RELEASE_TAG,
    SENTRY_DEBUG: 'false',
  },
};

/**
 * Return a dict of configuration variables based on the release channel being used.
 * @param {*} env environmnet config to grab
 * @returns dict of env configuration
 */

const getEnvVars = (mobileManifest = Constants.manifest2) => {
  const env = mobileManifest?.extra?.expoClient?.releaseChannel ?? LOCAL;
  const webEnv = Constants.manifest?.extra?.web ?? LOCAL;
  RELEASE_TAG = Constants.manifest?.extra?.release ?? 'local';
  if (env === RC_DEV || webEnv === RC_DEV) {
    return ENV.dev;
  } else if (env === RC_STAGE || webEnv === RC_STAGE) {
    return ENV.staging;
  } else if (env === RC_PROD || webEnv === RC_PROD) {
    return ENV.prod;
  } else if (
    env === LOCAL ||
    webEnv === LOCAL ||
    process.env.CI_TEST === LOCAL
  ) {
    return ENV.local;
  } else {
    throw new Error('No environmnet set for Config.ts!');
  }
};

const env = getEnvVars();

export default env;
