import * as types from 'constants/reportTypes';
import * as allBiomarkers from 'constants/biomarkers';
import * as codes from 'constants/reportCodes';
import {
  Answer,
  Biomarker,
  ColorCode,
  Question,
  BiomarkerCode,
  Result,
  Sex,
} from 'types';


export const calculateReportCode = (
  results: Result[],
  biomarkers: Biomarker[],
  sex: Sex
): ColorCode => {
  const reportCodes: ColorCode[] = [
    codes.OPTGREEN,
    codes.GREEN,
    codes.YELLOW,
    codes.ORANGE,
    codes.RED,
  ];
  let green2Count = 0;
  const reportIndex = (segmentCode: ColorCode) => reportCodes.indexOf(segmentCode);
  let codeIndex = 0; // start at lowest code
  biomarkers.forEach((biomarker) => {
    const result = results.find(
      ({ key }) => key === biomarker.developerConfig.key
    );
    if (result) {
      const { key } = biomarker.developerConfig;
      const { ranges, note } = biomarker;
      const sexSpecificRanges = ranges[sex] || ranges;
      const { reportable_range, segments, boolean, target_range } =
        sexSpecificRanges;
      let value = boolean
        ? result.qualitativeResult ?? ''
        : result.quantitativeResult ?? 0;
      if (value > reportable_range[1]) value = reportable_range[1];
      if (value < reportable_range[0] && !allBiomarkers.LDL_HDL_RATIO.includes(key))
        value = reportable_range[0];

      if (boolean) {
        if (value) {
          if (codeIndex < 3) codeIndex = 3; // positive value will be RED
        }
      } else {
        const segment = segments.find((segment) => {
          let lowerBoundCondition; let upperBoundCondition;
          if (segment.lowerBoundInclusive) {
            lowerBoundCondition = value >= segment.range[0];
          } else {
            lowerBoundCondition = value > segment.range[0];
          }
          if (segment.upperBoundInclusive) {
            upperBoundCondition = value <= segment.range[1];
          } else {
            upperBoundCondition = value < segment.range[1];
          }
          return lowerBoundCondition && upperBoundCondition;
        });
        if (segment) {
          let segmentCodeIndex = reportCodes.indexOf(segment.code);
          if (
            note !== types.CELIAC_SCREENING &&
            note !== types.CELIAC_MONITORING
          ) {
            if (codeIndex < segmentCodeIndex) codeIndex = segmentCodeIndex;
          }
          const elevated = value >= target_range[1];
          if (note === types.CELIAC_SCREENING) {
            const elevatedThreeTimes =
              value >= (3 * target_range[1]).toFixed(1);
            if (allBiomarkers.TTG_IGA.includes(key)) {
              if (elevatedThreeTimes) codeIndex = reportIndex(codes.RED);
              else if (elevated && !elevatedThreeTimes) {
                codeIndex = reportIndex(codes.ORANGE);
              } else codeIndex = reportIndex(codes.OPTGREEN);
            } else if (
              !allBiomarkers.TTG_IGA.includes(key) &&
              segment.code !== codes.GREEN
            ) {
              segmentCodeIndex = reportIndex(codes.YELLOW);
              if (codeIndex < segmentCodeIndex) {
                codeIndex = segmentCodeIndex;
              }
              if (allBiomarkers.DGP_IGG.includes(key)) green2Count += 1;
              else if (allBiomarkers.TTG_IGG.includes(key)) green2Count += 2;
              else green2Count += 4;
            } else return;
            if (green2Count === 3) {
              segmentCodeIndex = reportIndex(codes.GREEN);
              if (
                codeIndex === reportIndex(codes.YELLOW) ||
                codeIndex < segmentCodeIndex
              ) {
                codeIndex = segmentCodeIndex;
              }
            }
            if (green2Count === 6) {
              segmentCodeIndex = reportIndex(codes.OPTGREEN);
              if (
                codeIndex === reportIndex(codes.YELLOW) ||
                codeIndex < segmentCodeIndex
              ) {
                codeIndex = segmentCodeIndex;
              }
            }
          }
          if (note === types.CELIAC_MONITORING) {
            const elevatedTenTimes = value >= (10 * target_range[1]).toFixed(3);
            if (
              allBiomarkers.DGP_IGG.includes(key) ||
              allBiomarkers.TTG_IGA.includes(key)
            ) {
              if (elevatedTenTimes) {
                codeIndex = reportCodes.indexOf(codes.RED);
              } else if (elevated && !elevatedTenTimes) {
                segmentCodeIndex = reportCodes.indexOf(codes.YELLOW);
              } else segmentCodeIndex = reportCodes.indexOf(codes.OPTGREEN);
              if (codeIndex < segmentCodeIndex) codeIndex = segmentCodeIndex;
            }
          }
        }
      }
    }
  });
  return reportCodes[codeIndex];
};

export const calculateBiomarkerCode = (
  results: Result[],
  biomarkers: Biomarker[],
  sex: Sex
): BiomarkerCode[] => {
  const biomarkerStatuses: BiomarkerCode[] = [];

  let codeIndex = 0; // start at lowest code
  biomarkers.forEach((biomarker) => {
    const result = results.find(
      ({ key }) => key === biomarker.developerConfig.key
    );
    if (result) {
      const { ranges } = biomarker;
      const sexSpecificRanges = ranges[sex] || ranges;
      const { segments, boolean, reportable_range } = sexSpecificRanges;
      let value = boolean
        ? result.qualitativeResult ?? '0'
        : result.quantitativeResult ?? 0;
      
      if (value > reportable_range[1]) value = reportable_range[1];
      if (value < reportable_range[0]) value = reportable_range[0];

      if (boolean) {
        if (value) {
          if (codeIndex < 3) codeIndex = 3; // positive value will be RED
        }
      } else {
        const segment = segments.find((segment) => {
          let lowerBoundCondition; let upperBoundCondition;
          if (segment.lowerBoundInclusive) {
            lowerBoundCondition = value >= segment.range[0];
          } else {
            lowerBoundCondition = value > segment.range[0];
          }
          if (segment.upperBoundInclusive) {
            upperBoundCondition = value <= segment.range[1];
          } else {
            upperBoundCondition = value < segment.range[1];
          }
          return lowerBoundCondition && upperBoundCondition;
        });
        if (allBiomarkers.PSA.includes(result.key) && value > reportable_range[1]) {
          biomarkerStatuses.push({
            key: result.key,
            ...segments[segments.length - 1],
          });
        } else if (
          allBiomarkers.PSA.includes(result.key) &&
          value < reportable_range[0]
        ) {
          biomarkerStatuses.push({
            key: result.key,
            ...segments[0],
          });
        } else biomarkerStatuses.push({ key: result.key, ...segment });
      }
    }
  });
  return biomarkerStatuses;
};

export const calculateReportBackgroundCode = (
  answers: Answer[],
  questions: Question[]
): ColorCode => {
  const reportCodes: ColorCode[] = [
    codes.GREEN,
    codes.YELLOW,
    codes.ORANGE,
    codes.RED,
  ];
  let codeIndex = 0; // start at lowest code
  questions.forEach((question) => {
    const answer = answers.find(
      ({ key }) => key === question.developerConfig.key
    );
    if (answer) {
      if (answer.value) {
        // yes
        if (codeIndex < 1) codeIndex = 1; // positive answer will be YELLOW
      }
    }
  });
  return reportCodes[codeIndex];
};
