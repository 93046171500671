import * as React from 'react';
import {
  createDrawerNavigator,
  DrawerItem,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationState } from '@react-navigation/routers';
import {
  View,
  useWindowDimensions,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { useQuery } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import { Localization } from 'hooks/useLocalization';
import OverviewScreen from 'screens/report/OverviewScreen';
import ResultsScreen from 'screens/report/ResultsScreen';
import DetailsScreen from 'screens/report/DetailsScreen';
import RecommendationsScreen from 'screens/report/RecommendationsScreen';
import ResourcesScreen from 'screens/report/ResourcesScreen';
import { PortalHeader } from 'components/ReportCommon';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import { Layout } from 'components/StyledView';
import client, {
  ReportLocalization,
  REPORT_LOCALIZATION,
} from 'sample-collection-instructions';

import { Wand, CheckAll, Settings } from '../components/Icons';
import { Localization as LocalizationType } from '../types';

const Drawer = createDrawerNavigator();

const Navigator = () => {
  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions.width >= 780;
  const result = useQuery(REPORT_LOCALIZATION('en'), { client });
  const { loading, error, data } = result;
  if (loading) {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text large>{'Loading...'}</Text>
        </View>
      </View>
    );
  }
  if (error) {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text large>Error! ${error.message}</Text>
        </View>
      </View>
    );
  }
  const { reportLocalization: localization } = data;
  return (
    <ReportLocalization.Provider value={localization}>
      <Drawer.Navigator
        drawerContent={(props) => (
          <DrawerNavigation {...props} localization={localization} />
        )}
        // drawerType={isMediumScreen ? "permanent" : "front"} //uncomment when ready to show
        overlayColor={isMediumScreen ? 'transparent' : 'rgba(0, 0, 0, 0.5)'}
        drawerStyle={isMediumScreen ? { width: 240 } : { width: 400 }}
        initialRouteName={localization.myTests}
        drawerContentOptions={{
          activeBackgroundColor: 'none',
          activeTintColor: 'black',
          itemStyle: {
            marginVertical: 0,
          },
        }}
      >
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <Wand color={focused ? 'black' : '#C3D2DF'} size={24} />
            ),
          }}
          name={localization.activateTest}
          component={ReportNavigatorStack}
        />
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <CheckAll color={focused ? 'black' : '#C3D2DF'} size={24} />
            ),
          }}
          name={localization.myTests}
          component={ReportNavigatorStack}
        />
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <Settings color={focused ? 'black' : '#C3D2DF'} size={24} />
            ),
          }}
          name={localization.settings}
          component={ReportNavigatorStack}
        />
      </Drawer.Navigator>
    </ReportLocalization.Provider>
  );
};

export default function ReportNavigator(): JSX.Element {
  return <Localization.Consumer>{() => <Navigator />}</Localization.Consumer>;
}

const ReportStack = createStackNavigator();

export function ReportNavigatorStack({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element {
  const nav = navigation as unknown as DrawerNavigationHelpers;
  return (
    <ReportStack.Navigator
      headerMode="none" // change to 'screen' when ready to show
      screenOptions={{
        headerTitle: () => <PortalHeader />,
        headerLeft: () => <DrawerToggler navigation={nav} />,
        headerStyle: styles.portalHeader,
      }}
    >
      <ReportStack.Screen name="OverviewScreen" component={OverviewScreen} />
      <ReportStack.Screen name="ResultsScreen" component={ResultsScreen} />
      <ReportStack.Screen name="DetailsScreen" component={DetailsScreen} />
      <ReportStack.Screen
        name="RecommendationsScreen"
        component={RecommendationsScreen}
      />
      <ReportStack.Screen name="ResourcesScreen" component={ResourcesScreen} />
    </ReportStack.Navigator>
  );
}

const DrawerToggler = ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}) => {
  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions.width >= 780;
  return (
    <Layout paddingLeft="regular">
      {!isMediumScreen ? (
        <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
          <Ionicons name="menu" size={30} color="black" />
        </TouchableOpacity>
      ) : null}
    </Layout>
  );
};

const DrawerNavigation = (props: {
  activeBackgroundColor?: string;
  activeTintColor?: string;
  localization: LocalizationType;
  itemStyle?: StyleProp<ViewStyle>;
  state: NavigationState;
  navigation: DrawerNavigationHelpers;
}) => {
  const {
    activeBackgroundColor,
    activeTintColor,
    localization,
    itemStyle,
    state,
  } = props;
  return (
    <DrawerContentScrollView {...props}>
      <Layout style={styles.navDrawer}>
        <Layout style={styles.navHeaderContainer}>
          <Text xxl fontWeight="700" style={styles.navHeader}>
            im
            <Text xxl fontWeight="400">
              aware
              <Text medium style={styles.navBrandTrademark}>
                ™
              </Text>
            </Text>
          </Text>
        </Layout>
        <Layout style={styles.navContainer}>
          {state.routes.map(
            (route: { key: string; name: string }, index: number) => (
              <React.Fragment key={route.key}>
                <Layout style={styles.navItemContainer}>
                  <Layout
                    style={
                      props.state.index === index
                        ? styles.navIndicatorFocused
                        : styles.navIndicator
                    }
                  />
                  <DrawerItem
                    key={route.key}
                    focused={props.state.index === index}
                    activeTintColor={activeTintColor}
                    activeBackgroundColor={activeBackgroundColor}
                    style={itemStyle}
                    label={({ focused }) => (
                      <Text
                        label
                        fontWeight={focused ? '700' : '400'}
                        style={{ marginHorizontal: -15 }}
                      >
                        {route.name}
                      </Text>
                    )}
                    onPress={() => {
                      props.navigation.navigate(route.name);
                    }}
                  />
                </Layout>
              </React.Fragment>
            )
          )}
        </Layout>
        <View style={styles.navButtonContainer}>
          <Button bold secondary medium style={styles.navButton}>
            {localization?.signOut ?? ''}
          </Button>
        </View>
      </Layout>
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  portalHeader: {
    borderBottomWidth: 0,
    height: 75,
    shadowColor: '#E8EEF3',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  navDrawer: { display: 'flex', height: 99 },
  navHeaderContainer: { flex: 1, minHeight: 100 },

  navHeader: {
    borderBottomColor: 'rgba(151, 151, 151, 0.2)',
    borderBottomWidth: 1,
    marginHorizontal: 25,
    paddingBottom: 30,
    paddingTop: 15,
    letterSpacing: 1.5,
  },
  navContainer: {
    flex: 7,
    minHeight: 250,
  },
  navItemContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  navIndicatorFocused: {
    borderWidth: 3,
    borderColor: 'black',
    borderRadius: 5,
    marginLeft: 2,
  },
  navIndicator: {
    borderWidth: 3,
    borderColor: 'white',
    borderRadius: 5,
    marginLeft: 2,
  },
  navBrandTrademark: { lineHeight: 22, textAlignVertical: 'top' },
  navButtonContainer: { flex: 1, justifyContent: 'center' },
  navButton: { paddingHorizontal: 70, marginHorizontal: 'auto' },
});
