import { gql } from '@apollo/client';

export const CREATE_CONSULT = gql`
  mutation CreateConsult($consultInput: ConsultInput!) {
    createConsult(consultInput: $consultInput) {
      id
      links {
        rel
        href
      }
      disqualifyingResponses {
        patientInstructions
        questionnaireId
        question
        value
      }
    }
  }
`;

export const GET_TIMESLOTS = gql`
  query getTimeslots(
    $id: String!
    $timezone: String!
    $start: String
    $end: String
  ) {
    getTimeslots(id: $id, timezone: $timezone, start: $start, end: $end) {
      start
      end
      clinicians {
        id
        name
        npi
        license {
          state
          expiresAt
          licenseNumber
        }
      }
    }
  }
`;

export const SCHEDULE_APPOINTMENT = gql`
  mutation scheduleAppointment(
    $wheelScheduleAppointment: WheelScheduleAppointment
  ) {
    scheduleAppointment(wheelScheduleAppointment: $wheelScheduleAppointment) {
      id
      status
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleAppointment(
    $wheelScheduleAppointment: WheelScheduleAppointment
  ) {
    rescheduleAppointment(wheelScheduleAppointment: $wheelScheduleAppointment) {
      id
      status
    }
  }
`;

export const FINALIZE_CONSULT = gql`
  mutation finalizeConsult($id: String!) {
    finalizeConsult(id: $id)
  }
`;
