/* eslint-disable indent */
import * as React from 'react';
import { Image, View, ImageSourcePropType } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { ReportLocalization } from 'sample-collection-instructions';
import * as codes from 'constants/reportCodes';
import parseMarkdown from 'utils/markdown';
import {
  Biomarker,
  ColorCode,
  Localization,
  Panel as PanelProp,
  Result,
  Segment,
  Sex,
  DeveloperConfig,
} from 'types';

import { LDL_HDL_RATIO } from 'constants/biomarkers';
import Text from './StyledText';
import { Layout } from './StyledView';

export const PanelContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <View
    style={{
      marginVertical: 20,
      flex: 1,
      marginHorizontal: 30,
      minHeight: 'auto',
    }}
  >
    {children}
  </View>
);
export const ResultSummary = ({
  children,
  code,
}: {
  children: string;
  code: ColorCode;
}): JSX.Element => {
  const codeBackgroundColorMap = {
    [codes.OPTGREEN]: 'rgba(122, 220, 109, 0.17)',
    [codes.GREEN]: 'rgba(122, 220, 109, 0.17)',
    [codes.YELLOW]: 'rgba(255, 245, 215, 1)',
    [codes.ORANGE]: 'rgba(255, 96, 24, 0.17)',
    [codes.RED]: 'rgba(250, 218, 215, 0.5)',
  };
  const summary = parseMarkdown(
    {
      Header: ({ children: headerChildren }: { children: string }) => (
        <Layout paddingBottom={'tiny'}>
          <Text lora large fontWeight={'500'}>
            {headerChildren}
          </Text>
        </Layout>
      ),
      Paragraph: ({ children: paragraphChildren }: { children: string }) => (
        <Text regular>{paragraphChildren}</Text>
      ),
    },
    children
  );
  return (
    <Layout
      padding={'small'}
      style={{
        maxWidth: 525,
        borderRadius: 8,
        backgroundColor: codeBackgroundColorMap[code],
        alignSelf: 'center',
      }}
    >
      <View>
        <Text>{summary}</Text>
      </View>
    </Layout>
  );
};

const PanelLeft = ({
  children,
  isMobile,
}: {
  children: React.ReactNode;
  isMobile: boolean;
}) => (
  <Layout
    marginBottom={isMobile && 'large'}
    marginRight={!isMobile && 'xl'}
    style={{
      flex: 40,
    }}
  >
    {children}
  </Layout>
);

const PanelRight = ({
  isMobile,
  children,
}: {
  children: React.ReactNode;
  isMobile: boolean;
}) => <View style={isMobile ? {} : { flex: 60 }}>{children}</View>;

const BiomarkerRangeHelper = ({
  boolean,
  segments,
  value,
  cutoff,
  reportable_range,
  developerConfig,
}: {
  boolean: boolean;
  segments: Segment[];
  value: string | number | boolean;
  cutoff: number;
  shortName: string;
  reportable_range: number[];
  developerConfig: DeveloperConfig;
}) => {
  if (boolean) return null;
  const segmentColorMap = {
    [codes.OPTGREEN]: '#7ADC6D',
    [codes.GREEN]: '#7ADC6D',
    [codes.YELLOW]: '#FEDA47',
    [codes.ORANGE]: '#F4A555',
    [codes.RED]: '#EA877C',
  };
  let val = value;
  const { key } = developerConfig;
  if (value > reportable_range[1]) val = reportable_range[1];
  if (value < reportable_range[0] && !LDL_HDL_RATIO.includes(key)) {
    val = reportable_range[0];
  }
  const sum = (a: number, b: number) => a + b;
  const sortedSegments = [...segments].sort(
    (a, b) => a.range.reduce(sum) - b.range.reduce(sum)
  );
  const segmentIndex = sortedSegments.findIndex((segment) => {
    let lowerBoundCondition;
    let upperBoundCondition;
    if (segment.lowerBoundInclusive) {
      lowerBoundCondition = val >= segment.range[0];
    } else {
      lowerBoundCondition = val > segment.range[0];
    }
    if (segment.upperBoundInclusive) {
      upperBoundCondition = val <= segment.range[1];
    } else {
      upperBoundCondition = val < segment.range[1];
    }
    return lowerBoundCondition && upperBoundCondition;
  });
  const cutoffPosition = sortedSegments.findIndex(
    (segment) => cutoff === segment.range[0]
  );

  if (segmentIndex === -1) return null;
  // TODO: Determine better method for parsing possible string into number
  const numericalValue = parseFloat(val as string);
  const valueTick =
    (100 * (numericalValue - sortedSegments[segmentIndex].range[0])) /
    (sortedSegments[segmentIndex].range[1] -
      sortedSegments[segmentIndex].range[0]);

  // const valueInDisplayRange = value >= display_range[0] && value <= display_range[1];
  // const range = valueInDisplayRange ? display_range : reportable_range;
  const Wrapper = ({ children }: { children: React.ReactNode }) => (
    <Layout
      marginLeft={'small'}
      marginRight={'regular'}
      style={{
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
      }}
    >
      {children}
    </Layout>
  );
  if (value === 4.3) {
    console.log({
      sortedSegments,
      cutoffPosition,
      cutoff,
      value,
    });
  }
  return (
    <Wrapper>
      <Layout
        marginHorizontal={'tiny'}
        style={{
          flex: 1,
          flexDirection: 'row',
        }}
      >
        {sortedSegments.map((segment, index) => {
          const styles: {
            borderTopLeftRadius?: number;
            borderBottomLeftRadius?: number;
            borderTopRightRadius?: number;
            borderBottomRightRadius?: number;
          } = {};
          if (!index) {
            styles.borderTopLeftRadius = 38;
            styles.borderBottomLeftRadius = 38;
          } else if (index === segments.length - 1) {
            styles.borderTopRightRadius = 38;
            styles.borderBottomRightRadius = 38;
          }
          return (
            <View
              key={index}
              style={[
                {
                  height: 10,
                  backgroundColor:
                    index === segmentIndex
                      ? segmentColorMap[segment.code]
                      : '#C3D2DF',
                  flex: 1,
                },
                styles,
              ]}
            >
              {index === segmentIndex && (
                <View
                  style={{
                    position: 'absolute',
                    top: -13,
                    width: 11,
                    height: 9,
                    left: `${valueTick}%`,
                    zIndex: 2,
                  }}
                >
                  <Image
                    style={{
                      position: 'absolute',
                      left: -5.5,
                      width: 11,
                      height: 9,
                    }}
                    source={require('../assets/images/marker.png')}
                  />
                </View>
              )}
              {!!index && (
                <View
                  style={{
                    position: 'absolute',
                    top: -6,
                    width: 0,
                    height: 22,
                    borderRadius: 10,
                    borderLeftWidth: 1,
                    borderStyle: 'dashed',
                    borderColor: '#989898',
                    left: -0.5,
                    zIndex: 1,
                  }}
                >
                  {cutoffPosition === index && (
                    <Text
                      tiny
                      fontWeight={'500'}
                      style={{
                        position: 'absolute',
                        width: 60,
                        left: -29,
                        marginTop: 24,
                        textAlign: 'center',
                      }}
                    >
                      {cutoff}
                    </Text>
                  )}
                </View>
              )}
            </View>
          );
        })}
      </Layout>
    </Wrapper>
  );
};

const BiomarkerRange = ({
  biomarker,
  result,
  localization,
  isMobile,
  sex,
}: {
  biomarker: Biomarker;
  result: Result;
  localization: Localization;
  isMobile: boolean;
  sex: Sex;
}) => {
  const { name, shortName = '', units, ranges, developerConfig } = biomarker;
  const sexSpecificRanges = ranges[sex] || ranges;
  const { segments, cutoff, reportable_range, boolean } = sexSpecificRanges;

  const value = boolean
    ? result.qualitativeResult ?? ''
    : result.quantitativeResult ?? 0;
  let val = value;
  if (val > reportable_range[1]) val = reportable_range[1];
  if (
    val < reportable_range[0] &&
    !LDL_HDL_RATIO.includes(developerConfig.key)
  ) {
    val = reportable_range[0];
  }
  let outcome;
  let valuePresentation = String(value);
  if (boolean) {
    outcome = value
      ? localization?.biomarkerPositiveOutcome
      : localization?.biomarkerNegativeOutcome;
  } else {
    const targetRange = segments.find(
      (segment) => segment.code === codes.GREEN
    );
    const optimalRange = segments.find(
      (segment) => segment.code === codes.OPTGREEN
    );
    if (!targetRange) return null;
    if (!targetRange.upperBoundInclusive && val >= targetRange.range[1]) {
      if (
        optimalRange &&
        !optimalRange.upperBoundInclusive &&
        val < optimalRange.range[1]
      ) {
        outcome = localization?.biomarkerOptimalOutcome;
      } else {
        outcome = localization?.biomarkerElevatedOutcome;
      }
    } else if (targetRange.upperBoundInclusive && val > targetRange.range[1]) {
      if (
        optimalRange &&
        optimalRange.upperBoundInclusive &&
        val <= optimalRange.range[1]
      ) {
        outcome = localization?.biomarkerOptimalOutcome;
      } else {
        outcome = localization?.biomarkerElevatedOutcome;
      }
    } else if (
      !targetRange.lowerBoundInclusive &&
      val <= targetRange.range[0]
    ) {
      outcome = localization?.biomarkerLowOutcome;
    } else if (targetRange.lowerBoundInclusive && val < targetRange.range[0]) {
      outcome = localization?.biomarkerLowOutcome;
    } else outcome = localization?.biomarkerNormalOutcome;
    if (value > reportable_range[1]) {
      valuePresentation = `>${reportable_range[1]}`;
    }
    if (value < reportable_range[0]) {
      valuePresentation = `<${reportable_range[0]}`;
    }
    if (result.aboveReportable) valuePresentation = `>${value}`;
    if (result.belowReportable) valuePresentation = `<${value}`;
  }
  const nameWidth = isMobile ? 92 : 142;
  const valueWidth = isMobile ? 82 : 82;
  if (boolean) {
    return (
      <Layout
        marginTop={'tiny'}
        marginBottom={'regular'}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          label
          fontWeight={'700'}
          style={{ color: '#4A4A4A', width: nameWidth }}
        >
          {shortName || name}
        </Text>
        <Text
          fontWeight={'700'}
          label
          style={{
            textAlign: 'right',
            color: '#4A4A4A',
            width: valueWidth,
            textTransform: 'uppercase',
          }}
        >
          {outcome}
        </Text>
      </Layout>
    );
  }
  return (
    <Layout
      marginTop={'tiny'}
      marginBottom={'regular'}
      style={{
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Text
        label
        fontWeight={'700'}
        style={[
          { color: '#4A4A4A', width: nameWidth },
          isMobile && {
            alignSelf: 'flex-start',
            width: '100%',
          },
        ]}
      >
        {shortName || name}
      </Text>
      <Layout
        marginBottom={isMobile && 'regular'}
        marginTop={isMobile && 'medium'}
        style={[
          {
            flexDirection: 'row',
          },
          isMobile
            ? {
                width: '100%',
              }
            : { flex: 1 },
        ]}
      >
        <Layout
          style={{
            width: valueWidth,
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <Text
            fontWeight={'700'}
            label
            style={{
              textAlign: isMobile ? 'left' : 'right',
              color: '#000000',
            }}
          >
            {valuePresentation}
          </Text>
          {units ? (
            <Text
              fontWeight={'500'}
              units
              style={{ color: '#000000' }}
            >{` ${units}`}</Text>
          ) : null}
        </Layout>
        <BiomarkerRangeHelper
          cutoff={cutoff}
          boolean={boolean}
          segments={segments}
          value={value}
          reportable_range={reportable_range}
          shortName={shortName}
          developerConfig={developerConfig}
        />
        <Text
          label
          fontWeight={'700'}
          style={{ color: '#4A4A4A', width: 72, textTransform: 'uppercase' }}
        >
          {outcome}
        </Text>
      </Layout>
    </Layout>
  );
};
const BiomarkerRanges = ({
  biomarkers,
  results,
  isMobile,
  sex,
}: {
  biomarkers: Biomarker[];
  results: Result[];
  isMobile: boolean;
  sex: Sex;
}) => (
  <ReportLocalization.Consumer>
    {(localization) =>
      biomarkers.map((biomarker) => {
        const result = results.find(
          ({ key }) => key === biomarker.developerConfig.key
        );
        if (!result) return null;
        return (
          <BiomarkerRange
            isMobile={isMobile}
            key={biomarker.name}
            biomarker={biomarker}
            result={result}
            localization={localization}
            sex={sex}
          />
        );
      })
    }
  </ReportLocalization.Consumer>
);
const Panel = ({
  panel,
  results,
  isMobile,
  sex,
}: {
  panel: PanelProp;
  results: Result[];
  isMobile: boolean;
  sex: Sex;
}) => {
  const { biomarkers } = panel;
  const panelNotEmpty = panel.biomarkers.some(({ developerConfig }) =>
    results.some(({ key }: DeveloperConfig) => key === developerConfig.key)
  );
  if (panelNotEmpty) {
    return (
      <Layout
        marginBottom={'regular'}
        style={{
          backgroundColor: '#F7F9FB',
          borderRadius: 4,
          flexDirection: isMobile ? 'column' : 'row',
          padding: 30,
        }}
      >
        <PanelLeft isMobile={isMobile}>
          <Layout marginBottom={'small'}>
            <Text
              style={{
                textTransform: 'uppercase',
              }}
              regular
              fontWeight={'700'}
            >
              {panel.title}
            </Text>
          </Layout>
          <Text regular>{panel.description}</Text>
        </PanelLeft>
        <PanelRight isMobile={isMobile}>
          <BiomarkerRanges
            isMobile={isMobile}
            biomarkers={biomarkers}
            results={results}
            sex={sex}
          />
        </PanelRight>
      </Layout>
    );
  } else {
    return null;
  }
};

export const Panels = ({
  panels,
  results,
  sex,
}: {
  panels: PanelProp[];
  results: Result[];
  sex: Sex;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <>
      {panels.map((panel) => (
        <Panel
          isMobile={isMobile}
          key={panel.title}
          panel={panel}
          results={results}
          sex={sex}
        />
      ))}
    </>
  );
};

export const SpeechBubble = ({
  avatarSource,
  children,
}: {
  avatarSource: ImageSourcePropType;
  children: string;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Layout
      style={{
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignItems: isMobile ? 'flex-start' : 'flex-end',
        justifyContent: isMobile ? 'center' : 'center',
        alignSelf: 'center',
      }}
      marginTop={'regular'}
      marginHorizontal={'xl'}
    >
      <Layout
        style={{
          width: 40,
          height: 40,
          top: isMobile ? -23 : 27,
        }}
        marginRight={'tiny'}
      >
        <Image
          style={{
            width: 40,
            height: 40,
            borderRadius: 40,
          }}
          source={avatarSource}
        />
      </Layout>
      <Image
        style={{
          width: 23,
          height: 46,
          top: isMobile ? -23 : 0,
        }}
        source={
          isMobile
            ? require('../assets/images/triangle-down.png')
            : require('../assets/images/triangle.png')
        }
      />
      <Layout
        style={{
          backgroundColor: '#FAF7ED',
          borderRadius: 10,
          borderBottomLeftRadius: 0,
          maxWidth: 660,
        }}
        paddingHorizontal={'xl'}
        paddingVertical={'regular'}
      >
        <Text regular>{children}</Text>
      </Layout>
    </Layout>
  );
};
