export const buttonTextMap = {
  'Test activated': 'View collection instructions',
  Shipped: 'Track your sample',
  Delivered: 'Track your sample',
  Completed: 'View your results',
  'Partially Completed': 'View your results',
  'Results unavailable': 'Contact support',
};

export const buttonColorMap = {
  'Test activated': '#F5CB16',
  Shipped: '#F5CB16',
  Delivered: '#F5CB16',
  Completed: '#7ADC6D',
  'Partially Completed': '#7ADC6D',
  'Results unavailable': '#DF3C31',
};

export const testStatusMap = {
  'Shipped To Patient': 'Test activated',
  Activated: 'Test activated',
  'Delivered To Patient': 'Test activated',
  'Shipped To Lab': 'Shipped',
  'Delivered To Lab': 'Delivered',
  'Received By Lab': 'Delivered',
  'Rejected By Lab': 'Results unavailable',
  'Partially Resulted By Lab': 'Partially Completed',
  'Fully Resulted By Lab': 'Completed',
};

export const testTableHeaders = [
  'Test',
  'Activation Date',
  'Current Status',
  'Next Steps',
];
