import { gql } from '@apollo/client';

export const GET_PATIENT_TEST_HISTORY = gql`
  query GetPatientTestHistory {
    getPatientTestHistory {
      testType
      kitId
      carePlanId
      activationDate
      trackingPatient {
        carrier
        trackingNumber
      }
      trackingLab {
        carrier
        trackingNumber
      }
      kitStatus {
        status
        timestamp
        rejectionReasons {
          code
          message
        }
      }
      appointment {
        start
        startFormatted
        consultId
        clinician
        ableToReschedule
        status
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query GetPatient {
    getPatient {
      id
      gender
      birthDate
      name {
        family
        given
      }
      address {
        city
        state
        postalCode
        country
        line
      }
      telecom {
        system
        value
      }
    }
  }
`;

export const LIST_QUESTIONNAIRES = gql`
  query listQuestionnaires {
    listQuestionnaires {
      url
      name
      status
      title
      item {
        linkId
        text
        type
        repeats
        required
        answerOption {
          valueInteger
          valueString
        }
        enableWhen {
          operator
          question
          answerString
          answerBoolean
        }
      }
    }
  }
`;
