import React from 'react';
import {
  View,
  Image,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
} from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';

import Text from 'components/StyledText';
import ScreenWrapper from 'components/ScreenWrapper';
import { useMediaQuery } from 'react-responsive';
import { RouteProp } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

const EmailVerificationScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers;
  route: RouteProp<{ params: { email: string } }, 'params'>;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const onGoToRegistrationScreen = () =>
    navigation.navigate('EmailRegistrationScreen');

  return (
    <ScreenWrapper>
      <View style={styles.headerBar}>
        <Image
          style={styles.imawareLogo}
          source={require('assets/images/logo.png')}
        />
      </View>
      <View style={styles.box}>
        <View style={styles.desktopContainer}>
          <Text medium fontWeight={'400'}>
            Please Verify your email
          </Text>
          <View style={styles.messageContainer}>
            <Text style={[styles.label]}>
              We have sent an email to{' '}
              <Text fontWeight={'700'}>{route?.params?.email || 'N/A'}</Text>
            </Text>
            <Text style={[styles.label, { marginBottom: 10 }]}>
              Please verify your email to gain access to your patient portal.
            </Text>
            <Text style={styles.label}>
              if you don't see a message in your inbox, make sure the email
              address listed above is correct and check your spam or junk mail
              folders.
            </Text>
          </View>
          <View style={styles.messageContainer}>
            <TouchableOpacity onPress={onGoToRegistrationScreen}>
              <Text style={styles.textdecoration}>Try a different email</Text>
            </TouchableOpacity>
          </View>
          <View
            style={
              isMobile ? styles.btnContainerMobile : styles.btnContainerDesktop
            }
          >
            <TouchableHighlight
              style={isMobile ? styles.button : styles.buttonDesktop}
              testID="signInButton"
              accessible={true}
              accessibilityLabel="signInButton"
              onPress={() => navigation.navigate('LoginScreen')}
            >
              <View style={styles.btnContainer}>
                <Text style={{ color: 'white', paddingRight: 10 }}>
                  {isMobile ? 'Login' : 'Sign in'}
                </Text>
                <AntDesign name="arrowright" size={18} color="white" />
              </View>
            </TouchableHighlight>
          </View>
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default EmailVerificationScreen;

const styles = StyleSheet.create({
  desktopContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  headerBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
  },
  imawareLogo: {
    width: 125,
    height: 21,
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
    paddingHorizontal: 100,
    backgroundColor: 'black',
    borderRadius: 100,
    paddingVertical: 10,
  },
  headerDesktop: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
  },
  buttonDesktop: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
    paddingHorizontal: 100,
    backgroundColor: 'black',
    borderRadius: 100,
    paddingVertical: 10,
  },
  btnContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  label: { textAlign: 'justify', width: 230 },
  logo: {
    width: '130',
    aspectRatio: 2,
    resizeMode: 'contain',
    display: 'flex',
    alignItems: 'flex-start',
  },
  btnContainerMobile: {
    flex: 2.5,
    justifyContent: 'flex-start',
    display: 'flex',
  },
  btnContainerDesktop: {
    flex: 2.5,
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    flex: 2,
    alignItems: 'center',
    backgroundColor: '#e6f9eb',
  },
  messageContainer: {
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'auto',
  },
  textdecoration: {
    textDecorationLine: 'underline',
  },
});
