import React, { useState } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import {
  ReportContainer,
  ContentHeader,
  Paragraph,
} from 'components/ReportCommon';
import { Table } from 'components/Details';
import { ContentSummaryContainer } from 'components/Common';
import ScreenWrapper from 'components/ScreenWrapper';
import MissingBiomarkers from 'components/report/MissingBiomarkers';

import cmsClient, { ALL_REPORTS } from 'sample-collection-instructions';
import reportClient, { GET_KIT_ID } from 'kit-activation';
import { getReport, getPage } from 'utils/content';
import { formatObservations } from 'utils/data';
import {
  Biomarker,
  Page,
  RecommendationBiomarkerKey,
  Result,
  Sex,
} from 'types';

import GET_TEST_RESULTS from './queries';

export default ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [biomarkers, setBiomarkers] = useState<Biomarker[]>([]);
  const [unresultedBiomarkers, setUnresultedBiomarkers] = useState<string[]>(
    []
  );
  const [expanded, setExpanded] = useState(false);
  const [sex, setSex] = useState<Sex>('' as Sex);
  const [title, setTitle] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] =
    useState<ApolloError | null>(null);

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = data?.kitId ?? '';

  const [getTestResults, { loading: resultsLoading, error: resultsError }] =
    useMutation(GET_TEST_RESULTS, {
      variables: {
        kitId,
      },
      onCompleted: ({ getTestResults: testResultsData }) => {
        const {
          patient: { gender },
          testType,
          observations,
        } = testResultsData;
        const testResults = formatObservations(observations);
        const { pages: unfilteredPages, title: reportTitle } = getReport(
          content,
          testType
        );
        const {
          biomarkers: reportBiomarkers = [],
          title: pageTitle,
          description: pageDescription,
          subtitle: pageSubtitle,
        } = getPage(unfilteredPages, 'ReportDetailsPageRecord');
        setSex(gender);
        setResults(testResults);
        const filteredPages = unfilteredPages.filter(
          (p) => p.goToButton !== 'View Background'
        );
        const testedBiomarkerNames = [
          ...new Set(reportBiomarkers.map((b) => b.name.split(' - ')[0])),
        ];
        const resultedBiomarkerCodes = testResults.map((r) => r.key);
        const resultedBiomarkerNames = reportBiomarkers
          .filter((b) =>
            resultedBiomarkerCodes.includes(
              b.developerConfig.key as RecommendationBiomarkerKey
            )
          )
          .map((b) => b.name.split(' - ')[0]);
        const unresultedBiomarkerNames: string[] = [];
        testedBiomarkerNames.forEach((n) => {
          if (!resultedBiomarkerNames.includes(n)) {
            unresultedBiomarkerNames.push(n);
          }
        });
        setUnresultedBiomarkers(unresultedBiomarkerNames);
        setBiomarkers(reportBiomarkers);
        setPages(filteredPages);
        setTitle(reportTitle);
        setContentTitle(pageTitle);
        setDescription(pageDescription);
        setSubtitle(pageSubtitle);
      },
      onError: (err) =>
        console.error(
          `An error occurred during GetTestResults Mutation with kit id: ${kitId}`,
          err
        ),
    });

  const {
    loading: contentLoading,
    error: contentError,
    data: content,
  } = useQuery(ALL_REPORTS(), {
    fetchPolicy: 'network-only',
    client: cmsClient,
    onCompleted: () => getTestResults(),
    onError: (err) =>
      console.error('An error occurred during AllReports Query', err),
  });

  return (
    <ScreenWrapper
      loading={contentLoading || resultsLoading || reportDownloadLoading}
      error={contentError || resultsError || reportDownloadError}
      excludeWrapper
      excludeFooter
    >
      <ReportContainer
        pages={pages}
        navigation={navigation}
        title={title}
        screen={'DetailsScreen'}
        setLoading={setReportDownloadLoading}
        setError={setReportDownloadError}
      >
        <ContentHeader title={contentTitle} subtitle={subtitle} />
        <ContentSummaryContainer>
          <Paragraph leftAlignOnMobile>{description}</Paragraph>
        </ContentSummaryContainer>
        <Table biomarkers={biomarkers} results={results} sex={sex} />
        {unresultedBiomarkers.length ? (
          <MissingBiomarkers
            expanded={expanded}
            biomarkers={unresultedBiomarkers}
            setExpanded={setExpanded}
          />
        ) : null}
      </ReportContainer>
    </ScreenWrapper>
  );
};
