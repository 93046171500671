import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  TouchableHighlight,
  Image,
  Platform,
} from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useMediaQuery } from 'react-responsive';
import { authenticateUser, isAuthenticated } from 'auth/auth0';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import GlobalStyles from 'constants/Styles';

import { checkKitIdParam } from 'screens/registration/EmailRegistrationScreen/utils';
import { GET_PATIENT } from 'screens/dashboard/PatientDashboardScreen/queries';
import { checkAuthParams } from './utils';

const LoginScreen = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}): JSX.Element => {
  const [signinError, setSignInError] = useState<string | null>(null);
  const [getPatient, { loading, error: getPatientError }] = useLazyQuery(
    GET_PATIENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getPatient: getPatientResponse }) => {
        console.log('### getPatient onCompleted getPatient');
        if (getPatientResponse.id) {
          console.log('### getPatient onCompleted ID found');
          navigation.navigate('KitActivation');
        } else {
          console.log('### getPatient onCompleted no ID');
          navigation.navigate('CompletePatientProfileScreen');
        }
      },
    }
  );

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const onSignIn = () => {
    authenticateUser()
      .then((data) => {
        console.log('### Authenticate user returned: ', JSON.stringify(data));
        isAuthenticated().then((isAuth: boolean) => {
          console.log(`### useEffect isAuth: ${isAuth}`);
          if (isAuth) {
            getPatient();
          }
        });
      })
      .catch((err) => {
        console.error('### Error: ', JSON.stringify(err));
        setSignInError(err.message);
      });
  };

  useEffect(() => {
    console.log('### useEffect');

    if (Platform.OS === 'web') {
      console.log('### useEffect web');
      checkAuthParams()
        .then((token) => {
          console.log(`### useEffect checkAuthParams done. Token: ${token}`);
          if (token && typeof token === 'string') {
            checkKitIdParam();
            getPatient();
          }
        })
        .catch((err) => {
          console.error('### Error: ', JSON.stringify(err));
          setSignInError(err.message);
        });
    }
  }, []);

  return (
    <ScreenWrapper loading={loading} error={getPatientError}>
      <View style={isMobile ? [GlobalStyles.container] : styles.desktopBox}>
        {isMobile && (
          <View style={styles.header}>
            <Image
              source={require('../../../assets/images/logo.png')}
              style={styles.logo}
            />
            <TouchableHighlight
              onPress={() => navigation.navigate('EmailRegistrationScreen')}
            >
              <Text style={{ textDecorationLine: 'underline' }}>Sign Up</Text>
            </TouchableHighlight>
          </View>
        )}
        <View>
          <Text large style={styles.title}>
            Login
          </Text>
          {signinError ? (
            <Text
              style={[GlobalStyles.error, styles.subtitleDesktop, styles.error]}
            >
              {signinError}
            </Text>
          ) : null}
          <Text
            small
            style={isMobile ? styles.subtitle : styles.subtitleDesktop}
          >
            Please login to your secure imaware™ patient portal to activate a
            new kit or view your results.
          </Text>
          <Text style={isMobile ? styles.subtitle : styles.subtitleDesktop}>
            To log in, please ensure you have pop-ups enabled. The login page
            will open in a separate window.
          </Text>
        </View>
        <View
          style={{ display: 'flex', justifyContent: 'center' }}
          testID={'signInBtn'}
        >
          <Button
            primary
            onPress={onSignIn}
            style={isMobile ? styles.button : styles.desktopButton}
          >
            {isMobile ? 'Login' : 'Sign In'}
          </Button>
        </View>
        {!isMobile && (
          <TouchableHighlight
            onPress={() => navigation.navigate('EmailRegistrationScreen')}
          >
            <Text small>Don't have an account? Sign Up</Text>
          </TouchableHighlight>
        )}
      </View>
    </ScreenWrapper>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 30,
    marginVertical: 10,
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: '400',
    marginVertical: 10,
    width: 'auto',
  },
  subtitleDesktop: {
    textAlign: 'center',
    fontWeight: '400',
    marginVertical: 10,
    width: 400,
  },
  button: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
  },
  desktopButton: {
    width: 250,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
  },
  desktopInput: {
    width: 400,
    height: 40,
    marginVertical: 10,
    borderWidth: 1,
    padding: 10,
  },
  desktopBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  forgotLabel: { textAlign: 'right', fontWeight: 'bold', marginTop: 10 },
  logo: {
    width: 130,
    aspectRatio: 5,
    resizeMode: 'contain',
    display: 'flex',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 10,
    color: '#808080d9',
    fontWeight: '600',
    marginVertical: 5,
  },
  error: {
    marginTop: 15,
  },
});
