import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Modal, View, StyleSheet, TouchableHighlight } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { ModalInterface } from 'types';
import Text from 'components/StyledText';
import LoadingErrorWrapper from './LoadingErrorWrapper';

const ModalComponent = ({
  title,
  actions,
  children,
  onHidden,
  loading,
  hideTitle,
}: ModalInterface): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const getContainerStyles = () => {
    if (isMobile) {
      return styles.mobileBox;
    } else {
      if (loading) {
        return [styles.desktopBox, { paddingTop: 30 }];
      }
      return styles.desktopBox;
    }
  };

  return (
    <Modal
      visible
      onRequestClose={onHidden}
      transparent={true}
      animationType={'fade'}
      presentationStyle="overFullScreen"
    >
      <View style={styles.backdrop} />
      <View style={styles.modalContainer}>
        <View style={getContainerStyles()}>
          <LoadingErrorWrapper loading={loading} error={null}>
            <View style={styles.titleBox}>
              <View style={styles.closeContainer}>
                <Ionicons
                  name="close"
                  size={30}
                  color="black"
                  onPress={onHidden}
                />
              </View>
              {!hideTitle ? (
                <Text lato style={styles.title}>
                  {title || ''}
                </Text>
              ) : null}
            </View>
            <Text lato style={styles.title}>
              {title || ''}
            </Text>
            <View style={styles.modalContent}>{children}</View>
            <View style={styles.footer}>
              {actions.map((action) => (
                <TouchableHighlight
                  style={actions.length === 1 ? styles.bgButton : styles.button}
                  onPress={action.onPress}
                  key={action.name}
                  testID={`${action.name}Btn`}
                >
                  <Text style={styles.textStyle}>{action.name}</Text>
                </TouchableHighlight>
              ))}
            </View>
          </LoadingErrorWrapper>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    width: '100vw',
    height: '100vh',
  },
  backdrop: {
    height: '100%',
    width: '100%',
    backgroundColor: '#000000',
    opacity: 0.7,
    position: 'absolute',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  },
  modalContent: {
    width: '100%',
  },
  modalMobileContent: {
    width: '100%',
  },
  closeContainer: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
  mobileBox: {
    width: 'calc(100% - 40px)',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 5,
    minHeight: '300px',
    maxHeight: 'calc(100% - 100px)',
    overflow: 'scroll',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 30,
    opacity: 1,
  },
  desktopBox: {
    width: 700,
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 4,
    alignItems: 'center',
    paddingHorizontal: 45,
    paddingBottom: 30,
    paddingTop: 20,
    opacity: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: 'relative',
  },
  title: {
    alignContent: 'center',
    textAlign: 'center',
    marginVertical: 15,
    fontFamily: 'Lato',
    fontSize: 22,
    fontWeight: '700',
    width: '100%',
    marginHorizontal: 'auto',
    lineHeight: 26,
  },
  mobileTitle: {
    alignContent: 'center',
    textAlign: 'center',
    marginVertical: 15,
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: '700',
    marginHorizontal: 'auto',
    lineHeight: 26,
  },
  button: {
    borderRadius: 25,
    backgroundColor: 'black',
    width: 150,
    textAlign: 'center',
    paddingVertical: 12,
  },
  textStyle: {
    color: 'white',
    textAlign: 'center',
  },
  bgButton: {
    borderRadius: 25,
    backgroundColor: 'black',
    width: 250,
    textAlign: 'center',
    paddingVertical: 12,
  },
  footer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxHeight: 100,
  },
  headerModal: {
    marginTop: 10,
  },
  btnHidden: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  container: { flex: 2, width: '100%' },
  textButton: { color: 'white', textAlign: 'center' },
  titleBox: { marginTop: 10, width: '100%' },
});

export default ModalComponent;
