import * as allBiomarkers from 'constants/biomarkers';
import {
  calculateReportCode as calculateReportCode_v1,
  calculateBiomarkerCode as calculateBiomarkerCode_v1,
  calculateReportBackgroundCode as calculateReportBackgroundCode_v1,
} from '../bridge/imaware_v1';
import {
  Answer,
  Biomarker,
  BiomarkerCode,
  ColorCode,
  Observation,
  Question,
  RecommendationBiomarkerKey,
  Result,
  Sex,
} from '../types';

export const formatObservations = (observations: Observation[]): Result[] =>
  observations
    .filter((observation) => {
      if (observation.valueQuantity?.value) {
        const {
          code: { coding = [] } = {},
          valueQuantity: { value: observationValue } = {},
        } = observation;
        const biomarkerCode =
          (coding?.find((c) => !c?.system?.includes('imaware.health'))
            ?.code as RecommendationBiomarkerKey) ?? '';
        if (
          allBiomarkers.CHOLESTEROL_TOTAL.includes(biomarkerCode) ||
          allBiomarkers.HDL_C.includes(biomarkerCode) ||
          allBiomarkers.HSCRP.includes(biomarkerCode)
        ) {
          return false;
        }
        if (!observationValue) return false;
        const belowReportable = observationValue.toString().includes('<');
        const aboveReportable = observationValue.toString().includes('>');
        if (belowReportable && aboveReportable) return false;
        return true;
      }
      return null;
    })
    .map((observation: Observation) => {
      const {
        code: { coding = [] },
        valueQuantity: { value: observationValue = 0 } = {},
      } = observation;
      const key =
        (coding?.find((c) => c?.system?.includes('imaware.health'))
          ?.code as RecommendationBiomarkerKey) ?? '';
      const belowReportable = observationValue.toString().includes('<');
      const aboveReportable = observationValue.toString().includes('>');
      return {
        key,
        quantitativeResult: observationValue,
        belowReportable,
        aboveReportable,
      };
    });

export const calculateReportCode = (
  results: Result[],
  biomarkers: Biomarker[],
  sex: Sex,
  reportVersion = 'imaware_v1'
): ColorCode => {
  switch (reportVersion) {
    case 'imaware_v1':
      return calculateReportCode_v1(results, biomarkers, sex);
    default:
      throw new Error('invalid report version');
  }
};

export const calculateBiomarkerCode = (
  results: Result[],
  biomarkers: Biomarker[],
  sex: Sex,
  reportVersion = 'imaware_v1'
): BiomarkerCode[] => {
  switch (reportVersion) {
    case 'imaware_v1':
      return calculateBiomarkerCode_v1(results, biomarkers, sex);
    default:
      throw new Error('invalid report version');
  }
};

export const calculateReportBackgroundCode = (
  answers: Answer[],
  questions: Question[],
  reportVersion = 'imaware_v1'
): ColorCode => {
  switch (reportVersion) {
    case 'imaware_v1':
      return calculateReportBackgroundCode_v1(answers, questions);
    default:
      throw new Error('invalid report version');
  }
};
