export const MENS_HEALTH = 'mens_health';
export const WOMENS_HEALTH = 'womens_health';
export const FEMALE_FERTILITY_AND_WELLNESS = 'female_fertility_and_wellness';
export const FEMALE_FERTILITY = 'female_fertility';
export const FEMALE_WELLNESS = 'female_wellness';
export const VITAMIN_D_MONITORING = 'vitamin_d_monitoring';
export const TESTOSTERONE_MONITORING = 'testosterone_monitoring';
export const PROSTATE_SCREENING = 'prostate_screening';
export const RHEUMATOID_ARTHRITIS = 'rheumatoid_arthritis';
export const CELIAC_SCREENING = 'celiac_screening';
export const CELIAC_MONITORING = 'celiac_monitoring';
export const PREDIABETES_SCREENING = 'prediabetes_screening';
export const IMMUNE_DEFENSE = 'immune_defense';
export const THYROID_SCREENING = 'thyroid_screening';
export const BASELINE_HEART_HEALTH = 'baseline_heart_health';
export const VASCULAR_INFLAMMATION = 'vascular_inflammation';
export const ESSENTIAL_HEART_HEALTH = 'essential_heart_health';
export const LIPOPROTEIN_A = 'lipoprotein_a';
export const TESTOSTERONE_ADX = 'testosterone_adx';
