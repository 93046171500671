import axios from 'axios';
import { KlaviyoRequests } from 'types';
import env from 'constants/Config';

export const klaviyoRequestWithProperties = (req: KlaviyoRequests) =>
  axios
    .request({
      method: 'POST',
      url: 'https://a.klaviyo.com/api/track',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        data: `{"token": "${env.KLAVIYO_PUBLIC_APIKEY}", "event": "${req.event}", 
              "customer_properties": {"$email": "${req.email}", "$first_name": 
              "${req.firstName}", "$last_name": "${req.lastName}"}, ${req.properties}}`,
      }),
    })
    .then((response) => console.log(response.status))
    .catch((error) => console.log(error));

export const klaviyoRequest = (req: KlaviyoRequests) =>
  axios
    .request({
      method: 'POST',
      url: 'https://a.klaviyo.com/api/track',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        data: `{"token": "${env.KLAVIYO_PUBLIC_APIKEY}", "event": "${req.event}", 
              "customer_properties": {"$email": "${req.email}", "$first_name": 
              "${req.firstName}", "$last_name": "${req.lastName}"}}`,
      }),
    })
    .then((response) => console.log(response.status))
    .catch((error) => console.log(error));
