import * as React from 'react';
import { Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import * as WebBrowser from 'expo-web-browser';
import { MarkdownElement } from '../types';
import StyledText from '../components/StyledText';

type ContextProps = 'first_name' | 'tested_date';

export default (
  options?: {
    Header: MarkdownElement;
    Paragraph: MarkdownElement;
    first_name?: string;
    tested_date?: string;
  },
  markdown = ''
): string | JSX.Element[] => {
  const {
    Header = Text,
    Paragraph = Text,
    ...context
  } = options ?? {
    first_name: null,
    tested_date: null,
  };
  if (!markdown.trim()) return '';
  let formattedMarkdown = markdown;
  const matches = markdown.match(/{{[a-zA-Z\d_]*}}/gm) ?? [];
  matches.forEach((match) => {
    const parsedMatch = match.substring(2, match.length - 2);
    const contextKey = parsedMatch as ContextProps;
    formattedMarkdown = formattedMarkdown.replace(
      match,
      context[contextKey] ?? ''
    );
  });
  return formattedMarkdown
    .split('\n')
    .filter((element) => element.length)
    .map((element) => {
      const linkText = element.match(/\[(.*?)\]/) ?? '';
      const link = element.match(/\((.*?)\)/) ?? '';
      if (element.length > 2) {
        if (element[0] === '#' && element[1] === ' ') {
          return <Header key={element}>{element.slice(2)}</Header>;
        }
        if (linkText) {
          return (
            <StyledText regular key={element}>
              {element.slice(0, element.indexOf(linkText[1]) - 1)}
              <TouchableOpacity
                onPress={() => {
                  if (typeof window !== 'undefined') {
                    window.open(link[1], '_blank');
                  } else {
                    WebBrowser.openBrowserAsync(link[1]);
                  }
                }}
              >
                <StyledText
                  style={{ color: 'blue', textDecorationLine: 'underline' }}
                >
                  {linkText[1]}
                </StyledText>
              </TouchableOpacity>
              {element.slice(element.indexOf(')') + 1)}
            </StyledText>
          );
        }
      }
      return <Paragraph key={element}>{element}</Paragraph>;
    });
};
