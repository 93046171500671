import * as React from 'react';
import {
  Image,
  ScrollView,
  ImageSourcePropType,
  StyleSheet,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import * as WebBrowser from 'expo-web-browser';

import { ReportLocalization } from 'sample-collection-instructions';

// MediaQuery => horizontal scroll view on sections
import {
  BLOOD_GLUCOSE,
  CHOLESTEROL_TOTAL,
  HDL_C,
  LDL_C,
  TRIGLYCERIDES,
  HSCRP,
  FERRITIN,
  HB_A1C,
  TESTOSTERONE,
  PSA,
  VITAMIN_D,
  TSH,
  FSH,
  E2,
  AMH,
  BLOOD_COTININE,
  LP_PLA2_ACTIVITY,
  LIPOPROTEIN_A,
  TTG_IGA,
  TTG_IGG,
  DGP_IGA,
  DGP_IGG,
  RF_IGM,
  RF_IGA,
} from 'constants/biomarkers';
import { Localization, PatientRecommendation, BiomarkerCode } from 'types';

import { Layout } from './StyledView';
import Text from './StyledText';
import Button from './StyledButton';

export const Sections = ({
  recommendations,
}: {
  recommendations: PatientRecommendation[];
}): JSX.Element => {
  const [numberToShow, setNumberToShow] = React.useState(3);
  const handleClick = () => {
    setNumberToShow(numberToShow + 3);
  };
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <ReportLocalization.Consumer>
      {(localization) => (
        <SectionContainer isMobile={isMobile}>
          {isMobile && <Layout paddingLeft={'large'} />}
          {recommendations.slice(0, numberToShow).map((recommendation) => (
            <Section
              isMobile={isMobile}
              key={recommendation.rec.link}
              isDefault={recommendation.biomarkers[0].key === 'default'}
            >
              <SectionColour biomarkers={recommendation.biomarkers} />
              <SectionTitle>{recommendation.rec.title}</SectionTitle>
              <SectionDescription
                isDefault={recommendation.biomarkers[0].key === 'default'}
              >
                {recommendation.rec.description}
              </SectionDescription>
              {recommendation.rec.link !== '' ? (
                <SectionButton learnMore={recommendation.rec.link}>
                  {localization?.learnMore ?? 'Learn more'}
                </SectionButton>
              ) : null}
            </Section>
          ))}
          {numberToShow >= recommendations.length ? null : (
            <ShowMore handleClick={handleClick} localization={localization} />
          )}

          {isMobile && <Layout paddingRight={'large'} />}
        </SectionContainer>
      )}
    </ReportLocalization.Consumer>
  );
};
export const SectionContainer = ({
  children,
  isMobile,
}: {
  children: React.ReactNode;
  isMobile: boolean;
}): JSX.Element => {
  if (isMobile) {
    return <Layout style={styles.mobileContainer}>{children}</Layout>;
  }
  return (
    <ScrollView
      horizontal={true}
      style={styles.desktopContainer}
      contentContainerStyle={styles.scrollViewContentContainer}
    >
      {children}
    </ScrollView>
  );
};
export const SectionIcon = ({
  source,
}: {
  source: ImageSourcePropType;
}): JSX.Element => <Image style={styles.sectionIcon} source={source} />;

export const SectionColour = ({
  biomarkers,
}: {
  biomarkers: BiomarkerCode[];
}): JSX.Element => {
  let colour;
  if (biomarkers.length === 1) {
    colour = biomarkerColourMap[biomarkers[0].key];
  } else {
    colour = '#C3D2DF';
  }

  return (
    <Layout style={styles.layoutSectionColour}>
      <Layout
        style={[
          styles.sectionColour,
          {
            backgroundColor: colour,
          },
        ]}
      />
    </Layout>
  );
};

export const SectionTitle = ({
  children,
}: {
  children: string;
}): JSX.Element => (
  <Layout
    marginHorizontal={'regular'}
    marginVertical={'regular'}
    style={styles.sectionTitle}
  >
    <Text style={styles.sectionText} fontWeight={'500'} regular>
      {children}
    </Text>
  </Layout>
);
export const SectionDescription = ({
  children,
  isDefault,
}: {
  children: string;
  isDefault: boolean;
}): JSX.Element => (
  <Layout
    paddingBottom={isDefault ? 'regular' : null}
    style={styles.sectionDescription}
  >
    <Text style={styles.sectionLabel} label>
      {children}
    </Text>
  </Layout>
);

export const SectionButton = ({
  children,
  learnMore,
}: {
  children: string;
  learnMore: string;
}): JSX.Element => (
  <Layout marginTop={'small'}>
    <Button
      onPress={() => {
        if (typeof window !== 'undefined') {
          window.open(learnMore, '_blank');
        } else {
          WebBrowser.openBrowserAsync(learnMore);
        }
      }}
      small
      bold
      arrowRight
      style={styles.sectionBtn}
    >
      {children}
    </Button>
  </Layout>
);

export const Section = ({
  children,
  isMobile,
  isDefault,
}: {
  children: React.ReactNode;
  isMobile: boolean;
  isDefault: boolean;
}): JSX.Element => (
  <Layout
    marginBottom={isMobile && 'medium'}
    style={{
      // eslint-disable-next-line no-nested-ternary
      width: isMobile ? '100%' : isDefault ? '60%' : '33.33%',
      minWidth: 290,
    }}
    paddingHorizontal={'small'}
  >
    <Layout
      marginVertical={'small'}
      paddingBottom={'small'}
      style={styles.sectionLayout}
    >
      {children}
    </Layout>
  </Layout>
);
export const MessageBubble = ({
  children,
}: {
  children: string | React.ReactNode;
}): JSX.Element => (
  <Layout marginTop={'regular'} style={styles.messageBubble}>
    <Text style={styles.messageBubbleText} regular>
      {children}
    </Text>
  </Layout>
);

export const ShowMore = ({
  handleClick,
  localization,
}: {
  handleClick: () => void;
  localization: Localization | null;
}): JSX.Element => (
  <Layout>
    <Button small onPress={handleClick} style={styles.showMoreBtn}>
      {localization?.showMore ?? 'Show more'}
    </Button>
  </Layout>
);

const biomarkerColourMap = {
  [LP_PLA2_ACTIVITY]: '#EF9D98',
  [HSCRP[0]]: '#EF9D98',
  [HSCRP[1]]: '#EF9D98',
  [HSCRP[2]]: '#EF9D98',
  [HSCRP[3]]: '#EF9D98',
  [HSCRP[4]]: '#EF9D98',
  [CHOLESTEROL_TOTAL[0]]: '#EF9D98',
  [CHOLESTEROL_TOTAL[1]]: '#EF9D98',
  [CHOLESTEROL_TOTAL[2]]: '#EF9D98',
  [CHOLESTEROL_TOTAL[3]]: '#EF9D98',
  [CHOLESTEROL_TOTAL[4]]: '#EF9D98',
  [TRIGLYCERIDES[0]]: '#EF9D98',
  [TRIGLYCERIDES[1]]: '#EF9D98',
  [TRIGLYCERIDES[2]]: '#EF9D98',
  [TRIGLYCERIDES[3]]: '#EF9D98',
  [TRIGLYCERIDES[4]]: '#EF9D98',
  [LDL_C[0]]: '#EF9D98',
  [LDL_C[1]]: '#EF9D98',
  [LDL_C[2]]: '#EF9D98',
  [LDL_C[3]]: '#EF9D98',
  [LDL_C[4]]: '#EF9D98',
  [LDL_C[5]]: '#EF9D98',
  [HDL_C[0]]: '#EF9D98',
  [HDL_C[1]]: '#EF9D98',
  [HDL_C[2]]: '#EF9D98',
  [HDL_C[3]]: '#EF9D98',
  [HDL_C[4]]: '#EF9D98',
  [LIPOPROTEIN_A[0]]: '#EF9D98',
  [LIPOPROTEIN_A[1]]: '#EF9D98',
  [BLOOD_GLUCOSE[0]]: '#C1D7F2',
  [BLOOD_GLUCOSE[1]]: '#C1D7F2',
  [BLOOD_GLUCOSE[2]]: '#C1D7F2',
  [BLOOD_GLUCOSE[3]]: '#C1D7F2',
  [HB_A1C[0]]: '#C1D7F2',
  [HB_A1C[1]]: '#C1D7F2',
  [HB_A1C[2]]: '#C1D7F2',
  [HB_A1C[3]]: '#C1D7F2',
  [HB_A1C[4]]: '#C1D7F2',
  Insulin: '#C1D7F2',
  'Haptoglobin Genotype': '#C1D7F2',
  [TESTOSTERONE[0]]: '#CBEBE4',
  [TESTOSTERONE[1]]: '#CBEBE4',
  [TESTOSTERONE[2]]: '#CBEBE4',
  [TESTOSTERONE[3]]: '#CBEBE4',
  [TESTOSTERONE[4]]: '#CBEBE4',
  [PSA[0]]: '#CBEBE4',
  [PSA[1]]: '#CBEBE4',
  [PSA[2]]: '#CBEBE4',
  [TSH[0]]: '#AEA9E3',
  [TSH[1]]: '#AEA9E3',
  [TSH[2]]: '#AEA9E3',
  [TSH[3]]: '#AEA9E3',
  [TSH[4]]: '#AEA9E3',
  [FSH[0]]: '#F1E6B2',
  [FSH[1]]: '#F1E6B2',
  [E2[0]]: '#A7C6ED',
  [E2[1]]: '#A7C6ED',
  [E2[2]]: '#A7C6ED',
  [AMH[0]]: '#A7C6ED',
  [AMH[1]]: '#A7C6ED',
  Allergens: '#C4E5B2',
  [VITAMIN_D[0]]: '#FFE680',
  [VITAMIN_D[1]]: '#FFE680',
  [VITAMIN_D[2]]: '#FFE680',
  [VITAMIN_D[3]]: '#FFE680',
  [VITAMIN_D[4]]: '#FFE680',
  [RF_IGM]: '#F1A882',
  [DGP_IGG[0]]: '#F1A882',
  [TTG_IGG[0]]: '#F1A882',
  [DGP_IGA]: '#F1A882',
  [TTG_IGA[0]]: '#F1A882',
  [RF_IGA]: '#F1A882',
  'CCP IgG': '#F1A882',
  Cotinine: '#F1E6B2',
  [BLOOD_COTININE[0]]: '#F1E6B2',
  [BLOOD_COTININE[1]]: '#F1E6B2',
  Ferritin: '#F1E6B2',
  [FERRITIN[0]]: '#F1E6B2',
  [FERRITIN[1]]: '#F1E6B2',
  [FERRITIN[2]]: '#F1E6B2',
  [FERRITIN[3]]: '#F1E6B2',
  default: '#00C600',
};

const styles = StyleSheet.create({
  mobileContainer: {
    width: '100%',
    maxWidth: 330,
    alignSelf: 'center',
  },
  desktopContainer: {
    flexDirection: 'row',
    maxWidth: 980,
    alignSelf: 'center',
    marginBottom: 60,
  },
  sectionColour: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 25,
    width: '100%',
  },
  sectionTitle: { paddingHorizontal: 15, paddingVertical: 10 },
  sectionText: { textAlign: 'center' },
  sectionDescription: { paddingHorizontal: 15 },
  sectionLabel: { lineHeight: 32, textAlign: 'center', color: '#666666' },
  sectionBtn: { borderWidth: 0 },
  sectionLayout: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    alignItems: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    flexGrow: 1,
  },
  messageBubble: {
    backgroundColor: '#F6F9FD',
    borderRadius: 10,
    maxWidth: 660,
    alignSelf: 'center',
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  showMoreBtn: {
    paddingHorizontal: 60,
    marginHorizontal: 'auto',
    marginTop: 30,
  },
  scrollViewContentContainer: {
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: 'max-content',
  },
  sectionIcon: {
    width: 64,
    height: 64,
    backgroundColor: '#f7f9fb',
    borderRadius: 64,
  },
  messageBubbleText: {
    textAlign: 'center',
  },
  layoutSectionColour: {
    width: '100%',
  },
});
