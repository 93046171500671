import { ApolloError } from '@apollo/client';
import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import Text from 'components/StyledText';
import { useMediaQuery } from 'react-responsive';
import {
  DateSelected,
  ListQuestionnaireItem,
  QuestionnaireResponseItem,
  ListQuestionnaire,
  IntakeData,
} from 'types';
import GlobalStyles from 'constants/Styles';
import { formatDate } from './utils';

const ConsultConfirmation = ({
  dateSelected,
  scheduleAppointmentError,
  finalizeConsultError,
  intakeData,
  questions,
}: {
  dateSelected: DateSelected | null;
  scheduleAppointmentError: ApolloError | undefined;
  finalizeConsultError: ApolloError | undefined;
  intakeData: IntakeData | null;
  questions: ListQuestionnaire;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const patientResponses = intakeData?.intakeData.item;
  const questionnaire = questions.item;

  const renderIntake = () =>
    patientResponses?.map((response: QuestionnaireResponseItem) => {
      const questionId = response.linkId;

      const question = questionnaire.find(
        (questionnaireItem: ListQuestionnaireItem) => {
          if (questionnaireItem.linkId === questionId) {
            return questionnaireItem.text;
          }
          return null;
        }
      );

      let questionResponse = '';

      if (response.answer.length > 1) {
        response.answer.map((answer, index: number) => {
          questionResponse =
            questionResponse +
            answer.valueString +
            (index === response.answer.length - 1 ? '' : ', ');
          return questionResponse;
        });
      } else {
        questionResponse += response.answer[0].valueString;
      }

      if (questionResponse === 'true') {
        questionResponse = 'Yes';
      } else if (questionResponse === 'false') {
        questionResponse = 'No';
      }

      return (
        <View style={styles.questionContainer}>
          <Text lato fontWeight="700" style={styles.question}>
            {question?.text}
          </Text>
          <Text lato style={styles.response}>
            {questionResponse}
          </Text>
        </View>
      );
    });

  return (
    <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
      <View style={styles.box}>
        <Text lato fontWeight="700" style={styles.subtitle}>
          Consult Questionnaire answers:{' '}
        </Text>
        <ScrollView style={styles.questionnaireContainer}>
          {renderIntake()}
        </ScrollView>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <Text lato fontWeight="700" style={styles.subtitle}>
            Date and time:{' '}
          </Text>
          <Text lato fontWeight="400">
            {dateSelected ? formatDate(dateSelected) : null}
          </Text>
          {scheduleAppointmentError || finalizeConsultError ? (
            <Text lato style={[GlobalStyles.error, styles.errorText]}>
              Something went wrong when trying to confirm your consult
              appointment. Please contact{' '}
              <a
                href="mailto:support@imaware.health"
                style={{ color: '#DC412C' }}
              >
                support@imaware.health
              </a>{' '}
              for further assistance
            </Text>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  desktopContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginVertical: 30,
  },
  box: {
    flex: 1,
  },
  subtitle: {
    fontSize: 18,
  },
  listItem: {
    marginBottom: 5,
    fontSize: 16,
  },
  textBox: {
    marginVertical: 10,
    maxWidth: 'auto',
  },
  rescheduling: {
    lineHeight: 24,
  },
  errorText: {
    marginVertical: 15,
    textAlign: 'center',
  },
  questionnaireContainer: {
    marginBottom: 30,
    paddingHorizontal: 10,
    maxHeight: 250,
  },
  questionContainer: {
    marginTop: 10,
    marginBottom: 0,
  },
  question: {
    marginBottom: 5,
    fontWeight: 600,
  },
  response: {
    marginBottom: 0,
    marginLeft: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15,
  },
  rowMobile: {
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 15,
  },
});

export default ConsultConfirmation;
