import React, { useState } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import {
  ReportContainer,
  Paragraph,
  ContentHeader,
} from 'components/ReportCommon';
import { ContentSummaryContainer } from 'components/Common';
import { Sections } from 'components/Recommendations';
import ScreenWrapper from 'components/ScreenWrapper';

import cmsClient, { ALL_REPORTS } from 'sample-collection-instructions';
import reportClient, { GET_KIT_ID } from 'kit-activation';

import { formatObservations, calculateBiomarkerCode } from 'utils/data';
import {
  getReport,
  getPage,
  getRecommendations,
  getBiomarkerLevel,
} from 'utils/content';
import { Biomarker, Page, PatientRecommendation } from 'types';
import GET_TEST_RESULTS from './queries';

export default ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [biomarkers, setBiomarkers] = useState<Biomarker[]>([]);
  const [recommendations, setRecommendations] = useState<
    PatientRecommendation[]
  >([]);
  const [title, setTitle] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [defaultDescription, setDefaultDescription] = useState('');
  const [key, setKey] = useState('');
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] =
    useState<ApolloError | null>(null);

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = data?.kitId ?? '';

  const [getTestResults, { loading: resultsLoading, error: resultsError }] =
    useMutation(GET_TEST_RESULTS, {
      variables: {
        kitId,
      },
      onCompleted: ({ getTestResults: testResultsData }) => {
        const {
          patient: { gender },
          testType,
          observations,
        } = testResultsData;
        const testResults = formatObservations(observations);
        const { pages: unfilteredPages, title: reportTitle } = getReport(
          content,
          testType
        );
        const { biomarkers: reportBiomarkers = [] } = getPage(
          unfilteredPages,
          'ReportDetailsPageRecord'
        );
        const codes = calculateBiomarkerCode(
          testResults,
          reportBiomarkers,
          gender
        );
        const {
          title: pageTitle,
          description: pageDescription,
          subtitle: pageSubtitle,
          defaultDescription: defaultDesc = '',
          recommendations: allRecommendations = [],
        } = getPage(unfilteredPages, 'ReportRecommendationsPageRecord');
        const biomarkerLevels = getBiomarkerLevel(
          reportBiomarkers,
          testResults,
          gender
        );
        const calculatedRecommendations = getRecommendations(
          allRecommendations,
          codes,
          biomarkerLevels,
          biomarkers
        );
        const { key: biomarkerKey } =
          calculatedRecommendations[0].biomarkers[0];
        setDefaultDescription(defaultDesc);
        setRecommendations(calculatedRecommendations);
        const filteredPages = unfilteredPages.filter(
          (p) => p.goToButton !== 'View Background'
        );
        setKey(biomarkerKey);
        setBiomarkers(reportBiomarkers);
        setPages(filteredPages);
        setTitle(reportTitle);
        setContentTitle(pageTitle);
        setDescription(pageDescription);
        setSubtitle(pageSubtitle);
      },
      onError: (err) =>
        console.error(
          `An error occurred during GetTestResults Mutation with kit id: ${kitId}`,
          err
        ),
    });

  const {
    loading: contentLoading,
    error: contentError,
    data: content,
  } = useQuery(ALL_REPORTS(), {
    client: cmsClient,
    onCompleted: () => getTestResults(),
    onError: (err) =>
      console.error('An error occurred during AllReports Query', err),
  });

  return (
    <ScreenWrapper
      loading={contentLoading || resultsLoading || reportDownloadLoading}
      error={contentError || resultsError || reportDownloadError}
      excludeWrapper
      excludeFooter
    >
      <ReportContainer
        pages={pages}
        navigation={navigation}
        title={title}
        screen={'RecommendationsScreen'}
        setLoading={setReportDownloadLoading}
        setError={setReportDownloadError}
      >
        <ContentHeader title={contentTitle} subtitle={subtitle} />
        <ContentSummaryContainer>
          <Paragraph leftAlignOnMobile>
            {key === 'default' ? defaultDescription : description}
          </Paragraph>
        </ContentSummaryContainer>
        <Sections recommendations={recommendations} />
      </ReportContainer>
    </ScreenWrapper>
  );
};
