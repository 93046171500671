import React, { useEffect, useState } from 'react';
import { View, Linking, Pressable, StyleSheet } from 'react-native';

import Text from 'components/StyledText';
import { AllRequirements } from 'types';
import CheckboxKitCode from 'components/CheckboxKitCode';

const TestingRequirements = ({
  requirements,
  setTestAgree,
}: {
  requirements: AllRequirements | null;
  setTestAgree: any;
}): JSX.Element => {
  const [boxesChecked, setBoxesChecked] = useState(0);

  useEffect(() => {
    if (boxesChecked === requirements?.prepRequirements.length) {
      return setTestAgree(true);
    }
    return setTestAgree(false);
  }, [boxesChecked]);

  return (
    <>
      {requirements?.prepRequirements?.map((requirement, index) => {
        const { description, link } = requirement;
        const [checked, setChecked] = useState(false);
        const linkText = description.match(/\[(.*?)\]/) ?? '';
        const preLinkText = description.slice(
          0,
          description.indexOf(linkText[1]) - 1
        );

        const BoxToggle = (checkedBox: boolean) => {
          if (!checkedBox) {
            setBoxesChecked(boxesChecked + 1);
          } else {
            setBoxesChecked(boxesChecked - 1);
          }
          setChecked(!checkedBox);
        };

        return (
          <View
            key={requirement.title}
            style={styles.requirementWrapper}
            testID={`checkmark${index}`}
          >
            <CheckboxKitCode
              checked={checked}
              onChange={() => BoxToggle(checked)}
            />
            {link ? (
              <Text testID="linkText" lato style={styles.requirementText}>
                {preLinkText}
                <Pressable onPress={() => Linking.openURL(link)}>
                  <Text style={styles.linkText} testID={`requirement${index}`}>
                    {linkText[1]}
                  </Text>
                </Pressable>
              </Text>
            ) : (
              <Text
                style={styles.requirementText}
                testID={`requirement${index}`}
              >
                {description}
              </Text>
            )}
          </View>
        );
      })}
    </>
  );
};

const styles = StyleSheet.create({
  requirementWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  checkmarkIcon: {
    width: 20.8,
    height: 20.8,
  },
  requirementText: {
    paddingLeft: 14,
  },
  linkText: {
    textDecorationLine: 'underline',
    color: 'blue',
  },
});

export default TestingRequirements;
