import moment from 'moment';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { MaskedText } from 'react-native-mask-text';
import { useMediaQuery } from 'react-responsive';
import { AccountInformation } from 'types';

const PersonalInformation = (accountData: AccountInformation | any) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const birthDateFormat = accountData?.birthDate.replace(/-/g, '/');
  return (
    <View style={styles.container}>
      <Text style={styles.title} testID="title">
        Personal Information
      </Text>
      <View>
        <View style={isMobile ? styles.mobileBox : styles.box}>
          <Text style={styles.text} testID="name">
            {`${accountData?.name}` && `${accountData?.lastname}`
              ? `${accountData?.name} ${accountData?.lastname}`
              : 'N/A'}
          </Text>
          <MaskedText
            mask="(999) 999-9999"
            style={styles.text}
            testID="phoneNumber"
          >
            {accountData?.phoneNumber || 'N/A'}
          </MaskedText>
        </View>
        <View style={isMobile ? styles.mobileBox : styles.box}>
          <View style={styles.textBox}>
            <Text style={styles.birthDateText} testID="gender">
              {accountData?.gender ? `${accountData?.gender}` : 'N/A'}
            </Text>
            <Text>{accountData?.gender ? '(by birth)' : null}</Text>
          </View>
          <Text style={[styles.underline]} testID="email">
            {accountData?.email || 'N/A'}
          </Text>
        </View>
        <View style={isMobile ? styles.mobileBox : styles.box}>
          <Text style={styles.text} testID="birthdate">
            {accountData?.birthDate
              ? `Born ${moment(birthDateFormat).format('MM/DD/YYYY')}`
              : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PersonalInformation;

const styles = StyleSheet.create({
  title: {
    fontWeight: '600',
    fontFamily: 'lora_500',
    fontSize: 18,
    marginVertical: 5,
  },
  text: {
    flex: 1,
    marginVertical: 5,
    textTransform: 'capitalize',
  },
  container: {
    marginVertical: 20,
    width: '100%',
  },
  box: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  mobileBox: { display: 'flex', flexDirection: 'column' },
  underline: {
    flex: 1,
    marginVertical: 5,
    textDecorationLine: 'underline',
  },
  birthDateText: {
    marginVertical: 5,
    marginRight: 5,
    textTransform: 'capitalize',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
});
