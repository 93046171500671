export const collectionDeviceMap = {
  'Female Fertility and Wellness Test': 'microtube-minttop',
  'Female Fertility Test': 'microtube-minttop',
  'Female Wellness Test': 'microtube-minttop',
  'Testosterone Monitoring Test': 'microtube-minttop',
  'Prostate Cancer Screening Test': 'hemaspot',
  "Women's Health & Wellness Test": 'hemaspot',
  "Men's Health & Wellness Test": 'hemaspot',
  'Thyroid Screening Test': 'hemaspot',
  'Vitamin D Monitoring Test': 'hemaspot',
  'Prediabetes / Diabetes Screening Test': 'hemaspot',
  'Baseline Heart Health Test': 'hemaspot',
  'Immune Defense Test': 'hemaspot',
  'Celiac Disease Screening Test': 'microtube-purpletop',
  'Celiac Disease Monitoring Test': 'microtube-purpletop',
  'Rheumatoid Arthritis Test': 'microtube-purpletop',
  'IgE Allergy Test': 'microtube-purpletop',
  'Lipoprotein(a) Test': 'microtube-minttop',
  '': '',
};
