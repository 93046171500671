import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Text from 'components/StyledText';

const StartTelehealth = (): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
      <View style={!isMobile ? styles.box : null}>
        <Text lato style={styles.subtitle}>
          Next Step
        </Text>
        <View style={[styles.box, styles.textBox]}>
          <Text lato>Fill out a questionnaire</Text>
        </View>
      </View>
      <View style={!isMobile ? styles.box : null}>
        <Text style={styles.subtitle}>Rescheduling</Text>
        <View style={styles.textBox}>
          <Text lato style={styles.description}>
            Your appointment can be rescheduled up to 2 hours before the
            originally scheduled time.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    marginVertical: 15,
  },
  desktopContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginVertical: 30,
  },
  box: {
    flex: 1,
  },
  subtitle: {
    fontWeight: '900',
    fontSize: 16,
  },
  listItem: {
    marginBottom: 5,
    fontSize: 16,
  },
  textBox: {
    marginVertical: 10,
    maxWidth: 'auto',
  },
  description: {
    lineHeight: 24,
    fontWeight: '400',
  },
});

export default StartTelehealth;
