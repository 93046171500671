import React from 'react';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ColorSchemeName } from 'react-native';
import { useQuery } from '@apollo/client';
import client, { SET_PARTNER_CONFIGS } from 'kit-activation';
import cmsClient, { ALL_PARTNER_CONFIGS } from 'sample-collection-instructions';

import NotFoundScreen from 'screens/NotFoundScreen';
import { PartnerConfigData, PartnerName, RootStackParamList } from 'types';

import LoadingModal from 'components/LoadingModal';
import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';
import { checkKitIdParam } from 'screens/registration/EmailRegistrationScreen/utils';
import KitNavigator from './KitNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import RegistrationNavigator from './RegistrationNavigator';

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}): JSX.Element {
  const { loading: contentLoading } = useQuery<PartnerConfigData>(
    ALL_PARTNER_CONFIGS,
    {
      client: cmsClient,
      onCompleted: ({ allPartnerConfigs }) => {
        const partnerName = window.location.hostname.split(
          '.'
        )[0] as PartnerName;
        const partnerMap = {
          localhost: 'favor',
          favor: 'favor',
          healthome: 'healthome',
        };
        const matchingPartner = allPartnerConfigs.find(
          (config) => config.key === partnerMap[partnerName]
        );
        client.writeQuery({
          query: SET_PARTNER_CONFIGS,
          data: { partnerConfig: matchingPartner },
        });
      },
    }
  );

  if (contentLoading) {
    return <LoadingModal />;
  }

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      documentTitle={{
        formatter: () => 'Patient Portal | imaware',
      }}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { isAuthenticated, isLoading } = useCheckOrRefreshAuth();

  if (isLoading) {
    return <LoadingModal />;
  }

  checkKitIdParam();

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={
        !isLoading && isAuthenticated ? 'KitActivation' : 'Registration'
      }
    >
      <Stack.Screen name="Registration" component={RegistrationNavigator} />
      <Stack.Screen name="KitActivation" component={KitNavigator} />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
    </Stack.Navigator>
  );
}
