import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { View, Pressable, StyleSheet, TouchableOpacity } from 'react-native';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { ScrollView } from 'react-native-gesture-handler';
import { Picker } from '@react-native-picker/picker';
import {
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';
import * as WebBrowser from 'expo-web-browser';

import {
  states,
  stateCodeMap,
} from 'screens/registration/CompletePatientProfileScreen/utils';
import GlobalStyles from 'constants/Styles';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import RadioButton from 'components/RadioButton';
import TextInput from 'components/StyledTextInput';
import Checkbox from 'components/Checkbox';

import {
  Patient,
  QuestionnaireAnswerItem,
  QuestionnaireConditional,
  QuestionnaireQuestions,
  QuestionnaireQuestion,
  StateName,
  IntakeData,
} from 'types';
import { useMediaQuery } from 'react-responsive';
import { formatPascalCase } from 'utils/formatPascalCase';

const Questionnaire = ({
  patientData,
  modalPage,
  setModalPage,
  questions,
  kitId,
  createConsult,
  listQuestionnairesError,
  createConsultError,
  setIntakeData,
}: {
  patientData: Patient;
  modalPage: number;
  setModalPage: Dispatch<SetStateAction<number>>;
  questions: QuestionnaireQuestions;
  kitId: string;
  createConsult: (
    // eslint-disable-next-line no-unused-vars
    options?:
      | MutationFunctionOptions<OperationVariables, DefaultContext>
      | undefined
  ) => Promise<FetchResult<Record<string, string>, Record<string, string>>>;
  listQuestionnairesError: ApolloError | undefined;
  createConsultError: ApolloError | undefined;
  setIntakeData: Dispatch<SetStateAction<IntakeData | null>>;
}): JSX.Element => {
  const { control, getValues, handleSubmit } = useForm({
    mode: 'onBlur',
  });
  const [page, setPage] = useState(0);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
  const [currentPageData, setCurrentPageData] = useState<QuestionnaireQuestion>(
    {} as QuestionnaireQuestion
  );
  const formFieldValues = getValues();
  const questionnaireRef = useRef();

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  useEffect(() => {
    if (questions.item && page === 0) {
      setCurrentPageData(questions.item[page]);
    }
  }, [questions]);

  useEffect(() => {
    if (page === questions.item.length - 1 && questionAnswered) {
      setQuestionnaireCompleted(true);
    }
  }, [page]);

  const formatQuestionAnswers = (answers: string | string[]) => {
    if (Array.isArray(answers)) {
      return answers.map((value: string) => ({
        valueString: value,
      }));
    } else {
      return [
        {
          valueString: answers ?? 'None of the above',
        },
      ];
    }
  };

  const getQuestionResponses = () => {
    const patientResponses = getValues();
    const item: QuestionnaireAnswerItem[] = Object.keys(patientResponses)
      .filter((field) => field !== 'consent' && field !== 'state')
      .map((field) => ({
        linkId: field,
        answer: formatQuestionAnswers(patientResponses[field]),
      }));
    const patient = {
      patientId: patientData.id,
      kitId,
      encounterStateCode: stateCodeMap[patientResponses.state as StateName],
      checkedConsents: patientResponses.consent,
      intakeData: {
        questionnaire: '/v1/questionnaires/fertility',
        item,
      },
    };
    createConsult({
      variables: {
        consultInput: patient,
      },
    });
    setIntakeData(patient);
  };

  const resetQuestionnairePosition = () => {
    questionnaireRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }

  const onChangeNextQuestion = () => {
    if (page < questions.item.length - 1) {
      for (let i = page + 1; i < questions.item.length; i += 1) {
        const question = questions.item[i];
        const { enableWhen } = question;
        let renderQuestion = true;
        if (enableWhen) {
          renderQuestion = handleConditional(enableWhen);
        }
        if (renderQuestion) {
          setPage(i);
          if (question.type !== 'attachment') {
            setCurrentPageData(question);
            const value = formFieldValues[question.linkId];
            if (value === undefined) {
              setQuestionAnswered(false);
            }
          }
          resetQuestionnairePosition();
          return;
        }
      }
    }
  };

  const onChangePreviousQuestion = () => {
    let currentPage = 0;
    if (page >= 0) {
      for (let i = 1; i <= page; i += 1) {
        currentPage = page - i;
        if (questions.item[currentPage].enableWhen !== null) {
          const question =
            questions.item?.[currentPage].enableWhen?.[0].question;
          const answer =
            questions.item?.[currentPage].enableWhen?.[0].answerString;
          if (question && formFieldValues[question]?.includes(answer)) {
            break;
          }
        } else {
          break;
        }
      }
    }
    setPage(currentPage);
    setCurrentPageData(questions.item[currentPage]);
    setQuestionAnswered(true);
    resetQuestionnairePosition();
  };

  const onSubmit = () => getQuestionResponses();

  const onViewConsentForm = () =>
    WebBrowser.openBrowserAsync(
      'https://www.wheel.com/wheel-provider-group-telehealth-informed-consent'
    );

  const handleConditional = (enableWhen: QuestionnaireConditional[]) => {
    for (const e of enableWhen) {
      const question = e.question;
      const qualifyingAnswerString = e.answerString;
      const qualifyingAnswerBoolean = e.answerBoolean;
      const operator = e.operator;
      const userAnswer = formFieldValues[question];
      const validAnswer =
        userAnswer?.includes(qualifyingAnswerString) ||
        (qualifyingAnswerBoolean &&
          userAnswer === qualifyingAnswerBoolean.toString());
      const includesCondition =
        operator === 'exists' && userAnswer !== undefined;
      if (!validAnswer && !includesCondition) {
        return false;
      }
    }
    return true;
  };

  const multipleChoiceOnChange = (
    // eslint-disable-next-line no-unused-vars
    onChange: (event: string[]) => void,
    value: string[] | undefined,
    option: string
  ) => {
    let questionIsAnswered = true;
    if (!value) {
      onChange([option]);
    } else if (value.includes(option)) {
      const newValue = value.filter((v: string) => v !== option);
      if (!newValue.length) {
        questionIsAnswered = false;
      }
      onChange(newValue);
    } else {
      onChange([...value, option]);
    }
    setQuestionAnswered(questionIsAnswered);
  };

  const singleChoiceOnChange = (
    // eslint-disable-next-line no-unused-vars
    onChange: (event: string | null) => void,
    value: string | null
  ) => {
    onChange(value);
    setQuestionAnswered(true);
  };

  const renderQuestionNavigation = (
    error: FieldError | undefined,
    required: boolean
  ) => {
    const navigatingCompleteQuestionnaire =
      questionnaireCompleted && page < questions.item.length - 1;
    const questionRequirementsSatisfied = questionAnswered || !required;
    const navigateToNextQuestion =
      questionRequirementsSatisfied && !questionnaireCompleted;
    return (
      <>
        <View style={isMobile ? styles.buttonMobileContainer : null}>
          <Button
            ignoreWidth
            style={
              isMobile
                ? styles.questionNavButtonLeftMobile
                : styles.questionNavButtonLeft
            }
            onPress={onChangePreviousQuestion}
          >
            {'<'}
          </Button>
          {navigateToNextQuestion || navigatingCompleteQuestionnaire ? (
            <Button
              ignoreWidth
              style={
                isMobile
                  ? styles.questionNavButtonRightMobile
                  : styles.questionNavButtonRight
              }
              onPress={onChangeNextQuestion}
            >
              {'>'}
            </Button>
          ) : null}
        </View>
        {error ? (
          <Text units style={GlobalStyles.error}>
            {error.message ? error.message : required}
          </Text>
        ) : null}
      </>
    );
  };

  const renderFields = () =>
    [currentPageData]?.map(
      ({ linkId, text, type, answerOption, required, repeats }) => {
        const answerOptions = answerOption?.map((e) => e);
        if (answerOptions && repeats && !required) {
          answerOptions?.push({
            __typename: 'QuestionnaireAnswerOption',
            valueString: 'None of the above',
          });
        }
        return (
          <React.Fragment key={linkId}>
            <Controller
              name={linkId}
              control={control}
              rules={{
                required: {
                  value: required,
                  message: 'A response is required.',
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                switch (type) {
                  case 'choice':
                    return (
                      <View style={styles.questionContainer}>
                        <View>
                          <Text style={styles.questionText}>
                            {text}
                            {required ? '*' : null}
                          </Text>
                        </View>
                        {answerOptions?.map((option) =>
                          repeats ? (
                            <TouchableOpacity
                              style={styles.radioButton}
                              key={option.valueString}
                              onPress={() =>
                                multipleChoiceOnChange(
                                  onChange,
                                  value,
                                  option.valueString ?? ''
                                )
                              }
                            >
                              <Checkbox
                                checked={value?.includes(option.valueString)}
                                onChange={() =>
                                  multipleChoiceOnChange(
                                    onChange,
                                    value,
                                    option.valueString ?? ''
                                  )
                                }
                                style={styles.checkbox}
                                testID={`${formatPascalCase(option.valueString)}Box`}
                              />
                              <Text label> {option.valueString}</Text>
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity
                              style={styles.radioButton}
                              onPress={() =>
                                singleChoiceOnChange(
                                  onChange,
                                  option.valueString
                                )
                              }
                              key={option.valueString}
                            >
                              <RadioButton
                                selected={value === option.valueString}
                                testID={`${formatPascalCase(option.valueString)}Btn`}
                              />
                              <Text label> {option.valueString}</Text>
                            </TouchableOpacity>
                          )
                        )}

                        {renderQuestionNavigation(error, required)}
                      </View>
                    );
                  case 'boolean':
                    return (
                      <View style={styles.questionContainer}>
                        <Text>
                          {text}
                          {required ? '*' : null}
                        </Text>
                        <TouchableOpacity
                          style={styles.radioButton}
                          onPress={() => singleChoiceOnChange(onChange, 'true')}
                        >
                          <RadioButton selected={value === 'true'} />
                          <Text lato>Yes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.radioButton}
                          onPress={() =>
                            singleChoiceOnChange(onChange, 'false')
                          }
                        >
                          <RadioButton selected={value === 'false'} />
                          <Text lato>No</Text>
                        </TouchableOpacity>
                        {renderQuestionNavigation(error, required)}
                      </View>
                    );
                  case 'text':
                    return (
                      <View style={styles.questionContainer}>
                        <View>
                          <Text style={styles.questionText}>
                            {text}
                            {required ? '*' : null}
                          </Text>
                        </View>
                        <TextInput
                          onChangeText={(e) =>
                            singleChoiceOnChange(onChange, e)
                          }
                          onBlur={onBlur}
                          value={value as string}
                          style={[
                            GlobalStyles.input,
                            error && GlobalStyles.inputError,
                          ]}
                          testID={linkId}
                          placeholder="Please type response here."
                          placeholderTextColor="grey"
                          keyboardType="default"
                        />
                        {renderQuestionNavigation(error, required)}
                      </View>
                    );
                  default:
                    return (
                      <View style={styles.questionContainer}>
                        <View>
                          <Text style={styles.questionText}>{text}</Text>
                        </View>
                        <View
                          style={isMobile ? styles.buttonMobileContainer : null}
                        >
                          <Button
                            ignoreWidth
                            style={styles.questionNavButtonLeft}
                            onPress={onChangePreviousQuestion}
                          >
                            {'<'}
                          </Button>
                          <Button
                            ignoreWidth
                            style={styles.questionNavButtonRight}
                            onPress={onChangeNextQuestion}
                          >
                            {'>'}
                          </Button>
                        </View>
                      </View>
                    );
                }
              }}
            />
          </React.Fragment>
        );
      }
    );

  return (
    <View style={styles.container}>
      <View style={styles.centeredView}>
        <View>
          <Text lato style={styles.subHeader}>
            Please confirm your personal information and answer the questions
            below.
          </Text>
          <ScrollView style={isMobile ? styles.questionnaireMobile : styles.questionnaire} ref={questionnaireRef}>
            <View style={isMobile ? { flex: 3} : null}>
              <Controller
                control={control}
                name={'state'}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Text lato style={styles.dropdownText}>
                     Which state are you currently located in?
                    </Text>
                    <Picker
                      selectedValue={value}
                      onValueChange={(val) => onChange(val)}
                      onBlur={onBlur}
                      style={[styles.picker, error && GlobalStyles.inputError]}
                      testID="statePicker"
                    >
                      {states?.map((option) => (
                        <Picker.Item
                          key={option.label}
                          label={option.label}
                          value={option.value}
                          testID={option.label}
                        />
                      ))}
                    </Picker>
                    {error ? (
                      <Text units style={[GlobalStyles.error, styles.error]}>
                        This field is required
                      </Text>
                    ) : null}
                  </>
                )}
              />
              <Text lato style={styles.questionSectionHeader}>
                Consult Questionnaire
              </Text>
              {renderFields()}
            </View>
          </ScrollView>
          <Controller
            control={control}
            name={'consent'}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <>
                <View
                  style={
                    isMobile
                      ? styles.checkboxMobileContainer
                      : styles.checkboxContainer
                  }
                >
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    style={styles.checkbox}
                    testID="consentBox"
                  />
                  <View
                    style={
                      isMobile
                        ? styles.consentMobileMessage
                        : styles.consentMessage
                    }
                  >
                    <Text lato>I have read and accept the </Text>
                    <Pressable onPress={onViewConsentForm}>
                      <Text lato style={styles.underline}>
                        Telehealth Consent Form.
                      </Text>
                    </Pressable>
                    <Text lato>
                      The healthcare professional is an independent entity.
                      imaware provides no medical or health care service or
                      advice.
                    </Text>
                  </View>
                </View>
              </>
            )}
          />
          {createConsultError || listQuestionnairesError ? (
            <Text lato style={[GlobalStyles.error, styles.errorText]}>
              Something went wrong while trying to create a consult. Please
              contact{' '}
              <a
                href="mailto:support@imaware.health"
                style={{ color: '#DC412C' }}
              >
                support@imaware.health
              </a>{' '}
              for further assistance
            </Text>
          ) : null}
          <View
            style={isMobile ? styles.buttonMContainer : styles.buttonContainer}
          >
            <Button
              ignoreWidth
              style={isMobile ? styles.buttonMobile : styles.button}
              onPress={() => setModalPage(modalPage - 1)}
            >
              Back
            </Button>
            {questionnaireCompleted ? (
              <Button
                primary
                ignoreWidth
                style={isMobile ? styles.buttonMobile : styles.button}
                onPress={handleSubmit(onSubmit)}
              >
                Next
              </Button>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export default Questionnaire;

const styles = StyleSheet.create({
  header: {
    lineHeight: 26,
    fontSize: 22,
    textAlign: 'center',
    color: '#000000',
  },
  subHeader: {
    color: '#333333',
    marginBottom: 20,
    textAlign: 'center',
  },
  dropdownText: {
    fontSize: 12,
    lineHeight: 24,
    letterSpacing: 0.8,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  questionSectionHeader: {
    fontWeight: '900',
    fontSize: 16,
    lineHeight: 19,
    marginTop: 22,
  },
  questionText: {
    marginTop: 12.5,
  },
  button: {
    width: 200,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  buttonMobile: {
    width: 200,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    marginHorizontal: 8,
    marginVertical: 10,
  },
  questionNavButtonLeft: {
    position: 'relative',
    width: 10,
    height: 10,
    justifyContent: 'center',
    top: 20,
    borderRadius: 0,
  },
  questionNavButtonLeftMobile: {
    width: 10,
    height: 10,
    borderRadius: 0,
  },
  questionNavButtonRight: {
    position: 'relative',
    width: 10,
    height: 10,
    right: -400,
    top: -12,
    justifyContent: 'center',
    marginRight: 20,
    borderRadius: 0,
  },
  questionNavButtonRightMobile: {
    width: 10,
    height: 10,
    borderRadius: 0,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    width: '100%',
  },
  radioButton: {
    flexDirection: 'row',
    marginRight: 15,
    alignItems: 'center',
    marginTop: 10,
  },
  optionContainer: {
    flexDirection: 'row',
    marginRight: 15,
    alignItems: 'center',
    height: 50,
    marginTop: 10,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  checkboxMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkbox: { height: 20, width: 20, marginRight: 12 },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 20,
    justifyContent: 'space-between',
  },
  buttonMContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 20,
    alignItems: 'center',
  },
  underline: { textDecorationLine: 'underline' },
  questionnaire: {
    height: 375,
    marginBottom: 45,
  },
  questionnaireMobile: {
    height: '100%',
    marginVertical: 15,
    overflow: 'hidden',
  },
  questionContainer: {
    marginBottom: 15,
  },
  picker: {
    height: 50,
    backgroundColor: '#F7F7F7',
    marginTop: 10,
    width: '100%',
    paddingLeft: 16,
    borderWidth: 0,
  },
  consentMessage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'fit-content',
  },
  consentMobileMessage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'fit-content',
    marginHorizontal: 15,
  },
  error: {
    marginTop: 0,
  },
  errorText: {
    marginBottom: 20,
    textAlign: 'center',
  },
  container: {
    height: '100%',
  },
});
