import React from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import TextInput from 'components/StyledTextInput';
import GlobalStyles from 'constants/Styles';
import env from 'constants/Config';

import { ForgotPasswordField } from 'types';
import axios from 'axios';
import HeaderBar from 'components/HeaderBar';
import { useMediaQuery } from 'react-responsive';

const ResetPasswordScreen = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}) => {
  const { control, handleSubmit } = useForm<ForgotPasswordField>({
    mode: 'onBlur',
  });
  const onSendPress: SubmitHandler<ForgotPasswordField> = (data) => {
    axios
      .request({
        method: 'POST',
        url: `${env.AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          email: data.email,
          connection: env.AUTH0_CONNECTION,
          client_id: env.AUTH0_CLIENT_ID,
        },
      })
      .then(() => navigation.navigate('SuccessResetPasswordScreen'))
      .catch(({ err }) =>
        Alert.alert(
          err?.message ??
            'An error occurred while generating your reset password link. Please try again later.'
        )
      );
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <ScreenWrapper>
      <HeaderBar navigation={navigation} />
      <View style={styles.subContainer}>
        <View
          style={isMobile ? styles.mobileContainer : styles.desktopContainer}
        >
          <Text lora large fontWeight="500">
            Enter Email Associated With Account
          </Text>
          <Text style={[GlobalStyles.subheader, { paddingHorizontal: 0 }]}>
            We will email you a link to reset your password
          </Text>
          <Text units style={GlobalStyles.label} fontWeight={'700'}>
            EMAIL ADDRESS
          </Text>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'Email address is required',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <>
                <TextInput
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  style={[
                    GlobalStyles.input,
                    styles.inputDesktop,
                    error && GlobalStyles.inputError,
                  ]}
                  keyboardType="email-address"
                  placeholder="example@email.com"
                  placeholderTextColor="grey"
                  testID="email"
                  accessible={true}
                  accessibilityLabel="email"
                />

                {error && (
                  <Text style={GlobalStyles.error}>{error.message}</Text>
                )}
              </>
            )}
          />
          <Button
            onPress={handleSubmit(onSendPress)}
            style={GlobalStyles.button}
            primary
            arrowRight
          >
            Reset Password
          </Button>
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default ResetPasswordScreen;

const styles = StyleSheet.create({
  subContainer: {
    paddingTop: 15,
    backgroundColor: '#fbf8f1',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flex: 2,
    paddingHorizontal: 15,
  },
  inputDesktop: {
    width: '100%',
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    borderWidth: 0,
  },
  desktopContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  mobileContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});
